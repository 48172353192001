@use 'src/assets/styles/utilities/colors' as colors;

.exam-detail-wrapper {
    padding: 20px 30px 30px 30px;

    // Form items::
    .ant-form-item {
        align-items: flex-start;

        .ant-form-item-label > label {
            display: flex;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            height: 100%;
            line-height: 45px;
        }
    }

    // ANT DESIGN - Input, Input.Number:
    .app-input.ant-input,
    .app-input.ant-input-affix-wrapper {
        font-size: 18px;
    }

    .app-input.ant-input-number {
        display: block;
        min-width: 200px;
        font-size: 18px;
    }

    .app-input.ant-input-group-wrapper,
    .app-input.ant-input-number-group-wrapper {
        .ant-input {
            font-size: 18px;
        }
    }

    // Header:
    .page-header-wrapper {
        margin-bottom: 30px;
    }

    // Button list:
    .btn-group {
        margin-top: 30px;

        button.btn {
            padding-top: 5.5px;
            padding-bottom: 5.5px;

            .btn__icon-wrapper {
                height: 30px;

                & > svg {
                    height: 100%;
                }
            }
        }

        button.btn.btn-primary {
            .btn__icon-wrapper > svg {
                max-width: unset;
            }
        }

        .ant-form-item {
            margin-bottom: 0;
        }

        .ant-form-item-control-input-content {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            gap: 30px;
        }
    }

    // Layout:
    .exam-detail,
    .exam-detail-preview {
        // Variables:
        $sthHasPaddingY: 50px; // padding-top + padding-bottom.
        $sthHasMarginBottom: 30px;
        $headerHeight: clamp(60px, calc((90 / 1440) * 100vw), 90px);
        // Properties:
        min-height: calc(100vh - ($sthHasPaddingY + $sthHasMarginBottom + $headerHeight));
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    // Exam detail - Layout & elements:
    .exam-detail {
        .exam-formitems {
            .ant-form-item .ant-form-item-label > label {
                margin-right: 20px;
            }
        }

        .exam-question-list {
            margin-bottom: 30px;

            fillbox {
                display: none;
            }

            .list-topbar {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                column-gap: 50px;
                row-gap: 8px;
                margin-bottom: 8px;

                & > span {
                    display: block;
                }

                .topbar-title {
                    font-size: 18px;
                    line-height: 35px;
                    font-weight: 700;
                }

                .topbar-btn-wrapper {
                    text-align: right;
                }
            }

            .list-content {
                .ant-table-tbody {
                    .tags-wrapper {
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-start;
                        flex-direction: row;
                        flex-wrap: wrap;
                        gap: 4px;
                        // Layout:
                        margin: -16px;
                        padding: 16px;
                        max-height: 200px;
                        overflow-y: auto;
                    }

                    .ant-table-cell {
                        text-align: center;

                        &:nth-child(3) {
                            text-align: left;
                        }
                    }
                }

                .passage-input-disable {
                    background-color: #ddd;

                    * {
                        cursor: not-allowed !important;
                    }
                }
            }
        }
    }

    .question-list-table {
        .ant-table table {
            width: 100% !important;
        }

        .ant-table table colgroup col {
            &:nth-child(1) {
                width: 200px !important;
                min-width: 150px;
            }

            &:nth-child(2) {
                width: 200px !important;
                min-width: 150px;
            }

            &:nth-child(3) {
                width: auto !important;
                min-width: 350px;
            }

            &:nth-child(4) {
                width: 150px !important;
                min-width: 100px;
            }

            &:nth-child(5) {
                width: 150px !important;
                min-width: 100px;
            }

            &:nth-child(6) {
                width: auto !important;
                min-width: 100px;
            }
        }
    }

    // Exam preview - Layout & elements:
    .exam-detail-preview {
        .question-panel {
            margin-bottom: 30px;
        }

        .exam-formitems {
            margin-bottom: 30px;
        }

        .exam-formitems.set-publishing-status {
            justify-content: center;
            margin-bottom: 0;
        }

        .exam-preview {
            margin-bottom: 0;
        }
    }
}

.add-exam-question-modal {
    border-radius: 10px;
    overflow: hidden;

    .ant-modal-close-x {
        svg {
            fill: white;
            width: 20px;
            height: 20px;

            &:hover {
                opacity: 0.6;
            }
        }
    }

    .ant-modal-header {
        background-color: colors.$primary;

        .ant-modal-title {
            color: #fff;
            font-weight: 700;
            font-size: 26px;
            line-height: 35px;
        }
    }
}

// RESPONSIVE:
@media screen and (max-width: 1399.98px) {
    .exam-detail-wrapper {
        .ant-form-item {
            margin-bottom: 8px;

            .ant-form-item-label > label {
                line-height: 36px;
            }
        }

        .app-input.ant-input,
        .app-input.ant-input-affix-wrapper {
            line-height: 26px;
        }

        .app-input.ant-input-number {
            line-height: 26px;
        }

        &.app-input.ant-input-group-wrapper,
        &.app-input.ant-input-number-group-wrapper {
            .ant-input {
                line-height: 26px;
            }
        }

        .btn-group {
            button.btn {
                min-width: 140px;
                line-height: 20px;

                .btn__icon-wrapper {
                    height: 24px;
                }
            }
        }

        .exam-detail {
            .list-content {
                .ant-table-tbody {
                    .tags-wrapper {
                        gap: 2px;
                        margin: -8px;
                    }
                }
            }
        }

        .question-list-table {
            .ant-table table colgroup col {
                &:nth-child(1) {
                    min-width: 100px;
                }

                &:nth-child(2) {
                    min-width: 100px;
                }

                &:nth-child(3) {
                    min-width: 200px;
                }

                &:nth-child(4) {
                    min-width: 100px;
                }

                &:nth-child(5) {
                    min-width: 100px;
                }

                &:nth-child(8) {
                    min-width: 100px;
                }
            }
        }
    }
}

@media screen and (max-width: 767.98px) {
    .exam-detail-wrapper {
        .btn-group {
            .ant-form-item-control-input-content {
                justify-content: flex-end;
            }
        }
    }
}

@media screen and (max-width: 575.98px) {
    .exam-detail-wrapper {
        .btn-group {
            button.btn {
                width: 100%;
            }
        }
    }
}
