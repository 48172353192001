.question-preview-wrapper {
    padding: 20px 30px 30px 30px;

    .page-header-wrapper {
        margin-bottom: 30px;
    }

    .question-panel {
        margin-bottom: 30px;
    }

    // Action list:
    .btn-group {
        .ant-form-item-control-input-content {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            gap: 30px;
        }
    }
}
