@use 'src/assets/styles/utilities/colors' as c;

.login {
    position: relative;
    background-color: #f0f6fb;

    .login-layout-wrapper {
        min-height: 100vh;
        overflow: auto;
    }

    .login-top {
        position: relative;
        background: c.$primary;
        border-radius: 0px 0px 15px 15px;
        height: 120px;
        overflow: hidden;
        display: none;

        & > svg {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(0, -50%);
            width: auto;
            height: 130px;
            mix-blend-mode: soft-light;
            opacity: 0.3;
        }
    }

    .login-right {
        position: fixed;
        top: 0;
        left: auto;
        right: 0;
        bottom: 0;
        width: clamp(257.92px, 26%, 382px);
        background-color: c.$primary;

        .decoration-wrapper {
            position: relative;
            width: 100%;
            height: 100%;

            .decoration-top,
            .decoration-bottom {
                & > svg {
                    display: block;
                    width: 100%;
                    height: 100%;
                }
            }

            .decoration-top {
                position: absolute;
                top: 0;
                right: 0;
                bottom: auto;
                left: 0;
                padding: 30px 15px 0 30px;
            }

            .decoration-bottom {
                position: absolute;
                top: auto;
                right: 0;
                bottom: 0;
                left: auto;
                padding: 0 15px 30px 30px;
                width: 530px;
            }
        }
    }

    .login-left {
        width: calc(100% - clamp(257.92px, 26%, 382px));
        min-height: 100%;
    }
}

// RESPONSIVE DESIGN:
.login {
    .login-right {
        .decoration-wrapper {
            .decoration-top {
                height: calc(100% * (560.28 / 1024));
            }

            .decoration-bottom {
                width: calc(100% * (530 / 382));
                height: calc(100% * (512.19 / 1024));
            }
        }
    }
}

@media screen and (max-width: 991.98px) {
    .login {
        background-color: #fff;

        .login-top {
            display: block;
        }

        .login-right {
            display: none;
        }

        .login-left {
            width: 100%;
        }
    }
}
