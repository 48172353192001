// src\modules\containers\QuestionBank\components\FilterMenu\FilterMenu.scss
@use 'src/assets/styles/utilities/variables' as vars;

.filter-competitions {
    .filter-person_input {
        border-radius: 10px;
        height: 34px;
    }

    .ant-form-item {
        .ant-picker.filter-person_input {
            width: 100%;
        }
    }

    .ant-form.filter-personal-learning-progress_inner .ant-form-item .ant-picker.filter-person_input {
        padding: 4px 20px;
        height: auto;
        line-height: 35px;
        @media screen and (max-width: 1399.98px) {
            .ant-picker-input > input {
                line-height: 26px;
            }
        }
    }
}
