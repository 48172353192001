.page-404-wrapper {
    position: relative;
    background-color: #f0f6fb;
    background-attachment: fixed;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    z-index: 1;

    &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 785px;
        transform: translate(0, calc(-50% - 200px));
        background-image: url("../../../../assets/images/city.png");
        background-position: center center;
        background-repeat: repeat-x;
        z-index: -1;
    }

    .page-404-container {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;

        .page-404-img-wrapper {
            width: auto;
            height: 50vh;
            display: flex;
            justify-content: center;
            align-items: flex-end;

            img {
                // The size below is used for large screen.
                // CSS from media query will be applied for  smaller screen size.
                width: auto;
                height: 100%;
                max-width: 800px;
                max-height: 665px;
            }
        }

        .page-404-content {
            padding: 30px 15px 30px 15px;

            & > p {
                font-size: 46px;
                margin: 0;
            }

            .content-title {
                font-size: 34px;
                font-weight: bold;
                color: #0077ff;
            }

            .content-description {
                font-size: 18px;
            }

            .content-action-button {
                background-color: #ff1d86;
                width: 350px;
                height: 55px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0 auto;
                color: #fff;
                font-weight: bold;
                font-size: 18px;
                border-radius: 12px;
                box-shadow: 0 -3px 0 1px #dc076b inset;
                border: none;
                margin-top: 40px;
                cursor: pointer;

                &:hover {
                    opacity: 0.8;
                }

                &:active {
                    transform: scale(0.97);
                }
            }
        }
    }
}

@media screen and (max-width: 767.98px) {
    .page-404-wrapper {
        .page-404-container {
            .page-404-content {
                & > p {
                    font-size: 30px;
                }

                .content-title {
                    font-size: 25px;
                }

                .content-description {
                    font-size: 16px;
                }

                .content-action-button {
                    display: inline-block;
                    margin-top: 30px;
                    width: auto;
                    height: auto;
                    padding: 8px 15px;
                }
            }
        }
    }
}

@media screen and (max-width: 575.98px) {
    .page-404-wrapper {
        .page-404-container {
            .page-404-img-wrapper {
                min-height: 350px;

                img {
                    width: 100%;
                    height: auto;
                    max-width: 450px;
                }
            }
        }
    }
}
