.qp_passage {
    .ant-tabs {
        overflow: visible;
    }

    .passage-heading {
        font-size: 14px;
        font-style: italic;
    }

    .passage-title,
    .passage-content {
        font-size: 18px;
        line-height: 26px;
    }

    .passage_skeleton {
        position: relative;
        margin-bottom: 35px;

        .passage_transition {
            transition: all 0.5s ease-in;

            &.invisible {
                width: 0;
                flex: 0;
                padding: 0 !important;
                visibility: hidden;
                opacity: 0;
            }
        }
    }

    .passage_toggle {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -70px;
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &-btn-wrapper {
            background-color: #ddd;
            border-radius: 50%;

            width: 40px;
            height: 40px;
            display: flex;

            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
        &-note {
            font-size: 13px;
            text-align: center;
            line-height: 1.2;
        }

        &:hover {
            .passage_toggle-btn {
                color: rgb(85, 85, 85);
            }
        }

        .passage_toggle-btn {
            color: #f9f9f9;
            transition: all 0.5s ease-in;

            &.backward {
                transform: rotate(90deg);
            }

            &.forward {
                transform: rotate(-90deg);
            }
        }
    }

    .passage-title {
        font-weight: bold;
    }

    .passage-content-wrapper {
        position: relative;
        height: 100%;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        background-color: #fff;

        .passage-content {
            padding: 20px 10px 25px 20px;
            max-height: 450px;
            overflow-y: auto;
            padding-right: 10px;
            font-size: 16px;
            line-height: 24px;
            opacity: 0.7;
            color: #000;
        }
    }

    .passage-question-list-wrapper {
        position: relative;
        // padding: 20px 10px 25px 20px;
        height: 100%;

        .passage-question-list {
            padding: 20px 10px 25px 20px;
            max-height: 450px;
            overflow-y: auto;
            padding-right: 30px;

            .passage-question-wrapper {
                &:not(:last-child) {
                    margin-bottom: 30px;
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        .passage_toggle {
            bottom: -80px;
        }
    }
}
