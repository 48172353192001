@use 'src/assets/styles/utilities/variables' as vars;

$sidebarContentWidth: 43px; // = 65px - (10px * 2) - 2px.

.user-scope-picker {
    background-color: #fff;

    // Elements:
    .options-section {
        padding: 10px 0;

        &:not(:first-child) {
            border-top: 1px solid #e8ecf5;
        }
    }

    .section-title {
        font-weight: 700;
        font-size: 16px;
        line-height: 35px;
        margin: 0 10px;
    }

    .section-menu {
        &.ant-menu {
            padding: 4px 10px 10px 10px;

            &.ant-menu-vertical .ant-menu-item {
                height: auto;
                border-radius: 10px;
                padding: 0; // This line is important! Used to prevent from being changed!
            }
        }
    }

    .option-item-wrapper {
        .option-item {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 8px;
            user-select: none;
            // padding: 13px 10px 12px 16px;
            padding: 13px 8px 12px 8px;
            line-height: initial;
        }

        .option-item-section {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 8px;
            width: 100%;
            overflow: hidden;
        }

        .option-icon {
            display: flex;
            justify-content: center;
            align-content: center;
            height: 30px;
            width: 30px;
            min-width: 30px;
        }

        .option-name {
            width: calc(100% - 30px - 8px);
            overflow: hidden;
            text-overflow: ellipsis;
            color: rgba($color: #000000, $alpha: 0.6);
        }

        .option-status {
            display: block;

            svg {
                display: block;
            }
        }
    }

    // Ant Design - Menu:
    .ant-dropdown-menu {
        padding: 0 10px;
        box-shadow: none;
    }

    .ant-dropdown-menu-item,
    .ant-dropdown-menu-submenu-title {
        padding: 0;
        margin: 0;
        border-radius: 10px;
        overflow: hidden;
    }

    .ant-menu-vertical {
        border-right: none;
    }

    .ant-menu .ant-menu-item:not(.ant-menu-item-selected):not(:active):hover {
        background-color: rgba(230, 247, 255, 0.5);
        transition: all 0.2s ease;

        .option-item-wrapper {
            .option-name {
                color: #000;
            }
        }
    }
}

// RESPONSIVE:
@media screen and (min-height: 0px) {
    .user-scope-picker {
        .option-item-wrapper {
            .option-item {
                padding-left: calc(($sidebarContentWidth - 30px) / 2);
                padding-top: clamp(6px, ((13 / vars.$h) * 100vh), 35px);
                padding-bottom: clamp(5px, ((12 / vars.$h) * 100vh), 35px);
                font-size: clamp(16px, ((18 / vars.$h) * 100vh), 18px);
                line-height: clamp(24px, ((35 / vars.$h) * 100vh), 35px);
            }

            // .option-icon {
            //     width: clamp(24px, ((30 / vars.$h) * 100vh), 30px);
            //     height: clamp(24px, ((30 / vars.$h) * 100vh), 30px);
            //     min-width: clamp(24px, ((30 / vars.$h) * 100vh), 30px);
            // }
        }
    }
}
