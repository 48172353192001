.payment-wrapper {
    padding: 20px 30px;

    .payment-header {
        margin-bottom: 30px;
    }

    .payment-actions {
        margin-top: 16px;

        .btn-purchase {
            width: 100%;
        }
    }

    .promotion-section {
        padding: 16px 10px;
        background-color: #fff;
        border-radius: 10px;
        margin-bottom: 16px;

        .section:not(:last-child) {
            margin-bottom: 8px;
        }

        .section-title {
            font-size: 14px;
            font-weight: 700;
        }

        .section-applied-codes {
            display: flex;
            gap: 8px;
        }
    }

    .input-promotion-wrapper {
        position: relative;

        .ant-input.app-input {
            padding-right: 91px;
        }

        .btn-apply-promotion {
            position: absolute;
            top: 50%;
            right: 4.5px;
            transform: translate(0, -50%);
            min-width: unset;
            font-size: 14px;
            font-weight: normal;
            padding: 2px 10px;
            box-shadow: none;
            max-width: 75px;
            min-height: 36px;
        }
    }

    .select-plan-price-wrapper {
        width: 100%;
    }
}
