.swiper.app-swiper {
    .swiper-slide {
        width: unset;
        height: unset;
    }

    &.disable-shadow {
        &.swiper-3d {
            .swiper-slide-shadow-left,
            .swiper-slide-shadow-right {
                background: none;
            }
        }
    }
}
