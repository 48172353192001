@use 'src/assets/styles/utilities/variables' as vars;
@use 'src/assets/styles/utilities/colors' as colors;

/**
 * 1. Fonts:
 */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

/**
 * 2. Editor:
 * IMPORTANT NOTES:
 *   2.1. DO NOT SET UP THE PROPERTIES WITH VALUES THAT CAN AFFECTS THE CONTENT OF .mce-content-body, such as:
 *   font-family, font-size, font-weight, line-height, ...
 *   => THEY MUST BE SET WITH THEIR DEFAULT VALUES!
 *   2.2. SET UP THE PROPERTIES OF THE WRAPPER ONLY! NOT FOT THE CONTENT!
 */
.tox {
    .tox-toolbar__group {
        gap: 0 2px;
    }

    .tox-tbtn,
    .tox-tbtn__select-label {
        user-select: none;
        cursor: pointer;
    }

    .tox-tbtn#custom-tox-tbtn--paste-options {
        color: colors.$primary;
        background-color: #eee;

        svg {
            fill: colors.$primary;
        }

        &:hover {
            color: #fff;
            background-color: colors.$primary;

            svg {
                fill: #fff;
            }
        }
    }

    .tox-tbtn#custom-tox-tbtn--input-blank {
        color: #fff;
        background-color: rgba($color: colors.$primary, $alpha: 0.8);

        svg {
            fill: #fff;
        }

        &:hover {
            background-color: colors.$primary;
        }
    }
}

.mce-content-body {
    overflow-x: auto; // Deal with overflowed elements.
    overflow-y: auto; // Deal with overflowed elements.
}

// 1. Editor's Outer Toolbar:
// 2.1. For 'inline' editing mode:
.editorim-toolbar--default {
    --max-width: 100%;

    &.tox.tox-tinymce {
        box-shadow: 0 -2px 10px 2px rgba(0, 0, 0, 0.1);
        z-index: 1000;
    }

    &.tox .tox-editor-header {
        max-width: var(--max-width) !important;
    }

    &.tox.tox-tinymce,
    &.tox .tox-editor-header,
    &.tox .tox-menubar,
    &.tox .tox-toolbar-overlord,
    &.tox .tox-toolbar__primary {
        border-radius: 10px;
    }

    &.tox .tox-menubar {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    &.tox .tox-toolbar__primary {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
}

.editorim-toolbar--input {
    --max-width: 100%;

    &.tox.tox-tinymce {
        box-shadow: 0 -2px 10px 2px rgba(0, 0, 0, 0.1);
        z-index: 100;
    }

    &.tox .tox-editor-header {
        max-width: var(--max-width) !important;
    }

    &.tox.tox-tinymce,
    &.tox .tox-editor-header,
    // &.tox .tox-menubar,
    &.tox .tox-toolbar-overlord,
    &.tox .tox-toolbar__primary {
        border-radius: 10px;
    }

    // &.tox .tox-menubar {
    //     border-bottom-left-radius: 0;
    //     border-bottom-right-radius: 0;
    // }

    // &.tox .tox-toolbar__primary {
    //     border-top-left-radius: 0;
    //     border-top-right-radius: 0;
    // }
}

.editorim-toolbar--textarea {
    --max-width: 100%;

    &.tox.tox-tinymce {
        box-shadow: 0 -2px 10px 2px rgba(0, 0, 0, 0.1);
        z-index: 100;
    }

    &.tox .tox-editor-header {
        max-width: var(--max-width) !important;
    }

    &.tox.tox-tinymce,
    &.tox .tox-editor-header,
    &.tox .tox-menubar,
    &.tox .tox-toolbar-overlord,
    &.tox .tox-toolbar__primary {
        border-radius: 10px;
    }

    &.tox .tox-menubar {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    &.tox .tox-toolbar__primary {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
}

// 2. Editor's Inner Toolbar:
.editor-toolbar-container {
    position: relative;

    // 2.1. For 'inline' editing mode:
    .editorim-toolbar--default {
        &.tox.tox-tinymce {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
        }
    }

    .editorim-toolbar--input {
        &.tox.tox-tinymce {
            position: absolute;
            left: 0;
            bottom: 0;
        }
    }

    .editorim-toolbar--textarea {
        &.tox.tox-tinymce {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
        }
    }
}

// 3. Editor's body:
.editor-body-container {
    // 3. For 'inline' editing mode:
    &.editorim-content--default,
    &.editorim-content--input,
    &.editorim-content--textarea {
        &.mce-content-body {
            transition: box-shadow 0.3s;

            &.mce-edit-focus {
                box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
            }
        }
    }

    &.editorim-content--default {
        &.mce-content-body {
            background-color: #fff;
            border: 1px solid #cccccc;
            border-radius: 10px;
            min-height: 200px;
            padding: 8px 16px;
            outline: none;
            // [Text-displaying-related components]:
            font-size: vars.$txt-font-size;
            line-height: vars.$txt-line-height;
        }
    }

    &.editorim-content--input {
        &.mce-content-body {
            background-color: #fff;
            border: 1px solid #cccccc;
            border-radius: 10px;
            min-width: 200px;
            padding: 4px 16px;
            outline: none;
            font-size: 16px;
            line-height: 35px;

            &:not([dir="rtl"])[data-mce-placeholder]:not(.mce-visualblocks)::before {
                left: 16px;
            }
        }
    }

    &.editorim-content--textarea {
        &.mce-content-body {
            background-color: #fff;
            border: 1px solid #cccccc;
            border-radius: 10px;
            min-width: 200px;
            min-height: 200px;
            padding: 4px 16px;
            outline: none;
            font-size: 16px;
            line-height: 35px;

            &:not([dir="rtl"])[data-mce-placeholder]:not(.mce-visualblocks)::before {
                left: 16px;
            }
        }
    }
}

@media screen and (max-width: 1399.98px) {
    // 3. Editor's body:
    .editor-body-container {
        &.editorim-content--input {
            &.mce-content-body {
                font-size: 16px;
                line-height: 26px;
            }
        }

        &.editorim-content--textarea {
            &.mce-content-body {
                font-size: 16px;
                line-height: 26px;
            }
        }
    }
}
