@use 'src/assets/styles/utilities/colors' as c;

/*
 * Global class names:
 * _
 */

.q-drag-drop {
    .q-content-section {
        &:not(:last-child) {
            margin-bottom: 30px;
        }
    }

    .dragDrop_container {
        // margin: 54px 47px;
        margin-bottom: 10px;
        column-gap: 50px;
        margin-left: 5px;
        display: flex;
        // justify-content: space-between;

        .dropEmptyItem {
            // width: 225px;
            min-width: 230px;
            min-height: 40px;
            border: 1px dashed #7e7e7e;
            display: flex;
            align-items: center;

            &.fixHeight {
                height: 40px;
            }
        }

        @media screen and (max-width: 897px) {
            row-gap: 20px;
            flex-direction: column;

            .dragDrop_list {
                display: flex;
                flex-wrap: wrap;
            }

            // .dropEmptyItem {
            //     width: 166px;
            // }
        }
    }

    .dragDropQuestion_container {
        width: 100%;

        .dragDrop_list {
            list-style: none;
            position: relative;

            .dragDrop_itemWrapper {
                display: flex;
                align-items: center;

                .dragDrop_item_answerTitle {
                    // white-space: nowrap;
                    min-width: 80px;
                    margin-left: 19px;
                }
            }

            .dragDrop_item {
                box-shadow: 0px 0px 1px 1px #ddd;
                width: 228px;
                min-height: 38px;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                position: relative;
                margin: 0 !important;

                .dragDrop_item-value {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    padding: 8px;
                }

                &.dragging {
                    background: c.$primary;
                    list-style: none;
                    color: #fff;
                    // top: auto !important;
                    // left: auto !important
                }

                &.disabled {
                    background-color: rgba(132, 132, 132, 0.1);
                    cursor: not-allowed !important;

                    img {
                        cursor: not-allowed !important;
                    }
                }

                img {
                    height: 38px;
                    padding: 10px;
                    display: block;
                    cursor: -moz-grab;
                    cursor: grab;
                }
            }
        }

        @media screen and (max-width: 897px) {
            .dragDrop_list {
                margin-bottom: 30px;
                column-gap: 10px;
                row-gap: 5px;

                &.answered {
                    column-gap: 0px;
                    row-gap: 0px;

                    .dragDrop_item {
                        flex: 100%;
                    }
                }

                .dragDrop_item {
                    flex-basis: calc(50% - 20px);

                    &:not(.dragging) {
                        transform: none !important;
                    }
                }

                .dragDrop_itemWrapper {
                    width: 100%;
                }
            }
        }

        @media screen and (max-width: 992px) {
            width: 100%;
        }
    }
}
