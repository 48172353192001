/**
 * COLOR LIST:
 * Use: @use 'src/assets/styles/utilities/colors' as colors;
 */

// Statuses:
$danger: #ff4d4f;
$success: #52c41a;
$info: #1890ff;
$warning: #faad14;

// Default list:
$primary: #445cfe;
$second: #4fe3c1;
$dream: #8e6cf7;
$sunlight: #fce389;
$fog: #979c9e;

// Default list - customized:
$primary_darker: #445c9e;

// Others:
$white: #fff;
$text_black: #090a0a;
$yellow: #ff0;

// use in ranking form
$dark_gray: #636364;
