.feedback_button--float {
    bottom: 100px;
    display: block;
    margin: 0px 12px;
    overflow: visible;
    padding: 0px;
    position: fixed;
    right: 12px;
    z-index: 2147483644;

    button {
        box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 12px 0px;
        height: 60px;
        width: 60px;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 30px;
            height: 30px;
        }

        &:hover {
            animation: shake 0.4s ease 1;
        }
    }

    @keyframes shake {
        0% {
            transform: translateX(0);
        }
        25% {
            transform: translateX(-1px) rotate(-10deg);
        }
        50% {
            transform: translateX(1px) rotate(10deg);
        }
        75% {
            transform: translateX(-1px) rotate(-10deg);
        }
        100% {
            transform: translateX(1px) rotate(10deg);
        }
    }
}
