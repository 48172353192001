.homeStudent_wrapper {
    min-height: 100vh;
    overflow: hidden;
    .homeStudent_header {
        height: 12%;
        min-height: 200px;
    }

    .homeStudent_controlPanel-search {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-grow: 1;
    }

    .homeStudent_controlPanel {
        display: flex;
        margin-top: 30px;
        align-items: center;
        justify-content: space-between;
        .homeStudent_controlPanel_title {
            font-size: 18px;
            font-weight: bold;
            white-space: nowrap;
            padding: 0 20px;
            flex-basis: 90px;
        }

        .homeStudent_filter {
            background-color: #fff;
            padding: 10.5px;
            padding: max(calc(10.5 / 1440) * 100vh, 10.5px);
            border-radius: 10px;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            max-width: 799px;
            width: 50%;
            // display: flex;
            // align-items: center;

            div {
                font-style: italic;
                font-size: 14px;
                opacity: 0.5;
            }
        }

        @media screen and (max-width: 768px) {
            .homeStudent_controlPanel_title {
                display: none;
            }

            .homeStudent_filter {
                // height: 35px;
                width: 100%;
            }
        }
    }

    .cardList {
        display: flex;
        flex-wrap: wrap;
        // min-height: 200px;
        .cardItem {
            flex-basis: 33.33333%;
            width: 33.3333%;
            padding: 10px;
            // height: max(calc(300 / 1440) * 100vh, 400px);
            .cardItem_inner {
                // padding: 16px;
                padding: max(calc(16 / 1440) * 100vh, 16px);
                background: #fff;
                box-shadow: 0 0px 4px rgba(0, 0, 0, 0.2);
                border-radius: 10px;
                display: flex;
                height: 100%;

                .cardItem_thumbNail {
                    // width: min(5%, 160px);
                    width: 20%;
                    min-width: 90px;
                    border-radius: 10px;
                    overflow: hidden;
                }

                .cardItem_image {
                    padding-top: 100%;
                    height: 0px;
                    position: relative;

                    img {
                        position: absolute;
                        top: 0;
                        height: 100%;
                        width: 100%;
                    }
                }
            }

            .cardItem_info {
                margin-left: 20px;
                p {
                    margin: 0;
                }

                h3 {
                    margin: 0;
                    font-size: 16px;
                    font-weight: bold;
                }

                .cardItem_status {
                    font-size: 12px;
                    text-align: center;
                    display: inline-block;
                    margin: 2px 10px;
                    padding: 3px 20px;
                    border-radius: 5px;
                    background-color: #ddd;

                    &.cardItem_status-danger {
                        color: #ff0000;
                        background-color: rgba($color: #ff0000, $alpha: 0.1);
                    }

                    &.cardItem_status-success {
                        color: #129d29;
                        background-color: rgba($color: #00ff29, $alpha: 0.1);
                    }

                    &.cardItem_status-info {
                        color: #0077ff;
                        background-color: rgba($color: #0077ff, $alpha: 0.1);
                    }

                    &.cardItem_status-warn {
                        color: #af8609;
                        background-color: rgba($color: #fecc32, $alpha: 0.1);
                    }

                    &.cardItem_status-warn2 {
                        color: #af8609;
                        background-color: rgba($color: #fe7932, $alpha: 0.1);
                    }

                    &.cartItem_status-disable {
                        color: #9ea7ad;
                        background-color: rgba($color: #9ea7ad, $alpha: 0.1);
                    }
                }
            }
        }
    }

    .loadmore_btn {
        cursor: pointer;
        min-height: 157px;

        font-size: 24px;

        &:hover {
            opacity: 0.7;
        }

        div {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .cardItem_info {
        .btn__icon-wrapper img {
            width: 18px;
            height: 18px;
        }
        .btn {
            min-width: 140px;
        }
    }
}

.ant-dropdown.ant-dropdown-placement-bottom {
    z-index: 1;
}

@media screen and (max-width: 1440px) {
    .homeStudent_wrapper {
        .cardList {
            .cardItem {
                height: auto !important;
            }
        }
    }
}

@media screen and (max-width: 1360px) {
    .homeStudent_wrapper {
        .cardList {
            .cardItem {
                flex-basis: 50%;
                width: 50%;
            }
        }
    }
}

@media screen and (max-width: 880px) {
    .homeStudent_wrapper {
        .cardList {
            .cardItem {
                flex-basis: 100%;
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .homeStudent_wrapper {
        .homeStudent_controlPanel {
            flex-wrap: wrap;
            margin-top: 10px;

            .homeStudent_controlPanel-search,
            .filter-toolbar-item {
                width: 100%;
                margin: 5px 0;
            }
        }
    }
}
