@use 'src/assets/styles/utilities/variables' as vars;

.fill-blanks-detail {
    .question-setting.set-answers {
        margin-bottom: 20px;

        > .question-answer-title {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 35px;
        }
    }

    .question-answer-list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 10px;

        .input-editor {
            width: 100%;
        }

        .answer-input {
            width: 45%;

            label {
                width: 50px;
            }
        }

        .fill-blank-answers__actions {
            width: 45%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .ant-form-item {
        margin-bottom: 0;
    }

    .fill-blank-answers__actions {
        .fill-blank-answers__add {
            display: inline-flex;
            align-items: center;
            cursor: pointer;
            font-size: 16px;
            line-height: 24px;

            &:hover {
                text-decoration: underline;
            }

            & > span {
                margin-right: 5px;
            }

            button {
                font-size: 25px;
                padding-bottom: 10px;
                background-color: #000;
                color: #fff;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                outline: none;
                border: none;
                cursor: pointer;

                @media (hover: hover) {
                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 799.98px) {
    .fill-blanks-detail {
        .question-answer-list {
            .fill-blank-answers__actions,
            .answer-input {
                width: 100%;

                .ant-form-item-control,
                .ant-form-item-label {
                    flex: none;
                }

                .ant-form-item-control {
                    flex-grow: 1;
                }
            }
        }
    }
}
