.ant-avatar.app-avatar {
    &.layout-full {
        width: 100%;
        height: 100%;
    }
}

.avatar-img-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;

    .avatar-img,
    .avatar-img .img-wrapper,
    .avatar-img .img-wrapper img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .avatar-img {
        .img-wrapper {
            border-radius: 50%;
            overflow: hidden;
        }
    }

    &.wrap-with-badge {
        .avatar-img {
            position: relative;

            &::after {
                position: absolute;
                content: "";
                width: calc(100% + 8px);
                height: calc(100% + 8px);
                background: linear-gradient(90deg, #e7c178 37%, #f7dfaf 85%);
                top: -4px;
                left: -4px;
                border-radius: 50%;
                z-index: -1;
            }
        }

        .avatar-img-badge {
            position: absolute;
            width: 25px;
            height: 25px;
            top: calc(100% - 16.5px);
            left: calc(100% - 16.5px);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1;

            & > svg {
                display: block;
                width: auto;
                height: 10.2px;
            }
        }

        .avatar-img-label {
            display: block;
            margin-top: 9px;
            text-align: center;
            font-size: 10px;
            line-height: 18px;
            min-height: 18px;
            color: #864300;

            .label-content {
                border-radius: 10px;
                background: linear-gradient(90deg, #e7c178 37%, #f7dfaf 85%);
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translate(-50%, 0);
                padding: 0 5px 0 2px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                column-gap: 6px;
                font-weight: 500;
            }

            .label-icon {
                display: inline-block;
                width: 14px;
                height: 14px;
                background-color: #fff;
                border: 1px solid #f8b82c;
                border-radius: 50%;

                & > svg {
                    display: block;
                    margin: 0 auto;
                    width: 8px;
                    height: 100%;
                }
            }
        }
    }
}
