.learning-progress-report-nav-card {
    position: relative;
    display: inline-block;
    background: #fff;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
    border-radius: 24px;
    height: 420px;
    max-width: 410px;

    .card-title {
        padding: 34px 16px 0 16px;
        margin-bottom: 20px;
        text-align: center;
        font-weight: 700;
        font-size: 26px;
        line-height: 35px;
        color: #334940;
    }

    .card-action-list {
        position: relative;
        padding: 0 16px;
        text-align: center;
        z-index: 1;
    }

    .bg-img-wrapper {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        border-radius: 0 0 24px 24px;
        overflow: hidden;

        & > svg {
            display: block;
            width: 100%;
            height: auto;
        }
    }
}
