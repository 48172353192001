.sidebar_text-scroll {
    display: flex;
    align-items: center;
    overflow: hidden;
    margin: 0 25px 0 20px;
    border-radius: 20px;
    background: linear-gradient(90deg, #e7c178 37%, #f7dfaf 85%);
    margin-bottom: 10px;
    font-size: clamp(16px, 1.75vh, 18px);
    min-height: 40px;
    padding: 4px;
    transition: all 0.3s ease;

    @media screen and (min-height: 1010px) {
        margin-right: 10px;
    }
    .sidebar_text-scroll-content {
        overflow: hidden;
        margin-left: 5px;
        margin-right: 10px;
        flex: 1;
    }

    &.collapsed {
        .sidebar_text-scroll-content {
            display: none;
        }
    }

    p {
        white-space: nowrap;
        animation: slide-left 8s linear infinite;
        color: #864300;
        width: fit-content;
        margin-bottom: 0;
    }

    &-logo {
        height: 100%;
        border-radius: 50%;
        background-color: #fff;
        min-width: 32px;
        width: 32px;
        height: 32px;
        min-width: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        // box-shadow: rgba(181, 181, 185, 0.2) 0px 4px 6px 0px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

@keyframes slide-left {
    from {
        -webkit-transform: translateX(180px);
        transform: translateX(180px);
    }
    to {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
}
