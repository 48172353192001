.help-carousuel {
    &-body {
        overflow-y: auto;
        position: relative;
    }
    &-body-inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80%;
        margin: 0 auto;
    }
    &-title {
        font-size: 18px;
        font-weight: 600;
    }
    &-img {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin-bottom: 20px;
        img {
            max-width: 100%;
            height: auto;
            object-fit: contain;
        }
    }
    &-desc {
        width: 100%;
        > div {
            margin-bottom: 20px;
        }
    }
    .slick-dots {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    .slick-dots li button:before {
        font-size: 8px;
    }
    // .no-data {
    //     display: flex !important;
    //     align-items: center;
    //     justify-content: center;
    //     flex-direction: column;
    //     img {
    //         width: 100%;
    //         max-width: 400px;
    //         display: inline-block;
    //     }
    //     > div {
    //         color: #888;
    //         font-size: 1.2rem;
    //     }
    // }
}
