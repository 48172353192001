.customize-render-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: clamp(240px, calc(350 / 1440 * 100vw), 360px);
    @media screen and (max-height: 580px) {
        height: clamp(200px, calc(350 / 1440 * 100vw), 240px);
    }
    > span {
        font-size: 16px;
        line-height: 35px;
    }
}
