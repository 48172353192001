@use 'src/assets/styles/utilities/colors' as c;

.TestHeader.pdf {
    margin: 15px 15px;
    padding: 10px 20px;

    .TestHeader_Clock {
        display: flex;
    }
    .actions {
        display: flex;
        justify-content: center;

        .btn {
            min-width: unset;
            width: 60px;
            height: 60px;
        }
    }
}
