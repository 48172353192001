@use 'src/assets/styles/utilities/variables' as vars;
@use 'src/assets/styles/utilities/colors' as colors;

.subscription-plans-modal {
    .modal-subsc-plans {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1001;
        transition: all 0.5s ease;
        display: none;

        .modal-wrapper {
            position: relative;
            width: 100%;
            height: 100%;

            .modal-overlay {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-color: rgba(0, 0, 0, 0.85);
                z-index: 1100;
            }

            .modal-content-wrapper {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                overflow-x: hidden;
                overflow-y: auto;
                z-index: 1101;

                .modal-content {
                    width: 100%;
                    min-height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                }
            }

            .modal-header {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 0 15px;
                margin-top: 50px;
                margin-bottom: 50px;

                .header-icon {
                    margin-bottom: 24px;

                    & > svg {
                        width: 220px;
                        height: auto;
                    }
                }

                .header-title {
                    color: #fce389;
                    font-weight: 700;
                    font-size: 32px;
                    line-height: 36px;
                    text-align: center;
                }
            }

            .modal-body {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 50px;
                width: 100%;

                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }
    }

    .modal-subsc-plans.visible {
        display: block;
        animation: makeVisible 0.5s ease;
        animation-delay: 0;
        animation-direction: normal;
        animation-fill-mode: forwards;
    }

    .plan-list {
        display: flex;
        justify-content: center;
        align-items: center;
        // flex-wrap: wrap;
        gap: 30px;
        width: 100%;

        .swiper.app-swiper {
            width: 100%;

            .swiper-pagination {
                display: none;
            }
        }

        .subscription-plan-card {
            box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
            transition: transform 0.2s ease;

            .card-subsc-plan .card-wrapper {
                transition: all 0.2s ease;
            }

            .card-subsc-plan .card-wrapper .card-price {
                min-height: 62px;
            }

            .card-subsc-plan .card-wrapper .card-body {
                min-height: 200px;
            }

            &.emphasized {
                margin: 0;
                .card-subsc-plan .card-wrapper {
                    padding: 42px 0 44px 0;
                }
            }

            &:not(.emphasized) {
                margin: 20px 0 20px 0;
                .card-subsc-plan .card-wrapper {
                    padding: 22px 0 24px 0;
                }
            }

            &:nth-child(2).skeleton {
                .card-subsc-plan .card-wrapper {
                    padding: 42px 0 44px 0;
                }
            }
        }

        .empty-wrapper {
            position: relative;

            & *:not(.empty-msg) {
                opacity: 0;
                visibility: hidden;
            }

            .empty-msg {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: #fff;
                font-size: 16px;
            }
        }
    }

    .subscription-plan-card .card-subsc-plan .card-wrapper .card-actions .btn.activated {
        background-color: colors.$second;
    }
}

// RESPONSIVE:
@media screen and (min-height: 0px) {
    .subscription-plans-modal {
        .modal-subsc-plans {
            .modal-wrapper {
                .modal-header {
                    margin-top: clamp(24px, (50 / vars.$h) * 100vh, 50px);
                    margin-bottom: clamp(24px, (50 / vars.$h) * 100vh, 50px);
                    .header-icon {
                        margin-bottom: clamp(8px, (24 / vars.$h) * 100vh, 24px);
                        & > svg {
                            width: clamp(150px, (220 / vars.$h) * 100vh, 220px);
                        }
                    }
                    .header-title {
                        font-size: clamp(18px, (32 / vars.$h) * 100vh, 32px);
                        line-height: 36px;
                    }
                }

                .modal-body {
                    margin-bottom: clamp(24px, (50 / vars.$h) * 100vh, 50px);
                }
            }
        }
    }
}

@media screen and (max-width: 575.98px) {
    .subscription-plans-modal {
        .modal-subsc-plans {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1001;
            display: none;

            .modal-wrapper {
                .modal-body {
                    width: 100%;
                    padding: 0 16px;
                }
            }
        }

        .plan-list {
            flex-direction: column;
            width: 100%;

            .subscription-plan-card {
                width: 100%;

                .card-subsc-plan,
                .card-subsc-plan .card-wrapper {
                    width: 100%;
                }

                .card-subsc-plan .card-wrapper {
                    border-radius: 10px;
                }

                .card-subsc-plan .card-wrapper .card-price .price-discount-label {
                    top: 50%;
                    left: calc(100% + 8px);
                    transform: translate(0, -50%);
                }

                &:nth-child(n) {
                    z-index: unset;
                    transform: none;
                }

                &.emphasized,
                &:not(.emphasized) {
                    z-index: unset;
                    margin: 0;

                    .card-subsc-plan .card-wrapper {
                        padding: 22px 0 24px 0;
                    }
                }
            }
        }
    }
}
