.pdf-previewer {
    overflow: auto;

    width: 100%;
    height: 100%;
    border-radius: 5px;

    .content {
        display: flex;
    }

    &-container {
        min-width: 600px;

        padding: 5px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    &_adjust-tools {
        position: absolute;
        top: 70%;
    }
}
