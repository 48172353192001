@use 'src/assets/styles/utilities/variables' as vars;
@use 'src/assets/styles/utilities/colors' as colors;

$sidebarContentWidth: 65px;

.sidebar-login-picker {
    .picker-toggle-icon {
        position: absolute;
        top: 50%;
        left: calc(280px - 14px - 10px - 8px);
        transform: translate(0, -50%);
        transition: transform 0.5s ease;
    }

    &.app-dropdown.menu-as-child {
        padding-top: 10px;
        margin-bottom: 10px;

        .app-dropdown-trigger {
            position: relative;
            user-select: none;
            cursor: pointer;

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: calc(100% - 20px);
                height: 100%;
                background-color: #f8f8f8;
                border: 1px solid #e8ecf5;
                border-radius: 10px;
                margin: 0 10px 0 10px;
                z-index: -2;
            }

            &:hover {
                &::after {
                    background-color: #e8ecf5;
                }
            }
        }

        .app-dropdown-content {
            min-width: 260px;
            width: calc(100% - 20px);
            border-left: 1px solid #e8ecf5;
            border-right: 1px solid #e8ecf5;
            border-bottom: 1px solid #e8ecf5;
            border-radius: 0 0 10px 10px;
            box-shadow: 0 2px 2px 1px rgb(0 0 0 / 20%);
            margin: 0 10px 0 10px;
            overflow: hidden;
            z-index: 1100;
        }

        .user-scope-picker .options-section {
            &:first-child {
                border-top: 1px solid #e8ecf5;
            }
        }

        &.app-dropdown-open {
            .app-dropdown-trigger {
                &::after {
                    // content: "";
                    // position: absolute;
                    // top: 0;
                    // left: 0;
                    // width: calc(100% - 20px);
                    // height: 100%;
                    // background-color: #fff;
                    border-left: 1px solid #e8ecf5;
                    border-right: 1px solid #e8ecf5;
                    border-top: 1px solid #e8ecf5;
                    border-radius: 10px 10px 0 0;
                    box-shadow: 0 2px 2px 1px rgb(0 0 0 / 20%);
                    // margin: 0 10px 0 10px;
                    // z-index: -1;
                }
            }

            .picker-toggle-icon {
                transform: translate(0, -50%) rotateX(-180deg);
            }
        }
    }
}

.sidebar-menu-wrapper {
    display: block;

    > .sidebar-menu-overlay {
        z-index: 1;
        position: relative;
        background-color: rgba(0, 0, 0, 0);

        &.visible {
            z-index: 900;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.2);
            transition: background-color 0.5s ease;
        }
    }

    > .sidebar-menu-overlay.for-small-device {
        display: none;
    }

    > .sidebar-menu {
        position: fixed;
        top: 0;
        bottom: 0;
        z-index: 1000;
    }

    // Layout:
    .sidebar-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: hidden;
        box-shadow: 1px 0 1px rgb(0 0 0 / 25%);

        &.show-overlay {
            position: relative;
            background-color: rgba(0, 0, 0, 0);

            .sidebar-container-overlay {
                z-index: 901;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.7);
                backdrop-filter: blur(2px);
                transition: background-color 0.5s ease;
            }

            .sidebar-login-picker.app-dropdown.menu-as-child {
                z-index: 1001;
            }
        }
    }

    .sidebar-scrollable-section {
        overflow-x: hidden;
        overflow-y: auto;
    }

    .sidebar-section {
        &:nth-child(1) {
            border-bottom: 1px solid #e8ecf5;
            padding-bottom: 50px;
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
            margin-top: 50px;
        }
    }

    .sidebar-coll-item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        margin: 0;
        padding: 0 10px;

        &.mr-bottom {
            margin-bottom: 10px;
        }
        .upgrade-now {
            flex: 1;
            margin: 0 12.5px 0 12.5px;
            cursor: pointer;
        }
    }

    // Elements:
    .avatar {
        white-space: nowrap;
        padding: 30px 15px 15px 15px;
        // border-bottom: 1px solid #e8ecf5;

        &.avatar-show {
            align-items: flex-start;
            display: flex;
        }

        .avatar-img-wrapper {
            display: block;
            width: 55px;
            min-width: 55px;
        }

        .avatar-img {
            display: block;
            flex: none;
            height: 55px;
            width: 55px;
            // border-radius: 50%;
            // background-color: #eee;
            // overflow: hidden;
            margin: 0 auto;

            & > img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }

        .avatar-welcome {
            font-size: 14px;
            line-height: 19px;
        }

        .avatar-name {
            font-size: 18px;
            font-weight: 700;
            line-height: 25px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 160px;
        }

        & > p {
            display: flex;
            justify-content: center;
            flex-direction: column;
            margin-bottom: 0;
            margin-left: 16px;
        }
    }

    .user-info {
        position: relative;
    }

    .nav-menu-item {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        column-gap: 8px;
        user-select: none;
        padding: 13px 10px 12px 16px;
        line-height: initial;

        &:hover .custom-nav-dropdown {
            display: block;
        }

        .item-section {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
        }

        .nav-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 35px;
            width: 30px;
            min-width: 30px;

            .anticon,
            svg {
                display: block;
                width: 100%;
                height: auto;
                max-height: 100%;
            }
        }

        .nav-collapsible-icon {
            transition: transform 0.5s ease;

            svg {
                fill: #777;
            }
        }
    }

    .subnav {
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        .subnav-item a {
            font-weight: 400;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-word;
        }

        .subnav-item {
            position: relative;
            border-radius: 8px;
            margin: 0 10px 0 16px;
            div {
                font-size: 16px;
                // font-weight: 700;
                column-gap: 8px;
                display: flex;
                align-items: center;
                width: 30px;
                color: rgba(0, 0, 0, 0.6);
                padding-left: 2px;
            }
            .subnav-title {
                line-height: 32px;
            }
        }

        .subnav-item.active {
            background-color: #fff;
            a > div .subnav-title {
                color: colors.$second !important;
                font-weight: 700;
            }
            &::before {
                border-color: colors.$second;
            }
            svg * {
                fill: colors.$second !important;
            }
        }
    }

    .language-box {
        padding: 0 10px 50px 10px;
        text-align: center;

        & > a:first-child {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;

            & > span {
                display: inline-block;
            }

            .language-text {
                // opacity: 1;
                // visibility: visible;
                transition-delay: 0.2s;
            }
        }

        .flag {
            width: 40px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-content: center;

            & > img:first-child {
                display: block;
                width: auto;
                height: auto;
                max-height: 100%;
                margin: 0 auto;
            }
        }

        @media screen and (max-width: 768px) {
            padding-bottom: 0px;
        }
    }

    .sidebar_text-scroll {
        // Variables:
        $wh: 36px;
        $t: 4px;
        $mrX: calc(($sidebarContentWidth - ($wh + $t + $t)) / 2);
        // CSS:
        width: 100%;
        margin: 0 $mrX 0 $mrX;
        padding: 4px;
        border-radius: $wh;
        transition: none;

        .sidebar_text-scroll-logo {
            width: $wh;
            height: $wh;
            min-width: $wh;
        }
    }

    // Ant Design - Menu:
    .ant-badge {
        display: block;

        .ant-badge-count {
            top: 50% !important;
            right: 5px !important;
            transform: translate(0, -50%) !important;
            transition-property: top, right, transform, min-width, width, height, padding;
            transition-duration: 0.2s;
            transition-timing-function: ease;
        }
    }

    .ant-menu.sidebar-nav-menu {
        padding: 0 10px;
        border-right: 0;

        &.ant-menu-vertical .ant-menu-item {
            height: auto;
            padding: 0;
            margin: 0;
            border-radius: 10px;

            .subnav {
                display: none;
            }

            .subnav-item {
                a {
                    color: rgba($color: #000000, $alpha: 0.6) !important;
                }
                &::before {
                    border: 3px solid rgba($color: #000000, $alpha: 0.6);
                }
            }

            &.show-subnav .nav-menu-item .nav-collapsible-icon {
                transform: rotateX(-180deg);
            }

            &.show-subnav .subnav {
                display: block;
                border-top: 1px solid #e8ecf5;
            }

            &:not(.ant-menu-item-selected):not(:active):hover {
                background-color: rgba(230, 247, 255, 0.5);

                .subnav-item:hover {
                    background-color: rgba(230, 247, 255, 1);
                }
            }

            .nav-menu-item {
                font-size: 18px;
                line-height: 35px;
                color: rgba($color: #000000, $alpha: 0.6);
            }

            &.item-subsc {
                margin-left: calc(16px - 5px - 2.5px);
            }
        }

        &.ant-menu-vertical.ant-menu-inline-collapsed .ant-menu-item {
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: none;
            overflow: visible;

            .subnav {
                display: none;
            }

            .nav-menu-item {
                padding-left: 10px;
            }

            .nav-menu-item .nav-name,
            .nav-menu-item .item-section {
                display: none;
            }

            &.item-subsc {
                margin-left: 0;
            }

            .subsc-btn {
                padding: 4px !important;
            }

            .subsc-btn .subsc-btn-name,
            .subsc-btn .subsc-section {
                display: none;
            }

            .ant-badge {
                .ant-badge-count {
                    top: 0 !important;
                    right: 0 !important;
                    transform: none !important;
                    content-visibility: hidden;
                    min-width: 10px;
                    width: 10px;
                    height: 10px;
                    padding: 0;
                    color: rgba(0, 0, 0, 0);
                }
            }
        }

        &:not(.ant-menu-horizontal) .ant-menu-item-selected {
            background-color: colors.$second;
            box-shadow: 0 4px 4px rgb(0 0 0 / 25%);

            svg {
                fill: #fff;
                * {
                    fill: #fff;
                }
            }

            .subnav-item {
                a .subnav-title {
                    color: #fff;
                }
                &::before {
                    border: 3px solid #fff;
                }
            }

            .subnav-item.active {
                background-color: #fff;
                a {
                    color: colors.$second !important;
                }
                &::before {
                    border-color: colors.$second;
                }
                svg {
                    fill: colors.$second;
                }
            }

            .nav-menu-item {
                color: #fff;
                font-weight: 700;
            }
        }

        .subnav-icon {
            height: auto;
            display: block;
            width: 100%;
            max-height: 100%;

            svg {
                display: block;
                width: 30px;
                height: 24px;
            }
        }
    }

    .ant-menu.sidebar-nav-menu.for-login-picker {
        padding-top: 10px;
        padding-bottom: 10px;

        &.ant-menu-vertical .ant-menu-item {
            &.item-subsc {
                margin-left: 0;
            }
        }
    }

    .sidebar-menu.ant-layout-sider-collapsed {
        .language-box {
            & > a:first-child {
                .language-text {
                    display: none;
                }
            }
        }
    }
}

.sidebar-menu-wrapper.fixed-nav-menu-item {
    .nav-menu-item {
        padding-left: calc(($sidebarContentWidth - 30px) / 2);
    }

    .ant-menu.sidebar-nav-menu {
        &.ant-menu-vertical .ant-menu-item {
            &.item-subsc {
                margin-left: calc(($sidebarContentWidth - 43px) / 2);
            }
        }
    }

    .ant-menu.sidebar-nav-menu.for-login-picker {
        &.ant-menu-vertical .ant-menu-item {
            &.item-subsc {
                margin-left: 0;
            }
        }
    }

    .sidebar-menu.ant-layout-sider-collapsed {
        .ant-menu.sidebar-nav-menu {
            &.ant-menu-vertical.ant-menu-inline-collapsed .ant-menu-item {
                width: $sidebarContentWidth;

                &.item-subsc {
                    margin-left: 0;
                }
            }
        }

        .sidebar-scrollable-section {
            overflow: hidden;
        }
    }
}

// RESPONSIVE:
@media screen and (max-width: 1399.98px) {
    .sidebar-menu-wrapper {
        .sidebar_text-scroll {
            // Variables:
            $wh: 32px;
            $t: 4px;
            $mrX: calc(($sidebarContentWidth - ($wh + $t + $t)) / 2);
            // CSS:
            margin: 0 $mrX 0 $mrX;
            border-radius: $wh;

            .sidebar_text-scroll-logo {
                width: $wh;
                height: $wh;
                min-width: $wh;
            }
        }
    }
}

@media screen and (min-height: 0px) {
    .sidebar-menu-wrapper {
        // 16 là giá trị mặc định theo chiều cao.
        // vars.$h là tổng chiều cao theo thiết kế để dùng cho việc tính tỉ lệ.
        $distance: calc((16 / vars.$h) * 100vh);

        .sidebar-section {
            &:nth-child(1) {
                padding-bottom: clamp(8px, $distance, 50px);
            }

            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
                margin-top: clamp(8px, $distance, 50px);
            }
        }

        .avatar {
            padding-top: clamp(8px, $distance, 30px);
            padding-bottom: clamp(8px, $distance, 30px);

            .avatar-img {
                height: clamp(40px, ((55 / vars.$h) * 100vh), 45px);
                width: clamp(40px, ((55 / vars.$h) * 100vh), 45px);
            }

            .avatar-welcome {
                line-height: clamp(16px, ((19 / vars.$h) * 100vh), 19px);
            }

            .avatar-name {
                font-size: clamp(16px, ((18 / vars.$h) * 100vh), 18px);
                line-height: clamp(18px, ((25 / vars.$h) * 100vh), 25px);
            }

            & > p {
                margin-left: clamp(8px, ((16 / vars.$h) * 100vh), 16px);
                // min-height should be the same as the height of avatar:
                // min-height: clamp(40px, ((55 / vars.$h) * 100vh), 55px);
            }
        }

        .subnav {
            .subnav-item {
                line-height: clamp(24px, ((35 / vars.$h) * 100vh), 35px);
                padding-top: clamp(2px, ((8 / vars.$h) * 100vh), 8px);
                padding-bottom: clamp(2px, ((8 / vars.$h) * 100vh), 8px);
            }
        }

        .nav-menu-item {
            padding-top: clamp(6px, ((13 / vars.$h) * 100vh), 35px);
            padding-bottom: clamp(5px, ((12 / vars.$h) * 100vh), 35px);

            .nav-icon {
                height: clamp(24px, ((35 / vars.$h) * 100vh), 35px);
            }
        }

        .language-box {
            padding-top: clamp(8px, $distance, 50px);
            padding-bottom: clamp(8px, $distance, 50px);
        }

        .ant-menu.sidebar-nav-menu {
            &.ant-menu-vertical .ant-menu-item {
                .nav-menu-item {
                    font-size: clamp(16px, ((18 / vars.$h) * 100vh), 18px);
                    line-height: clamp(24px, ((35 / vars.$h) * 100vh), 35px);
                }
            }
        }
    }
}

@media screen and (max-width: 767.98px) {
    .sidebar-menu-wrapper {
        > .sidebar-menu-overlay:not(.for-small-device) {
            display: none;
        }

        > .sidebar-menu-overlay.for-small-device {
            display: block;

            &.visible {
                background-color: rgba(0, 0, 0, 0.7);
            }
        }

        > .sidebar-menu {
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            border-radius: 0px 15px 15px 0px;

            &.ant-layout-sider-collapsed {
                flex: 0 0 0px !important;
                max-width: 0px !important;
                min-width: 0px !important;
                width: 0px !important;
            }
        }

        // Layout:
        .sidebar-container {
            border-radius: 0px 15px 15px 0px;
        }

        // Elements:
        .avatar {
            padding-top: 30px;

            &.avatar-show {
                align-items: center;
                flex-direction: column;
                row-gap: 24px;
            }

            .avatar-img-wrapper {
                display: block;
                width: auto;
                min-width: unset;
                user-select: none;
            }

            .avatar-img {
                height: 90px;
                width: 90px;
            }

            .avatar-welcome {
                text-align: center;
            }

            .avatar-name {
                width: auto;
                line-height: unset;
                white-space: normal;
            }

            & > p {
                margin-left: 0;
                width: 100%;
                text-align: center;
                min-width: 250px;
            }
        }

        // .nav-menu-item {
        //     .nav-icon {
        //         height: 35px;
        //         width: 35px;
        //         min-width: 35px;
        //         background-color: #F2F4F5;
        //         border-radius: 50%;

        //         & > svg {
        //             width: 18px;
        //             height: auto;
        //             max-height: 100%;
        //         }

        //         & > svg * {
        //             fill: #979C9E;
        //         }
        //     }
        // }

        .language-box {
            padding-bottom: 30px;
        }

        // Ant Design - Menu:
        // .ant-menu.sidebar-nav-menu {
        //     &:not(.ant-menu-horizontal) .ant-menu-item-selected {
        //         svg {
        //             fill: #979C9E;
        //             * {
        //                 fill: #979C9E;
        //             }
        //         }
        //     }
        // }
    }
}
