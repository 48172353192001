@use 'src/assets/styles/utilities/colors' as colors;

.class-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: colors.$primary;
    border-radius: 10px;
    color: #fff;
    border: 2px solid colors.$primary;
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    padding: 10px 20px;
    min-width: 150px;
    transition: all 0.3s ease;

    &:hover {
        opacity: 0.7;
    }

    span {
        margin-right: 5px;
        font-size: 20px;
    }

    &.outline-btn {
        background-color: transparent;
        color: colors.$primary;

        &:hover {
            background-color: colors.$primary;
            color: #fff;
            opacity: 1;
        }
    }
}
