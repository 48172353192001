@use 'src/assets/styles/utilities/colors' as c;

.importusers {
    position: relative;

    .importusers-subbtn {
        cursor: pointer;
        position: absolute;
        left: 0;
        right: 0;
        font-size: 12px;
        line-height: 1.5;
        text-align: center;
        white-space: nowrap;

        &:hover {
            color: c.$primary;
        }
    }
}
