@use 'src/assets/styles/utilities/colors' as c;

.resultanswer-wrapper {
    position: relative;
    display: inline-block;
    padding: 8px;
    border-radius: 10px;
    min-width: 150px;
    background-color: #fff;

    .result-status-icon {
        position: absolute;
        top: 50%;
        right: 8px;
        transform: translate(0, -50%);
        display: block;

        & > svg {
            display: block;
        }
    }

    .resultanswer-content {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        padding-right: 32px;
        min-height: 22px;

        .answer-letter {
            display: block;
            min-width: 30px;
            width: 30px;
            height: 30px;
            line-height: 30px;
            background-color: c.$primary;
            color: #fff;
            border-radius: 50%;
            font-size: 18px;
            text-align: center;
        }
    }

    &.is-correct {
        background-color: rgba(0, 255, 41, 0.1);

        .resultanswer-content {
            .answer-letter {
                background-color: c.$success;
            }
        }
    }

    &.is-incorrect {
        background-color: #ffe6e6;

        .resultanswer-content {
            .answer-letter {
                background-color: c.$danger;
            }
        }
    }
}
