.organization-user-list.org-teacher-list {
    .organization-user-list-actions {
        min-width: 114px;

        svg {
            font-size: 25px;
            width: 25px;
            height: 25px;
        }
    }
}

// RESPONSIVE:
@media screen and (max-width: 991.98px) {
    .organization-user-list.org-teacher-list {
        .organization-user-list-actions {
            min-width: 86px;

            svg {
                font-size: 18px;
                width: 18px;
                height: 18px;
            }
        }
    }
}
