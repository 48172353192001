@use 'src/assets/styles/utilities/colors' as colors;

$icon-size: calc((30 / 1440) * 100vw);
$class-img-size: calc((243 / 1440) * 100vw);

@function size-by-vw($rate-size, $min-size) {
    @return max($min-size, calc((($rate-size) / 1440) * 100vw));
}

.class-header {
    .class-header-title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 16px;

        .title-icon,
        .title-icon svg {
            display: block;
        }
    }
}

.detail-class-header {
    position: relative;
    padding: 30px;
    background-color: colors.$primary;
    border-radius: 20px;

    .detail-class-header-wrapper {
        display: flex;
        align-items: center;

        .class-img-spinner {
            min-width: 200px;
            width: 200px;
            text-align: center;

            .ant-spin-blur {
                opacity: 1;
                border-radius: 10px;
                overflow: hidden;
            }

            .ant-spin-container {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .class-img-wrapper {
            display: inline-block;
            position: relative;
            border-radius: 10px;
            overflow: hidden;
            object-fit: contain;
            // width: max($class-img-size, 243px);
            height: 120px;
            img {
                width: auto;
                height: 100%;
            }
            &.loading {
                img {
                    filter: brightness(80%) blur(2px);
                }
            }
        }

        .class-img-actions-wrapper {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba($color: #000000, $alpha: 0.2);
            .class-img-actions {
                padding: 8px;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 8px;
            }
            .action-btn {
                width: 30px;
                height: 30px;
                background-color: #eee;
                width: 20px;
                height: 20px;
                display: block;
                border-radius: 50%;
                cursor: pointer;
                &:hover {
                    opacity: 0.8;
                }
            }
            .action-btn > span:first-child {
                display: block;
                height: 100%;
            }
            .action-btn > span:first-child svg {
                height: 100%;
                max-width: 100%;
            }
        }

        .class-img-wrapper {
            .class-img-actions-wrapper {
                // display: none;
                opacity: 0;
                visibility: hidden;
                transition: all 0.2s ease;
            }
            &:hover {
                .class-img-actions-wrapper {
                    // display: block;
                    opacity: 1;
                    visibility: visible;
                }
            }
        }

        ul {
            width: 100%;
            list-style: none;
            display: flex;
            justify-content: space-evenly;
            align-items: flex-start;
            margin: 0;
            padding: 0;

            li {
                color: #fff;
                overflow-wrap: anywhere;

                p {
                    margin-bottom: 5px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;

                    span {
                        margin-bottom: 5px;
                        font-size: 16px;
                        line-height: 25px;
                    }

                    .detail-class-icon {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: max($icon-size, 30px);
                        height: max($icon-size, 30px);
                        background-color: #fff;
                        border-radius: 50%;
                        color: colors.$primary;
                    }

                    strong {
                        font-size: 20px;
                        line-height: 25px;
                    }

                    strong.show-more {
                        letter-spacing: 2px;
                        font-size: 25px;
                        line-height: 25px;
                        cursor: pointer;
                    }
                }
            }
        }
        .detail-class-item-title > span {
            display: none;
        }
    }
}

.class-header-dropdown-menu {
    background-color: #fff;
    color: colors.$primary;
    font-size: 18px;
    font-weight: lighter;
    line-height: 25px;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0px 0px 10px 5px rgb(0, 0, 0, 0.1);
    max-height: 235px;
    overflow-y: auto;

    strong {
        display: block;
        border-bottom: 1px solid #ececec;
        padding: 10px 20px;
    }
}

@media screen and (min-width: 1920px) {
    .detail-class-header {
        &-wrapper {
            ul li p {
                .detail-class-icon {
                    img {
                        width: 50%;
                    }
                }
                strong {
                    font-size: size-by-vw(20, 20px) !important;
                    line-height: size-by-vw(35, 35px) !important;
                }
                span {
                    font-size: size-by-vw(16, 16px) !important;
                    line-height: size-by-vw(25, 25px) !important;
                }
            }
        }
    }
}

@media screen and (max-width: 1399.98px) {
    .detail-class-header {
        .detail-class-header-wrapper {
            flex-direction: row;
            column-gap: 20px;
            row-gap: 0;

            ul {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(111px, max-content));
                grid-gap: 20px;
                justify-content: center;
                align-items: flex-start;
            }
        }
    }
}

@media screen and (max-width: 767.98px) {
    .detail-class-header {
        padding: 20px;
        .detail-class-header-wrapper {
            flex-direction: row;
            row-gap: 20px;
            ul {
                display: flex;
                justify-content: flex-start;
                flex-direction: column;
                grid-gap: 4px;
                li {
                    width: 100%;
                }
            }
            ul li p {
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                column-gap: 20px;
                margin-bottom: 0;
                strong {
                    font-size: 16px;
                    line-height: size-by-vw(35, 35px);
                }
                span {
                    font-size: 12px;
                }
            }
            .detail-class-item-title {
                font-weight: 700;
                font-size: size-by-vw(20, 16px);
                line-height: size-by-vw(35, 20px);
                margin-bottom: 0;
            }
            .detail-class-item-title > span {
                display: inline-block;
                font-weight: 700;
                font-size: size-by-vw(20, 20px);
                line-height: size-by-vw(35, 35px);
            }
        }
    }
}

@media screen and (max-width: 575.98px) {
    .detail-class-header {
        // border-radius: 10px;
        .detail-class-header-wrapper {
            flex-direction: column;
            .detail-class-item-title {
                display: flex;
                align-items: center;
                flex-wrap: nowrap;
                line-height: 40px;
                > span {
                    line-height: 40px;
                    margin-left: 2px;
                    margin-bottom: -1px;
                }
            }
        }
    }
}
