.target-controller-wrapper {
    position: relative;
    min-height: 100vh;

    .tc-title {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
