.help-iframe-embedlink {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    width: 100%;
    height: 100%;

    .embedlink-viewer {
        position: relative;
        background-color: #eee;
        width: 100%;
        height: 600px;
        border-radius: 24px;
        animation: skeletonLoadingChangeBgColor 0.5s ease infinite alternate;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 9;
        }

        & > iframe {
            border: none;
            outline: none;
            width: 100%;
            height: 100%;
            border-radius: 24px;
            position: relative;
            z-index: 10;
        }
    }
}

// RESPONSIVE:
@media screen and (max-height: 767.98px) {
    .help-iframe-embedlink {
        display: block;
    }
}
