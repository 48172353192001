@use 'src/assets/styles/utilities/variables' as vars;
@use 'src/assets/styles/utilities/colors' as colors;

.class-list.rounds.rounds-student {
    .test_status {
        font-size: 12px;
        text-align: center;
        display: inline-block;
        margin: 2px 10px;
        padding: 3px 20px;
        border-radius: 5px;
        background-color: #ddd;
        line-height: 1.5;

        &.test_status-danger {
            color: #ff0000;
            background-color: rgba($color: #ff0000, $alpha: 0.1);
        }

        &.test_status-success {
            color: #129d29;
            background-color: rgba($color: #00ff29, $alpha: 0.1);
        }

        &.test_status-info {
            color: #0077ff;
            background-color: rgba($color: #0077ff, $alpha: 0.1);
        }

        &.test_status-warn {
            color: #fecc32;
            background-color: rgba($color: #fecc32, $alpha: 0.1);
        }

        &.test_status-disable {
            color: #9ea7ad;
            background-color: rgba($color: #9ea7ad, $alpha: 0.1);
        }
    }
}
