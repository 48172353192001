.imgFallback {
    width: 100%;
    height: 100%;
    &.imgFallback--loading {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;

        &:before {
            content: "";
            // background: url("../../../assets/images/no-avatar.png") no-repeat center center;
            background-size: contain;
            background-color: #fff;
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            z-index: 1;
        }
    }

    img {
        width: 100%;
        height: 100%;
    }
}
