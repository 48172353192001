@use 'src/assets/styles/utilities/colors' as c;

@mixin styleText($type: default) {
    @if $type==text45 {
        line-height: 45px;
    } @else if $type==text36 {
        line-height: 36px;
    }
}

.question-detail.imgwithlabels-dropdown {
    $grab-area: 64px;

    .question-formitems.question-setanswers {
        .ant-form-item-label > label {
            min-width: 50px;
        }

        .action-btn {
            .btn__content {
                background-color: #ddd;
            }

            .btn__icon-wrapper > svg {
                fill: #000;
                width: 24px;
            }

            &.danger:hover {
                .btn__content {
                    background-color: c.$danger;
                }

                .btn__icon-wrapper > svg {
                    fill: #fff;
                }
            }
        }

        .dragdrop-btn {
            position: absolute;
            top: 1px;
            right: 1px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            min-width: $grab-area;
            background: linear-gradient(90deg, #fff, #eee);
            border-radius: 0 10px 10px 0;
            min-height: calc(45px - 2px);
            transition: all 0.3s;

            svg {
                width: 16px;
                height: auto;
            }

            & > span {
                padding: 4px;

                &:first-child {
                    margin-left: 4px;
                }

                &:last-child {
                    margin-right: 4px;
                }
            }
        }

        .question-answer-list {
            .list-item {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 8px;
            }

            .list-item-order {
                font-size: 16px;
                @include styleText($type: text45);
            }

            .list-item-answer {
                position: relative;
                width: 100%;

                .ant-form-item .ant-input {
                    padding-right: $grab-area;
                }
            }

            .list-item-actions {
                display: flex;
                justify-content: center;
                align-items: center;
                min-height: 45px;
                overflow: visible;

                &::after {
                    content: ".";
                    white-space: nowrap;
                    width: 0;
                    visibility: hidden;
                    opacity: 0;
                }

                .action-wrapper {
                    height: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }

            .list-item-answer-drg.imglbl-drg-wrapper {
                .imglbl-drg {
                    top: 0;
                    right: 0;
                    bottom: auto;
                    left: auto;
                    width: 28px;
                    opacity: 0;

                    .ant-form-item {
                        margin-bottom: 0;
                    }

                    &.react-draggable-dragging {
                        width: 130px !important;
                        opacity: 0.8;
                        transition: width 0.2s ease, opacity 0.2s ease;
                    }
                }
            }
        }
    }
}

// RESPONSIVE:
@media screen and (max-width: 1399.98px) {
    .question-detail.imgwithlabels-dropdown {
        .question-formitems.question-setanswers {
            .dragdrop-btn {
                min-height: calc(36px - 2px);

                &::after {
                    content: ".";
                    width: 0;
                    visibility: hidden;
                    opacity: 0;
                }
            }

            .question-answer-list {
                .list-item-order {
                    @include styleText($type: text36);
                }

                .list-item-actions {
                    min-height: 36px;
                }
            }
        }
    }
}
