.question-detail-hotspot {
    .hotspot-areas,
    .hotspot-correct-answer {
        margin-top: 40px;
        h3 {
            font-size: 20px;
            font-weight: 600;
        }
    }

    .hotspot-areas-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        .hotspot-image-botton {
            display: flex;
            justify-content: space-between;
            gap: 10px;
            flex-wrap: wrap;
        }
        h3 {
            flex: 1;
        }
        button {
            cursor: pointer;
        }
    }
    .hotspot-areas_editor,
    .hotspot-correct-answer_editor {
        height: auto;
        min-height: 500px;
        justify-content: center;
    }
    .zwibbler-draw-container {
        flex: 1;
        width: 100%;
        max-height: 80vh;
        min-height: 500px;
    }
    .image-with-labels {
        align-items: unset;
    }
    .hotspot-areas_editor {
        overflow: hidden;
        width: 100%;
    }
}
