.homeStudent_wrapper {
    .cardList {
        .cardItem {
            .cardItem_info {
                .cardItem_scoreWrapper {
                    color: #0077ff;
                    display: flex;
                    align-items: flex-end;

                    .cardItem_score {
                        font-size: 35px;
                        font-weight: bold;
                        margin-right: 5px;
                    }

                    .cardItem_unit {
                        font-size: 14px;
                        padding: 10px 0;
                    }
                }

                .cardItem_board {
                    font-size: 14px;
                    span {
                        font-weight: bold;
                    }
                }
            }
        }
    }
}
