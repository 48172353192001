.hotspot-areas {
    .hotpot-editor-wrapper {
        .zwibbler-canvas-holder {
            padding: 0;
        }
    }
    .zwibbler-draw-container {
        .zwibbler-container {
            width: fit-content;
            // overflow-x: auto;
            margin: auto;
        }
        .image-resize-wrapper {
            display: flex;
            justify-content: center;
        }
    }
    .image-resize-wrapper {
        // min-width: 400px;
        // min-height: 400px;
    }

    .image-src-wrapper.empty {
        min-width: 500px;
        min-height: 500px;
    }
}
