.ranking {
    padding: 20px 30px;

    // Elements:
    .filter-toolbar-wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        column-gap: 30px;
        row-gap: 10px;

        .filter-toolbar {
            flex: 1 1 0%;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            column-gap: 30px;
            row-gap: 10px;

            .app-tag {
                // display: block;
            }

            .filter-toolbar-item {
                flex: 1 1 0%;
                min-width: 200px;
                position: relative;

                &:nth-child(1) {
                    max-width: 500px;
                }

                &.filtermenu-wrapper {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-end;
                    align-items: center;
                    gap: 8px;
                    min-height: unset;

                    .filter-keys-bar-wrapper {
                        max-width: calc(100% - 175px - 8px);
                    }
                }
            }
        }

        .filter-toolbar-actions {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 10px;
        }
    }

    .filter-keys-bar-wrapper {
        width: 100%;
        height: 100%;
        // min-height: 45px;
        // background-color: #fff;
        // border: 1px solid #d9d9d9;
        border-radius: 10px;
        font-size: 14px;
        font-style: italic;
        color: rgba(0, 0, 0, 0.5);
        // padding: 4px 11px;
        transition: all 0.3s;
        cursor: pointer;

        &:hover {
            border-color: #40a9ff;
        }

        .filter-keys-bar {
            width: 100%;
            height: 100%;
            min-height: 35px;
            justify-content: right;

            .ant-space-item {
                max-width: 100%;
                line-height: initial;
            }
        }
    }
    .ant-table-filter-trigger {
        font-size: 16px;
    }

    .filterButton {
        .help {
            .act-button.question {
                display: block;
                height: 38px;
                width: 38px;
                width: clamp(30px, calc(45 / 1440 * 100vw), 45px);
                height: clamp(30px, calc(45 / 1440 * 100vw), 45px);
                border-radius: 50%;
                border: 1px solid #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #0077ff;
                cursor: pointer;
                svg {
                    fill: #fff;
                    font-size: clamp(16px, calc(20 / 1440 * 100vw), 20px);
                }
                &:hover {
                    opacity: 0.6;
                }
                &.outline {
                    border: 2px solid #445cfe;
                    background-color: transparent;
                    svg {
                        fill: #0077ff;
                    }
                    &:hover {
                        opacity: 1;
                        border: none;
                        background-color: #0077ff;
                        svg {
                            fill: #fff;
                        }
                    }
                }
            }
        }
    }
}

.table-search .ant-input-affix-wrapper {
    padding: 4px;
    border-radius: 5px;
    overflow: hidden;
}

.ranking-table {
    .ant-table-filter-dropdown {
        border-radius: 10px;
        overflow: hidden;
    }

    // do bị css bên question bank ghi đề nên phải ghi d
    .ant-table table colgroup col {
        &:nth-child(1) {
            width: 400px !important;
            min-width: 350px;
        }

        &:nth-child(2) {
            width: 200px !important;
            min-width: 150px;
        }

        &:nth-child(3) {
            width: 200px !important;
            min-width: 150px;
        }

        &:nth-child(4) {
            width: auto !important;
            min-width: 100px;
        }

        &:nth-child(5) {
            width: 150px !important;
            min-width: 100px;
        }

        &:nth-child(6) {
            width: 150px !important;
            min-width: 100px;
        }

        &:nth-child(7) {
            width: 150px !important;
            min-width: 100px;
        }

        &:nth-child(8) {
            width: 200px !important;
            min-width: 150px;
        }

        &:nth-child(9) {
            width: auto !important;
            min-width: 100px;
        }
    }
}
