@use 'src/assets/styles/utilities/colors' as colors;

.app-reactions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    .reaction-btn {
        min-height: unset;

        &.btn.btn-icon-only .btn__content {
            background-color: #dedede;
            box-shadow: 0 1px 0px 0px rgba(0, 0, 0, 0.2);
            transition: all 0.5s ease;
        }

        .btn__icon-wrapper svg {
            fill: #aaa;
            height: 22px;
            max-width: 22px;
            transition: all 0.5s ease;
        }

        &:disabled,
        &.disabled {
            .btn__icon-wrapper svg {
                fill: #cdcfd0;
            }
        }
    }

    .reaction-item {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0;
        flex-direction: column;
    }

    .reaction-action {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 45px;
        height: 45px;
        background-color: #eee;
        border-radius: 50%;

        &.act-love {
            position: relative;

            &.active {
                .reaction-btn {
                    &.btn.btn-icon-only .btn__content {
                        background-color: #ff4d4f;
                    }

                    .btn__icon-wrapper svg {
                        fill: #fff;
                        height: 18px;
                        max-width: 18px;
                    }
                }
            }

            &.loading {
                pointer-events: none;

                &::after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 100%;
                    height: 100%;
                    background: transparent;
                    border: 2px solid #ff4d4f;
                    border-radius: 50%;
                    border-bottom-color: transparent;
                    border-left-color: transparent;
                    animation-name: loading;
                    animation-duration: 1s;
                    animation-delay: 0;
                    animation-iteration-count: infinite;
                    animation-direction: normal;
                    animation-timing-function: ease;
                    animation-fill-mode: forwards;
                }
            }
        }

        // &.act-clone {}
    }

    .reaction-count {
        font-size: 14px;
        color: rgba($color: #000000, $alpha: 0.5);
    }
}

@keyframes loading {
    from {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    to {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}
