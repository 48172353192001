.fm-files {
    flex: 1;
    border-left: 1px solid #d9d9d9;
    display: flex;
    flex-direction: column;
    position: relative;
    .file-manager-breadcrumb {
        padding-left: 5px;
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            font-size: 12px;
            line-height: 22px;
            li {
                margin-right: 2px;
                &:before {
                    content: "/";
                    margin-right: 1px;
                    position: relative;
                    top: -1px;
                }
            }
        }
    }
    .fm-file-items {
        flex: 1;
    }
    .fm-grid-files {
        display: flex;
        flex-wrap: wrap;
        flex: 1;
        align-items: flex-start;
        align-content: flex-start;
        .file-manager-file-item {
            flex: 0 0 120px;
            max-width: 120px;
            margin: 5px;
            background-color: transparent;
            color: #3f3f3f;
            padding: 5px;
            border-radius: 3px;
            user-select: none;
            // transition: all 300ms;
            &:hover {
                background-color: #f2f2f2;
            }
            &.selected {
                background-color: #58a3c9;
                color: #fff;
            }
            &.focused {
                background-color: #58a3c9;
                color: #fff;
            }
            .fm-item-banner {
                margin-bottom: 5px;
                &.fm-item-banner-folder {
                    text-align: center;
                    svg {
                        height: 50px;
                        fill: #3f3f3f;
                    }
                }
                &.fm-item-banner-file {
                    text-align: center;
                    svg {
                        height: 50px;
                        fill: #3f3f3f;
                    }
                    img {
                        height: 50px;
                        max-width: 100%;
                        object-fit: contain;
                    }
                }
            }
            &.selected {
                .fm-item-banner.fm-item-banner-folder svg {
                    fill: #fff;
                }
                .fm-item-banner.fm-item-banner-file svg {
                    fill: #fff;
                }
            }
            .fm-item-file-name {
                text-align: center;
                font-size: 12px;
                word-wrap: break-word;
                white-space: -moz-pre-wrap;
                white-space: pre-wrap;
            }
            .fm-item-file-name-edit {
                display: block;
                width: 100%;
                resize: none;
                color: #000;
                background-color: rgba(255, 255, 255, 0.8);
                border: none;
                outline: none;
                border-radius: 2px;
                padding: 0px;
                height: 22px;
                min-height: unset;
                max-height: unset;
            }
        }
    }
}

.fm-files.display-as-list {
    .fm-grid-files {
        flex-direction: column;
        justify-content: stretch;
        align-items: stretch;
        align-content: stretch;
        .file-manager-file-item {
            max-width: calc(100% - 10px);
            flex: unset;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 8px;
            transition: all 300ms;
            overflow: hidden;
            .fm-item-banner {
                &.fm-item-banner-folder {
                    width: 30px;
                    height: 20px;
                    svg {
                        width: auto;
                        height: 100%;
                    }
                }
                &.fm-item-banner-file {
                    width: 30px;
                    height: 20px;
                    min-width: 30px;
                    svg {
                        width: auto;
                        height: 100%;
                    }
                    img {
                        width: auto;
                        height: 100%;
                    }
                }
            }
            .fm-item-file-name {
                text-align: left;
                white-space: nowrap;
            }
        }
    }
}

.fm-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #d9d9d9;
}

.fm-settings {
    display: flex;
    & > .setting-btn {
        display: inline-block;
        width: 24px;
        height: 24px;
        background: #eee;
        cursor: pointer;
        & > span {
            display: block;
            & > svg {
                height: 24px;
                max-width: 15px;
            }
        }
        &:hover {
            background-color: #bbb;
        }
        &:not(:first-child) {
            margin-left: 8px;
        }
    }
    & > .setting-btn.selected {
        border: 1px solid #aaa;
        & > span {
            & > svg {
                height: 22px;
            }
        }
    }
}

.fm-files-spin {
    &.ant-spin-nested-loading {
        width: 100%;

        .ant-spin-container {
            height: 100%;
        }

        .fm-files {
            height: 100%;
        }
    }
}
