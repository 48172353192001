// ClassNames: app-spin-nested-loading.

// (Ant Design) Spin:
.ant-spin-nested-loading.app-spin-nested-loading {
    &.layout-full {
        & > div > .ant-spin {
            max-height: unset;
        }
    }
}
