@use 'src/assets/styles/utilities/colors' as colors;

.shade-grid {
    display: flex;
    flex-wrap: wrap;

    &-ul {
        display: inline-flex;
        flex-direction: row;
        // flex-wrap: wrap;
        list-style: none;
        justify-content: center;
        align-items: center;
        margin: 0;
        padding: 0;
        background-color: #fff;

        li {
            margin: 1px;
            &:hover {
                border: 3px solid rgb(2, 157, 73) !important;
            }
        }
    }
}

.shade-answer-status {
    display: flex;
    align-items: center;
    span {
        font-size: 18px;
        // padding: 0 10px;
    }
    span.correct {
        color: "#4fe3c1";
    }
    span.incorrect {
        color: colors.$danger;
    }
}
