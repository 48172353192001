.pdf-thumbnails {
    max-height: 100%;

    height: 100%;

    .thumbnails-list {
        max-height: calc(100vh - 100px);
        overflow-y: auto;

        height: 100%;
        display: flex;
        flex-direction: column;
        padding-bottom: 20px;
        // display: none;
        max-width: 0;
        visibility: hidden;
        transition: all linear 0.02s;
        &.show {
            // display: flex;
            max-width: 140px;
            visibility: visible;
        }
    }

    .actions {
        display: flex;
        align-items: center;
        gap: 20px;
        padding: 4px;
        border-radius: 4px;
        margin-bottom: 15px;
        > {
            box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.2);
            background-color: #fff;
        }
    }
}
