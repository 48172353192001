.file-manager-sidebar {
    overflow: auto;
    height: 100%;
    padding: 10px 20px 10px 11px;
    flex: 0 0 200px;

    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        ul {
            margin-left: 10px;
        }
    }

    .file-manager-sidebar-item {
        border-radius: 5px;

        .file-manager-sidebar-item-label {
            display: flex;
            align-items: center;
            cursor: pointer;
            margin-bottom: 2px;
            user-select: none;
        }

        .file-manager-sidebar-item-collapse-target {
            cursor: pointer;
            flex: 0 0 14px;
            text-align: center;
            line-height: 18px;
            margin-right: 5px;
        }

        .file-manager-sidebar-item-name {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 8px;
            padding: 2px 5px 3px 3px;
            border-radius: 3px;
            background-color: transparent;
            color: #3f3f3f;
            transition: all 300ms;
            .item-icon {
                display: block;
                height: 20px;
                & > svg {
                    display: block;
                    width: auto;
                    height: 100%;
                }
            }
            .item-name {
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                width: 100%;
            }
            &:hover {
                background-color: #e3e3e3;
            }
        }

        &.active {
            > .file-manager-sidebar-item-label > .file-manager-sidebar-item-name {
                background-color: #58a3c9;
                color: #fff;
            }
        }

        &.skeleton-loading {
            height: 27px;
            animation: skeletonLoadingChangeBgColor 0.5s ease infinite alternate;
        }

        &.has-scope-line {
            position: relative;

            &::after {
                content: "";
                position: absolute;
                top: 22px;
                left: 6.5px;
                width: 1px;
                height: calc(100% - 22px);
                background-color: rgba($color: #000000, $alpha: 0.2);
            }
        }
    }
}
