.learningProgressReport_wrapper {
    padding: 20px 30px 30px 30px;

    .learningProgressReport_header {
        margin-bottom: 30px;
    }

    .learningProgressReport_container {
        .card-list {
            display: grid;
            gap: 30px;

            .learning-progress-report-nav-card {
                max-width: unset;
            }
        }
    }
}

// RESPONSIVE:
@media screen and (min-width: 992px) {
    .learningProgressReport_wrapper {
        .learningProgressReport_container {
            .card-list {
                grid-template-columns: repeat(3, 1fr);
            }
        }
    }
}

@media screen and (min-width: 1440px) {
    .learningProgressReport_wrapper {
        .learningProgressReport_container {
            .card-list {
                grid-template-columns: repeat(2, 410px);
            }
        }
    }
}

@media screen and (max-width: 991.98px) {
    .learningProgressReport_wrapper {
        .learningProgressReport_container {
            .card-list {
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }
}

@media screen and (max-width: 575.98px) {
    .learningProgressReport_wrapper {
        .learningProgressReport_container {
            .card-list {
                grid-template-columns: 1fr;
            }
        }
    }
}
