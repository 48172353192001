// ClassNames: app-input.

// Input, Input.Number:
.app-input.ant-input,
.app-input.ant-input-affix-wrapper {
    padding: 4px 16px;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    font-size: 14px;
    line-height: 35px;
    overflow: hidden;

    &::placeholder {
        font-size: 14px;
        line-height: 35px;
        font-style: italic;
        color: rgba(0, 0, 0, 0.5);
    }

    .ant-input-prefix {
        margin-right: 10px;
    }

    &:focus,
    .ant-input-focused {
        border-color: #d9d9d9;
    }

    &:not(.ant-input-affix-wrapper-disabled):hover {
        border: 1px solid #d9d9d9;
    }
}

.app-input.ant-input-affix-wrapper > input.ant-input {
    &::placeholder {
        font-size: 14px;
        line-height: 35px;
        font-style: italic;
        color: rgba(0, 0, 0, 0.5);
    }
}

.app-input.ant-input-number {
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    font-size: 14px;
    line-height: 35px;
    overflow: hidden;

    &:focus,
    .ant-input-number-focused {
        border-color: #d9d9d9;
    }

    .ant-input-number-input {
        height: auto;
        padding: 4px 16px;

        &::placeholder {
            font-size: 14px;
            line-height: 35px;
            font-style: italic;
            color: rgba(0, 0, 0, 0.5);
        }
    }
}

.app-input.ant-input-group-wrapper {
    .ant-input {
        padding: 4px 16px;
        border: 1px solid #d9d9d9;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        font-size: 14px;
        line-height: 35px;
        overflow: hidden;

        &::placeholder {
            font-size: 14px;
            line-height: 35px;
            font-style: italic;
            color: rgba(0, 0, 0, 0.5);
        }
    }

    .ant-input-group-addon {
        &:first-child {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
        }

        &:last-child {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }
    }
}

.app-input.ant-input-number-group-wrapper {
    .ant-input {
        padding: 4px 16px;
        border: 1px solid #d9d9d9;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        font-size: 14px;
        line-height: 35px;
        overflow: hidden;

        &::placeholder {
            font-size: 14px;
            line-height: 35px;
            font-style: italic;
            color: rgba(0, 0, 0, 0.5);
        }
    }

    .ant-input-number-group-addon {
        &:first-child {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
        }

        &:last-child {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }
    }
}

// Input.TextArea:
textarea.app-input.ant-input {
    font-size: 14px;
    line-height: 35px;
    min-height: 36px;
    overflow: auto;

    &::placeholder {
        font-size: 14px;
        line-height: 35px;
    }
}

// RESPONSIVE DESIGN:
.has-rd {
    @media screen and (max-width: 1399.98px) {
        &.app-input.ant-input,
        &.app-input.ant-input-affix-wrapper {
            line-height: 26px;
        }

        &.app-input.ant-input-number {
            line-height: 26px;
        }

        &.app-input.ant-input-group-wrapper,
        &.app-input.ant-input-number-group-wrapper {
            .ant-input {
                line-height: 26px;
            }
        }
    }
}

textarea.has-rd {
    @media screen and (max-width: 1399.98px) {
        &.app-input.ant-input {
            line-height: 26px;

            &::placeholder {
                line-height: 26px;
            }
        }
    }
}
