.answer-for-drag-drop {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;

    .sec-left {
        width: 60%;
    }

    .sec-right {
        width: 40%;
        min-height: 38px;
        padding: 8px;
    }

    .resultanswer-wrapper {
        width: 100%;
    }
}
