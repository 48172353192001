.student-card {
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .info {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .info-target {
            display: flex;
            align-items: center;

            .avatar-img {
                flex: none;
                display: inline-block;
                border-radius: 50%;
                width: 50px;
                height: 50px;
                overflow: hidden;

                img {
                    height: 100%;
                }
            }

            p {
                margin-left: 10px;
                margin-bottom: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;

                span {
                    &:first-child {
                        font-size: 18px;
                        font-weight: 700;
                    }

                    &:last-child {
                        font-size: 14px;
                        color: #ff0e0e;
                    }

                    &.graded {
                        &:last-child {
                            color: #129d29;
                        }
                    }
                }
            }
        }

        .action-list-wrapper {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 10px;
            margin-left: 10px;
            align-self: baseline;

            .view-detail-btn {
                cursor: pointer;
                color: #0077ff;
                white-space: nowrap;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .ant-checkbox-wrapper.app-checkbox.small-checkbox {
            align-self: baseline;
        }
    }

    .progress {
        margin-top: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        > span {
            &:first-child {
                font-weight: 400;
                font-size: 14px;
                line-height: 35px;
                color: rgba(0, 0, 0, 0.5);
            }

            &:last-child {
                font-size: 18px;
                line-height: 35px;
                font-weight: 700;
                color: #75b136;
            }
        }
    }

    .score {
        margin-top: -8px;
        margin-bottom: 8px;
        font-weight: 700;
        font-size: 14px;
        line-height: 35px;
    }

    .statistical-status-bar {
        width: 100%;
        display: grid;
        align-items: center;
        grid-template-columns: repeat(auto-fit, minmax(35px, 1fr));
        grid-gap: 1px;
        background-color: #f5f5f5;
        min-height: 8px;

        // &.fixed-row-of-10-blocks {
        //     grid-template-columns: repeat(10, 1fr);
        // }

        .block {
            display: block;
            width: 100%;
            background-color: #000;
            height: 8px;

            &:first-child {
                margin-left: 0;
            }

            &.correct {
                background-color: #129d29;
            }

            &.incorrect {
                background-color: #e56965;
            }

            &.hand {
                background-color: #f8cc5a;
            }

            &.part {
                background-color: #0077ff;
            }

            &.no-record {
                background-color: #dddddd;
            }

            &:hover {
                border: 1px solid #000;
            }
        }
    }
}
