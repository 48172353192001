.payment-result-panel {
    background-color: #fff;
    padding: 0 16px;
    border-radius: 10px;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
    font-size: 16px;
    max-width: 850px;
    margin: 0 auto;

    .price {
        & > span:first-child {
            margin-right: 2px;
        }
    }

    .status-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;

        &.skeleton {
            height: 42px;
            border-radius: 10px;
            animation: skeletonLoadingChangeBgColor 0.5s ease infinite alternate;
        }

        .status-icon {
            display: inline-block;
            width: 100px;
            height: 100px;
            min-width: 100px;

            & > svg {
                display: block;
                height: 100%;
                width: auto;
                max-width: 100%;
                margin: 0 auto;
            }
        }
    }

    .result-header {
        font-size: 18px;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        padding-top: 8px;
        margin-bottom: 24px;
    }

    .result-body {
        margin-bottom: 8px;

        .body-section {
            padding: 8px 0;
            border-bottom: 1px solid #dedfe0;

            &.skeleton {
                height: 42px;
                border-bottom: none;
                border-radius: 10px;
                animation: skeletonLoadingChangeBgColor 0.5s ease infinite alternate;

                &:not(:last-child) {
                    margin-bottom: 2px;
                }
            }
        }
    }

    .result-header,
    .result-body {
        min-height: 42px;
    }

    .result-footer {
        text-align: center;
        padding-top: 8px;
        padding-bottom: 16px;
    }

    .order-items-info,
    .payment-info {
        .ant-row {
            .ant-col:nth-child(2n + 1) {
                font-weight: 500;
            }

            .ant-col:nth-child(2n) {
                font-weight: 700;
            }
        }
    }
}
