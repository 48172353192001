@use 'src/assets/styles/utilities/variables' as vars;

.filter-personal-learning-progress_wrapper {
    background-color: #fff;
    padding: 50px 30px 34px 77px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    max-height: 400px;

    // Form items:
    .ant-form-horizontal .ant-form-item-label {
        text-align: left;
        width: 100%;
        padding: 0;
    }

    // Custom elements:
    .ant-form.filter-personal-learning-progress_inner {
        max-height: 350px;
        overflow-y: auto;

        .ant-form-item-control {
            min-width: auto;
        }

        .filter-person_input {
            padding: 22px 20px;
            border-radius: 10px;
            height: 55px;
        }

        .filter-person_selector {
            .ant-select-selector {
                padding: 0 0 0 20px;
                height: auto;
                border-radius: 10px;
                height: 55px;
                align-items: center;

                .ant-select-selection-search {
                    display: flex;
                    align-items: center;
                    padding-left: 9px;
                }
            }
        }

        .ant-form-item {
            flex-direction: column;
            padding: 0px 15px;

            .ant-col.ant-form-item-label {
                font-weight: bold;
            }

            .ant-picker.filter-person_input {
                width: 100%;
            }
        }
    }

    .filter_btn {
        // display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        row-gap: 30px;
        column-gap: 32px;
        margin-top: 6px;

        .btn {
            flex: 1 1 0%;
            width: 175px;
            max-width: 175px;
        }

        .btn__icon-wrapper {
            display: block;
            height: 29px;

            & > svg {
                display: block;
                height: 100%;
            }
        }
    }

    @media screen and (max-width: 992px) {
        padding: 20px;
    }
}

.filter-limit-height {
    max-height: 170px;
    overflow-y: scroll;
}

// RESPONSIVE:
@media screen and (max-width: 1439.98px) {
    .filter-personal-learning-progress_wrapper {
        padding: clamp(16px, calc(30 / vars.$w * 100vw), 30px);

        // Form items:
        .ant-form-horizontal .ant-form-item-label {
            & > label {
                line-height: clamp(36px, calc(45 / vars.$w * 100vw), 45px);
            }
        }

        // ANT DESIGN - Input:
        // .ant-form-item-control-input-content .ant-input {
        //     line-height: clamp(26px, calc(35 / vars.$w * 100vw), 35px);
        // }

        // ANT DESIGN - Select:
        .ant-form.filter-personal-learning-progress_inner .filter-person_selector .ant-select-selector {
            height: auto;
            min-height: clamp(36px, calc(45 / vars.$w * 100vw), 45px);

            &:after {
                line-height: clamp(26px, calc(30 / vars.$w * 100vw), 30px);
            }

            .ant-select-selection-placeholder,
            .ant-select-selection-item {
                line-height: clamp(26px, calc(35 / vars.$w * 100vw), 35px);
                height: auto;
                transform: none;
            }
        }

        // ANT DESIGN - DatePicker:
        .ant-form.filter-personal-learning-progress_inner .ant-form-item .ant-picker.filter-person_input {
            padding: 4px 20px;
            height: auto;

            .ant-picker-input > input {
                line-height: clamp(26px, calc(35 / vars.$w * 100vw), 35px);
            }
        }

        // ANT DESIGN - InputNumber:
        // .ant-input-number {
        //     line-height: clamp(26px, calc(35 / vars.$w * 100vw), 35px);
        // }

        // Filter buttons:
        .filter_btn {
            gap: clamp(16px, calc(30 / vars.$w * 100vw), 30px);

            .btn {
                min-width: clamp(140px, calc(175 / vars.$w * 100vw), 175px);
                max-width: clamp(140px, calc(175 / vars.$w * 100vw), 175px);
                font-size: 16px;
                padding: 4px 8px;
            }
        }
    }
}
