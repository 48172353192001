@keyframes transitionDown {
    from {
        transform: translateY(-20%);
        opacity: 0;
    }
    to {
        transform: translateY(0%);
        opacity: 1;
    }
}

.profile_wrapper {
    min-height: 100vh;

    .profile_scroll {
        max-height: 65vh;
        overflow-y: scroll;
        padding: 20px;
    }

    .profile_headerWrapper {
        padding: 20px 30px 0 30px;
    }

    .profile_btnWrapper {
        padding: 10px;
        height: 10vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .profile_groupItem {
        align-items: center;
        margin-bottom: clamp(0px, calc((24 / 1440) * 100vw), 24px);

        .ant-input-affix-wrapper {
            border-radius: 10px;
            padding: 0 11px;
        }

        .ant-form-item-control-input-content {
            display: flex;
            align-items: center;

            .ant-input {
                // padding: 15.5px 20px;
                padding: clamp(8px, calc((15.5 / 1440) * 100vw), 15.5px) clamp(10px, calc((20 / 1440) * 100vw), 20px);
                border-radius: 10px;
            }
        }

        .ant-form-item-label {
            font-size: 18px;
            font-weight: bold;
            white-space: nowrap;
            text-align: right;
            margin-right: 20px;
            white-space: pre-wrap;
            label {
                height: 20px;
            }
        }
    }

    // .profile_fixHeight {
    // }

    .profile_showPassword {
        cursor: pointer;

        &:active {
            transform: scale(0.97);
        }
    }

    .profile_changePassword {
        color: #0077ff;
        cursor: pointer;
    }

    .profile_form {
        height: calc(100vh - 20px - 90px);
        padding: 30px;
    }

    .profile_boxContent {
        margin: 0;
        height: 70vh;
    }

    .profile_row {
        margin: 5px 0;

        .ant-col {
            // padding: 4px 0;
            width: 100%;
        }
    }

    .profile_btn {
        justify-content: center;
        column-gap: 20px;
        row-gap: 10px;
    }

    .profile_changePasswordForm {
        animation: transitionDown 0.3s ease-out;
    }

    .profile_avatar {
        margin-bottom: 40px;
        .profile_image {
            display: block;
            width: 180px;
            height: 180px;
            border-radius: 50%;
            object-fit: cover;
        }

        .profile_avatarLabel {
            display: block;
            width: 180px;
            height: 180px;
            margin: 0 auto;
            position: relative;
            cursor: pointer;
            box-shadow: 0px 0px 2px #333;
            border-radius: 50%;
            user-select: none;

            &.disabled {
                // cursor: not-allowed;
            }

            &:hover {
                box-shadow: 0px 0px 3px #000;
                border-radius: 50%;
            }

            .profile_avatarloading {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-color: rgba(255, 255, 255, 1);
                box-shadow: 0 0 0 2px #eee;
                border-radius: 50%;
                z-index: 1;

                &.skeleton-loading {
                    animation-name: skeleton-loading;
                    animation-duration: 0.5s;
                    animation-timing-function: ease;
                    animation-delay: 0s;
                    animation-iteration-count: infinite;
                    animation-direction: alternate;
                    animation-fill-mode: both;
                }
            }

            @keyframes skeleton-loading {
                0% {
                    background-color: #f5f5f5;
                }
                100% {
                    background-color: #fff;
                }
            }
        }

        .profile_camera {
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 10;
        }
    }
}

@media screen and (max-width: 1024px) {
    .profile_wrapper {
        .profile_btnWrapper {
            height: 15vh;
        }
    }
}

@media screen and (max-width: 1199px) {
    .profile_wrapper {
        .profile_groupItem {
            .ant-form-item-label {
                text-align: left;
            }
        }
    }
}

// RESPONSIVE DESIGN:
@media screen and (max-width: 1399.98px) {
    .profile_wrapper {
        .profile_form {
            height: calc(100vh - 20px - 80px);
            padding: 30px;
        }
    }
}

@media screen and (max-width: 991.98px) {
    .profile_wrapper {
        .profile_form {
            height: calc(100vh - 20px - 70px);
            padding: 30px;
        }
    }
}

@media screen and (max-width: 575.98px) {
    .profile_wrapper {
        .profile_form {
            height: calc(100vh - 20px - 102px);
            padding: 30px;
        }
    }
}
