.assignment-report-wrapper .answer-record-detail-wrapper {
    // Itself:
    .answer-record-detail {
        padding: 0;
        background-color: #fff;
        border-radius: 20px;
        height: 100%;

        .qc_wrapper {
            height: 100%;
        }

        .qc_panel {
            border-radius: 20px 20px 0 0;
        }
    }

    .result-record-detail {
        height: 100%;

        .result-record-info {
            padding: 20px;
            background-color: #fff;
            border-radius: 20px;
            height: 100%;

            p {
                font-size: 18px;
                margin-bottom: 10px;
            }

            .avatar {
                display: flex;
                align-items: center;

                span {
                    flex: none;
                    display: inline-block;
                    overflow: hidden;
                    border-radius: 50%;
                    width: 50px;
                    height: 50px;
                    margin-right: 10px;

                    img {
                        height: 100%;
                    }
                }
            }

            .score {
                margin-bottom: 20px;
                display: flex;
                align-items: center;

                span {
                    padding: 15px 0;
                    font-size: 35px;
                    line-height: 35px;
                    font-weight: bold;
                    text-align: center;

                    &:first-child {
                        flex: 14;
                        background-color: #eeeeee;
                        color: #0077ff;
                        border-top-left-radius: 5px;
                        border-bottom-left-radius: 5px;
                    }

                    &:last-child {
                        flex: 10;
                        background-color: #0077ff;
                        color: #fff;
                        border-top-right-radius: 5px;
                        border-bottom-right-radius: 5px;
                    }
                }
            }

            .duration {
                & > strong > span {
                    font-size: 30px;
                    margin-right: 2px;
                }
            }

            .teacher-comment {
                width: 100%;
                resize: none;
                border: none;
                outline: none;
                background-color: #eeeeee;
                border-radius: 5px;
                padding: 10px 20px;
            }
        }
    }

    // Other elements from other React components:
    .content-wrapper {
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
    }

    .PassageResult_itemQuestion {
        .PassageResult_answer {
            & > div:first-child {
                margin-top: 50px;
            }

            & > div:not(:last-child) {
                margin-bottom: 50px;
            }
        }
    }

    .fillInTheBlank_answer {
        padding: 5px;
        min-width: 100px;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
    }
}
