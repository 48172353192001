.payment-methods {
    &.ant-collapse {
        border-radius: 10px;

        .menu-item {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            row-gap: 8px;
            column-gap: 16px;

            .menu-item-symbol {
                display: inline-block;
                width: 55px;
                min-width: 55px;
                max-height: 55px;

                img {
                    display: inline-block;
                    width: 100%;
                    height: auto;
                    max-height: 100%;
                }
            }

            .menu-item-content {
                .content-title {
                    font-weight: 700;
                    font-size: 16px;
                }
            }
        }

        & > .ant-collapse-item {
            &.has-no-children {
                .ant-collapse-content {
                    border-top: none;

                    .ant-collapse-content-box {
                        padding: 0;
                    }
                }
            }
        }

        & > .ant-collapse-item:last-child {
            border-radius: 10px;

            & > .ant-collapse-content {
                border-radius: 0 0 10px 10px;
            }
        }

        & > .ant-collapse-item.payment-atm-section {
            .ant-collapse-content {
                max-height: 300px;
                overflow-x: hidden;
                overflow-y: auto;
            }
        }
    }
}
