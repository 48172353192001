.q-result.q-sort {
    .qc_selected-answers {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 8px;
    }

    .qc_correct-answers {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .answer-for-sort {
        width: 100%;
    }
}
