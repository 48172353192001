@use 'src/assets/styles/utilities/colors' as c;
.student-header {
    // position: relative;
    // padding: 50px 30px;
    // background-color: #0077ff;
    // border-radius: 20px;
    // margin: 30px;
    padding: 30px 30px 10px 30px;
    // height: 80px;

    .student-header_container {
        padding: max(calc(23 / 1440) * 100vh, 20px) max(calc(30 / 1440) * 100vh, 30px);
        background-color: c.$primary;
        border-radius: 20px;
        position: relative;
    }
    .student-header-left {
        display: flex;
        .student-header-title {
            margin-left: 20px;
            margin-top: -2px;
        }
    }

    .student-header_controlPanel {
        position: relative;
        z-index: 2;
        display: none;
        justify-content: space-between;
        align-items: center;
        .student-header_showMore {
        }

        .student-header_avatarWrapper {
            img {
                width: 35px;
                height: 35px;
                border-radius: 50%;
            }
        }

        .student-header_notification {
            // margin-left: auto;
            padding: 0 20px;

            img {
                width: 15.81px;
                height: 18.79px;
                filter: brightness(0) invert(1);
            }
        }
        .student-header_help {
            margin-left: auto;
            margin-top: 2px;
            svg {
                display: inline-block;
                font-size: 16px;
                color: #fff;
                line-height: 100%;
                cursor: pointer;
            }
        }
    }
    .student-header-wrapper {
        // width: 100%;
        // svg {
        //     display: block;
        // }

        // p {
        //     display: block;
        // }
        // // display: none;
    }

    .student-header-wrapper {
        // width: 60%;

        p {
            color: #fff;
            margin-bottom: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
        }

        .student-header-title {
            font-size: 26px;
            line-height: 35px;
            font-weight: 700;
            -webkit-line-clamp: 1;
        }

        &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-image: url(../../../assets/images/decoration-clock.svg);
            background-repeat: no-repeat;
            background-position: center right;
            z-index: 1;
            mix-blend-mode: soft-light;
            opacity: 0.25;
        }

        // &::before {
        //     content: "";
        //     position: absolute;
        //     top: 12%;
        //     right: 15%;
        //     width: 164px;
        //     height: 180px;
        //     background-image: url(../../../assets/images/girl_and_book.png);
        //     background-repeat: no-repeat;
        //     background-position: center center;
        //     background-size: contain;
        //     z-index: 1;
        // }

        @media screen and (max-width: 768px) {
            &::before {
                display: none;
            }

            &::after {
                display: none !important;
            }
        }
    }
    .student-header-wrapper-pc {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .student-header-right {
            z-index: 10;
        }
        .act-button.question {
            cursor: pointer;
            svg {
                color: #fff;
                font-size: 18px;
                font-size: clamp(20px, calc((26 / 1440) * 100vw), 26px);
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .student-header {
        border-radius: 0;
        position: relative;
        padding: 50px 30px;
        background-color: c.$primary;
        // border-radius: 20px;
        margin: 0;
        height: 210px;
        .student-filter {
            position: relative;
            z-index: 2;
        }

        &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-image: url(../../../assets/images/decoration-clock.svg);
            background-repeat: no-repeat;
            background-position: center right;
            z-index: 1;
            mix-blend-mode: soft-light;
            opacity: 0.25;
        }

        .student-header_container {
            // padding-top: calc(23 / 1440) * 100vh;
            // padding-bottom: (calc(23 / 1440) * 100vh);
            // padding-left: (calc(30 / 1440) * 100vh);
            // padding-right: (calc(30 / 1440) * 100vh);
            padding: 0;
            background-color: transparent;
            border-radius: 0;
            position: inherit;
        }

        .student-header_icon {
            display: none;
        }

        .student-header_controlPanel {
            display: flex;
        }
        .student-header-wrapper {
            width: 100%;
            svg {
                display: none;
            }

            p {
                display: none;
            }
            // display: none;
        }
    }
}
.detail-student-header {
    position: relative;
    padding: 30px;
    background-color: #0077ff;
    border-radius: 20px;

    .detail-student-header-wrapper {
        display: flex;
        align-items: center;

        & > img {
            border-radius: 10px;
        }

        ul {
            width: 100%;
            list-style: none;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            margin: 0;
            padding: 0;

            li {
                color: #fff;

                p {
                    margin-bottom: 5px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    span {
                        margin-bottom: 5px;
                        font-size: 16px;
                        line-height: 25px;
                    }

                    .detail-student-icon {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 30px;
                        height: 30px;
                        background-color: #fff;
                        border-radius: 50%;
                        color: #0077ff;
                    }

                    strong {
                        font-size: 20px;
                        line-height: 25px;
                    }
                }
            }
        }
    }
}

.student-header-teacher-menu {
    background-color: #fff;
    color: #0077ff;
    font-size: 18px;
    line-height: 25px;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0px 5px 10px 10px rgba(0, 0, 0, 0.1);

    strong {
        display: block;
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #ececec;

        &:last-child {
            border-bottom: none;
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }
}
