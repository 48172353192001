.view-image-label.view-result {
    .resultanswer-wrapper {
        padding: 0;
        min-width: unset;

        .resultanswer-content {
            .answer-content {
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }
        }
    }

    .view-image-label-content-wrapper {
        .resultanswer-wrapper {
            width: 100%;
            height: 100%;
            border-radius: 10px;

            .resultanswer-content {
                height: 100%;
                padding-left: 8px;

                .answer-letter {
                    min-width: 24px;
                    width: 24px;
                    height: 24px;
                    line-height: 24px;
                    font-size: 14px;
                }
            }
        }
    }

    // Label answer - Text:
    .view-image-label.text {
        .resultanswer-wrapper {
            .image-label-content {
                padding: 0;
            }
        }
    }

    // // Label answer - Dropdown:
    // .view-image-label.dropdown {
    // }

    // // Label answer - Drag & drop:
    // .view-image-label.drag-drop {
    // }
}
