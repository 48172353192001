.modal-detail-student {
    .info-confirmation-wrapper {
        max-height: 210px;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .info-confirmation-box {
        margin-right: 8px;
        padding: 8px;
        border-radius: 10px;

        & > div:nth-child(2n + 1) {
            font-weight: 700;
            padding-right: 8px;
        }

        .teacher-list {
            & > span > span {
                &:hover {
                    text-decoration: underline;
                }
            }
        }

        &:hover {
            background-color: #eee;
        }
    }
}
