.app-editable-math-field {
    position: relative;
    width: 100%;

    .emf-field {
        min-height: 45px;
    }

    .emf-field.mq-editable-field {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #d9d9d9 !important;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 4px 16px;
        height: auto;

        .mq-root-block {
            position: relative;
            display: block;
            background: none;
            padding: 0;
            white-space: pre-wrap;
            overflow-wrap: break-word;
            z-index: 2;
        }

        &.mq-focused {
            box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2) !important;
        }
    }

    .emf-placeholder {
        position: absolute;
        top: 50%;
        left: 16px;
        transform: translate(0, -50%);
        font-style: italic;
        color: rgba(0, 0, 0, 0.5);
        z-index: 1;
    }

    // Element's states (Pseudo-classes, extra classes):
    &.disabled {
        .emf-field.mq-editable-field {
            background-color: rgb(208 208 208);
            pointer-events: none;
            cursor: not-allowed;
        }
    }
}

// RESPONSIVE DESIGN:
.has-rd {
    @media screen and (max-width: 1399.98px) {
        &.app-editable-math-field {
            .emf-field {
                min-height: 36px;
            }
        }
    }
}
