.question-detail.number-line {
    .question-formitems.set-input-numbers {
        column-gap: 70px;
    }
}

// RESPONSIVE:
@media screen and (max-width: 767.98px) {
    .question-detail.number-line {
        .question-formitems.set-input-numbers {
            .ant-form-item .ant-form-item-label > label {
                min-width: 150px;
            }
        }
    }
}
