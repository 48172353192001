.pdf-worksheet {
    &-content.ant-layout {
        display: flex;
        // grid-template-columns: auto auto auto;
        flex-direction: row;
        width: 100%;
        overflow: hidden;
        padding: 10px;
        background-color: #f3f3f4;
    }

    &-preview {
        // max-height: calc(100vh - 200px);
        // overflow: auto;
        flex: 1;
    }

    &-questionlist {
        .question-list-acttion {
            display: flex;
            justify-content: space-between;
            box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
            background-color: #fff;
            padding: 4px;
            border-radius: 3px;
            margin-bottom: 15px;
        }
    }
    &-thumbnails {
        max-height: calc(100vh - 200px);
    }
}
