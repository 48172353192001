.exam-select {
    height: calc(100vh - 200px);
    display: flex;
    align-items: center;
    justify-content: center;
    &-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 30px;
    }
}
