.subsc-btn {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 8px;
    padding: 4px 10px 4px 4px !important;
    background: linear-gradient(90deg, #e7c178 37%, #f7dfaf 85%);
    border-radius: 30px;

    .subsc-btn-icon {
        width: 35px;
        height: 35px;
        min-width: 35px;
        background-color: #ffffff;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .subsc-btn-name {
        color: #864300;
        text-transform: uppercase;
        text-wrap: nowrap;
    }

    .subsc-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        & > svg {
            fill: #864300;
        }
    }

    &.collapsed {
        &.nav-menu-item {
            padding: 4px 4px !important;
        }
        .subsc-section {
            display: none;
        }
    }

    &:hover {
        background: linear-gradient(90deg, #e7c178 75%, #f7dfaf 100%);
    }

    &:active {
        background: linear-gradient(90deg, #e7c178 0%, #f7dfaf 100%);
    }
}
