.testLayout_wrapper.pdf {
}

.testLayout_content {
    flex: 1;
    max-height: calc(100vh - 130px);
    overflow: auto;
    min-width: 50vw;
}
.pdf-test-sidebar {
    max-height: calc(100vh - 130px);
    overflow: auto;
}
