.container {
    box-shadow: rgba(0, 0, 0, 0.03) 0px 0px 5px 5px;
    transition: all 0.3s ease 0s;
    border-radius: 16px;
    background-color: #fff;
    min-height: 180px;
    // * {
    // font-family: cursive, "mulish", sans-serif !important;
    // }
}

.container:hover {
    transform: scale(1.03);
}

.wrapper {
    padding: 8px 20px 16px;
    display: flex;
    align-items: center;
    height: 100%;
    position: relative;
    p {
        margin-bottom: 0;
    }
}

.avatar {
    width: 80px;
    min-width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    padding: 10px;
    margin-right: 16px;
}
.avatar img {
    max-width: 100%;
}

.content {
    padding: 10px;
    color: #6b7280;
    > p {
        margin-bottom: 0;
        font-size: 26px;
        font-weight: 700;
    }
}

.number {
    color: #6b7280;
}

.text {
    p {
        font-size: 18px;
        font-weight: 500;
    }
}

.actions {
    position: absolute;
    bottom: 8px;
    right: 20px;
    button {
        border: none;
        background-color: transparent;
        cursor: pointer;
        &:hover {
            font-weight: 600;
        }
    }
}
