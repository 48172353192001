.hotpot-editor-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .zwibbler-canvas-holder {
        border: none;
    }
}

.hotspot-resize-toolbars {
    display: inline-flex;
    justify-content: space-between;
    margin-bottom: 3px;
    .hotspot-resize-botton button {
        max-width: 150px;
        min-width: 100px;
        font-size: 14px;
        cursor: pointer;

        span {
            margin-right: 5px;
        }
    }

    .hotspot-resize-configs {
        .ant-input-group-wrapper {
            max-width: 120px;
        }
    }

    .config-imgsize {
        display: flex;
    }
}

.image-resize-wrapper {
    position: relative;
}
