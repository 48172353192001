@use 'src/assets/styles/utilities/colors' as colors;

.pdf-question-list {
    padding-left: 10px;
    padding-right: 10px;
    background-color: #f0f6fb;
    max-height: calc(100vh - 130px);
    overflow: auto;

    &_top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px;
        background-color: #f0f6fb;
        box-shadow: 1px 2px 3px 0px #afa3a3;
        border-radius: 5px;
        margin-bottom: 10px;
        position: absolute;
        bottom: 10px;
        width: 370px;
    }
    .btn-add-question {
        height: 45px;
        border-radius: 8px;
        display: flex;
        align-items: center;

        box-shadow: 0px -3px 0px colors.$primary_darker inset;

        cursor: pointer;

        &:hover {
            filter: opacity(0.8);
            transform: scale(0.97);
        }

        svg {
            font-size: 26px;
        }
    }
}
