@use 'src/assets/styles/utilities/colors' as colors;

.question-bank {
    padding: 20px 30px 30px 30px;

    .page-header-wrapper {
        margin-bottom: 30px;
    }

    // Animations for row table:
    .ant-table-row {
        // transition: background-color 0.3s ease-in-out;
        transition: all 2s ease-in-out;
    }

    .ant-table-row.row-clone-animate {
        background-color: rgba(212, 212, 243, 0.933) !important;
        animation: row-animate 0.8s;
    }

    .ant-table-row.row-add-animate {
        background-color: rgba(212, 212, 243, 0.933) !important;
        animation: row-animate 0.8s;
    }

    .ant-table-row.row-update-animate {
        background-color: rgba(157, 157, 232, 0.933) !important;

        animation: 2s linear row-update-animate;
        transition: unset;
    }

    @keyframes row-update-animate {
        0% {
            opacity: 0.4;
        }

        100% {
            opacity: 1;
        }
    }

    @keyframes row-animate {
        from {
            transform: translateX(200px);
            opacity: 0;
            height: 0;
        }

        to {
            transform: translateX(0);
            opacity: 1;
            height: auto;
        }
    }

    fillbox {
        visibility: hidden;
    }

    // Elements:
    .table {
        margin-top: 30px;

        .table-content-format * {
            font-size: 14px !important;
            line-height: 15px !important;
        }

        .ant-table-tbody {
            .tags-wrapper {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 4px;
                // Layout:
                margin: -16px;
                padding: 16px;
                max-height: 200px;
                overflow-y: auto;
                overflow-x: hidden;
            }

            .ant-tag.app-tag-label:hover {
                white-space: nowrap;
            }

            .ant-table-row {
                cursor: pointer;

                &:hover {
                    background-color: #fafafa;
                }
            }

            .ant-table-cell {
                text-align: center;

                pre {
                    white-space: pre-wrap;
                }

                &:nth-child(2),
                &:nth-child(4) {
                    text-align: left;
                }

                .question-bank-action {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;

                    > span {
                        display: block;
                    }

                    svg {
                        font-size: 25px;
                        color: colors.$primary;
                    }

                    .question-bank-delete {
                        span svg {
                            color: colors.$danger;
                        }
                    }
                }
            }
        }
    }

    .question-bank-table {
        fillbox {
            display: none;
        }

        .ant-table table {
            width: 100% !important;
        }

        .ant-table .ant-table-expanded-row-fixed {
            width: auto !important;
        }

        .ant-table table colgroup col {
            &:nth-child(1) {
                width: 200px !important;
                min-width: 150px;
            }

            &:nth-child(2) {
                width: 200px !important;
                min-width: 150px;
            }

            &:nth-child(3) {
                width: 200px !important;
                min-width: 150px;
            }

            &:nth-child(4) {
                width: auto !important;
                min-width: 350px;
            }

            &:nth-child(5) {
                width: 150px !important;
                min-width: 100px;
            }

            &:nth-child(6) {
                width: 150px !important;
                min-width: 100px;
            }

            &:nth-child(7) {
                width: 150px !important;
                min-width: 100px;
            }

            &:nth-child(8) {
                width: 200px !important;
                min-width: 150px;
            }

            &:nth-child(9) {
                width: auto !important;
                min-width: 100px;
            }

            &:nth-child(10) {
                width: auto !important;
                min-width: 100px;
            }
        }
    }

    .list-actions {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }

    .list-btn {
        &.btn.btn-icon-only .btn__content {
            background-color: transparent;
            transition: all 0.5s ease;
        }

        .btn__icon-wrapper svg {
            fill: colors.$primary;
            height: 22px;
            max-width: 22px;
            transition: all 0.5s ease;
        }

        &:hover {
            &.btn.btn-icon-only .btn__content {
                background-color: colors.$primary;
            }

            .btn__icon-wrapper svg {
                fill: #fff;
            }
        }

        &.btn-danger {
            .btn__icon-wrapper svg {
                fill: colors.$danger;
            }

            &:hover {
                &.btn.btn-icon-only .btn__content {
                    background-color: colors.$danger;
                }

                .btn__icon-wrapper svg {
                    fill: #fff;
                }
            }
        }

        &:disabled,
        &.disabled {
            .btn__icon-wrapper svg {
                fill: #cdcfd0;
            }
        }
    }
}

.option-modal {
    .ant-modal-content .ant-modal-body {
        .modal-icon {
            color: #0077ff;
            padding: 20px;
            font-size: 50px;
            line-height: 50px;
            border-color: #0077ff;
        }
    }
}

// RESPONSIVE:
@media screen and (max-width: 1399.98px) {
    .question-bank {
        .table {
            .ant-table-tbody {
                .ant-tag.app-tag-label {
                    max-width: 100px;
                }
            }
        }

        .question-bank-table {
            .ant-table table colgroup col {
                &:nth-child(1) {
                    min-width: 100px;
                }

                &:nth-child(2) {
                    min-width: 100px;
                }

                &:nth-child(3) {
                    min-width: 100px;
                }

                &:nth-child(4) {
                    min-width: 200px;
                }

                &:nth-child(5) {
                    min-width: 100px;
                }

                &:nth-child(6) {
                    min-width: 100px;
                }

                &:nth-child(7) {
                    min-width: 100px;
                }

                &:nth-child(8) {
                    min-width: 100px;
                }

                &:nth-child(9) {
                    width: auto !important;
                    min-width: 100px;
                }

                &:nth-child(10) {
                    width: auto !important;
                    min-width: 100px;
                }
            }
        }
    }
}

@media screen and (max-width: 1199.98px) {
    .question-bank {
        .table {
            .ant-table-tbody {
                .ant-tag.app-tag-label {
                    font-size: 12px;
                    line-height: 18px;
                }

                .tags-wrapper {
                    gap: 2px;
                    margin: -8px;
                }
            }
        }
    }
}
