/*
 * Global class names:
 * .view-answer-list
 */

.q-fill-blanks {
    .q-content-section {
        &:not(:last-child) {
            margin-bottom: 30px;
        }
    }

    .ant-select,
    .ant-select.ant-select-in-form-item {
        width: auto;
    }
}

.view-answer-list {
    text-align: left;
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    padding: 12px 30px;
    background: #f0f7ff;
    border-radius: 10px;
    column-gap: 40px;
    row-gap: 10px;
    overflow-x: auto;
    overflow-y: auto;

    > span:not(.mq-math-mode) {
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
    }
}
