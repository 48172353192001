.view-image-labels-container {
    $boxShadow1: 0px 0px 5px 2px rgb(0, 0, 0, 0.1), 0px 0px 0px 4px rgb(255, 255, 255, 0.5),
        0px 8px 16px 0px rgb(0, 0, 0, 0.5);
    $boxShadow2: 0px 0px 5px 2px rgb(0, 0, 0, 0.1), 0px 0px 0px 4px rgb(255, 255, 255, 0.8),
        0px 8px 16px 0px rgb(0, 0, 0, 0.5);
    $boxShadow3: 0px 0px 5px 2px rgb(0, 0, 0, 0.1), 0px 0px 0px 4px rgb(255, 255, 255, 0.8),
        0px 8px 16px 0px rgb(0, 0, 0, 1);

    .view-image-label {
        position: absolute;
        width: 130px;
        height: 36px;
        border-radius: 10px;
        box-shadow: $boxShadow1;
        transition: box-shadow 0.2s ease;

        &:hover {
            box-shadow: $boxShadow2;

            .view-image-label-content-wrapper {
                .image-label-pin {
                    box-shadow: $boxShadow2;
                }
            }
        }

        &.is-focused {
            z-index: 1;
        }

        &.under-dragelem {
            box-shadow: 0 0 0 5px rgb(255, 255, 255, 0.8), 0 0 0 10px rgb(255, 255, 255, 0.5);
        }
    }

    .view-image-label-content-wrapper {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;

        .image-label-pin {
            position: absolute;
            top: -5px;
            left: -5px;
            width: 10px;
            height: 10px;
            background-color: #fff;
            border: 1px solid #d9d9d9;
            border-radius: 100%;
            box-shadow: $boxShadow1;
            transition: box-shadow 0.2s ease;
            // z-index: 10;
        }

        .image-label-content {
            width: 100%;
            height: 100%;
            line-height: 32px;
        }
    }

    // Label answer - Text:
    .view-image-label.text {
        background-color: #fff;
        border: 2px solid #fff;

        &.has-val {
            background-color: #fff;
        }

        .image-label-content {
            padding: 0 8px;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }

        .image-label-input {
            line-height: 32px;
            padding: 0 8px;
            font-size: 14px;
            border: none;
            outline: none;
            box-shadow: none;
            background: none;

            &:hover {
                border: none;
            }
        }
    }

    // Label answer - Dropdown:
    .view-image-label.dropdown {
        background-color: rgba(255, 255, 255, 0.5);

        .image-label-content {
            display: block;
        }

        .ant-select.app-select.image-label-selector {
            width: 100%;

            .ant-select-selector {
                padding: 0 25px 0 8px;
                min-height: 36px;

                .ant-select-selection-search {
                    left: 8px;
                }

                .ant-select-selection-placeholder {
                    line-height: 34px;
                }

                .ant-select-selection-item {
                    line-height: 34px;
                }
            }
        }
    }

    // Label answer - Drag & drop:
    .view-image-label.drag-drop {
        background-color: rgba(255, 255, 255, 0.5);
        border: 2px dashed #fff;

        &.has-val {
            background-color: #fff;
        }

        .image-label-content {
            display: block;
        }

        .view-lblanswer-drg.imglbl-drg-wrapper {
            .lblanswer-item {
                line-height: 32px;
                padding: 0 8px;
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }
        }
    }
}
