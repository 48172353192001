.general_layout_wrapper {
    .general_layout {
        margin-left: 85px !important;
        height: auto;
        min-height: 100vh;

        .ant-layout.ant-layout-content {
            position: relative;
        }
    }

    .layout-msg-wrapper {
        & > div {
            margin: 20px 30px 0px 30px;
        }
    }

    .toggle-btn-for-navsidebar {
        position: fixed;
        top: 50%;
        left: 0px;
        transform: translate(0, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
        width: 25px;
        height: 50px;
        border-radius: 0 10px 10px 0;
        z-index: 1000;
        cursor: pointer;
        transition: left 0.2s ease;

        &:hover {
            box-shadow: 1px 0px 2px 0px rgba(0, 0, 0, 0.2);
        }

        &.active {
            left: 280px;
            box-shadow: none;
        }

        .anticon svg {
            height: 24px;
            width: auto;
        }
    }
}

@media screen and (max-width: 767.98px) {
    .general_layout_wrapper {
        overflow-x: hidden;

        .general_layout {
            .ant-layout.ant-layout-content {
                height: 100%;
            }
        }

        .sidebar_scroll {
            transform: translateX(0%);
        }

        .general_layout {
            // transform: translateX(265px);
            margin-left: 265px;
        }

        .general_layout {
            margin-left: 0px !important;
        }

        &.general_layout_wrapper-collapsed {
            .sidebar_scroll {
                transform: translateX(-100%);
            }
        }
    }
}
