.modal-addusertoclass {
    &.ant-modal {
        .form-actions {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 16px;
        }

        .student-row {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            gap: 30px;
            width: 100%;
            background-color: #f8f8f8;
            padding: 8px 16px;
            border-radius: 10px;

            &:not(:last-child) {
                margin-bottom: 16px;
            }

            .ant-row {
                width: 100%;
            }

            .num {
                height: 45px;
                line-height: 45px;
            }

            .acts {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 8px;
                height: 45px;
            }
        }

        .ant-form-item {
            margin-bottom: 0;
        }
    }
}

@media screen and (max-width: 1399.98px) {
    .modal-addusertoclass {
        &.ant-modal {
            .student-row {
                .num {
                    height: 36px;
                    line-height: 36px;
                }

                .acts {
                    height: 36px;
                }
            }
        }
    }
}
