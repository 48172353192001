@use 'src/assets/styles/utilities/variables' as vars;
@use 'src/assets/styles/utilities/colors' as colors;

.detail-class {
    padding: 20px 30px;

    .detail-class-top {
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 30px;
        border-bottom: 1px solid #d9d9d9;
        row-gap: 8px;
        column-gap: 8px;

        .detail-class-title {
            font-size: 26px;
            line-height: 35px;
            font-weight: 700;
            margin-bottom: 0;
        }

        .class-actions-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;

            .class-actions {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 8px;
            }
        }
    }

    .detail-class-management {
        margin-top: 30px;

        .ant-tabs {
            .ant-tabs-nav-list {
                border: 1px solid colors.$second;
                padding: 5px;
                border-radius: 10px;

                .ant-tabs-tab {
                    width: 300px;
                    display: flex;
                    justify-content: center;
                    background-color: transparent;
                    border: none;
                    border-radius: 10px;

                    & > div > span {
                        font-size: 20px;
                        line-height: 35px;
                        display: block;
                    }

                    &.ant-tabs-tab-active {
                        background-color: colors.$second;

                        span {
                            color: #fff;
                            font-weight: 700;
                        }
                    }

                    &:hover {
                        color: colors.$second;
                    }
                }
            }

            .ant-table {
                .ant-table-thead {
                    .owner-ship-wrapper {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        span {
                            margin-right: 5px;
                        }
                    }
                }

                .ant-table-tbody {
                    .ant-table-cell {
                        text-align: center;

                        &:first-child {
                            span {
                                position: relative;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                .icon {
                                    position: absolute;
                                    top: 50%;
                                    left: -5px;
                                    transform: translateY(-50%);
                                    cursor: pointer;

                                    &:hover {
                                        opacity: 0.6;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .student-list-table {
        .ant-table table {
            width: 100% !important;
        }

        .ant-table .ant-table-expanded-row-fixed {
            width: auto !important;
        }

        .ant-table table colgroup col {
            &:nth-child(1) {
                width: auto !important;
                min-width: 150px;
            }

            &:nth-child(2) {
                width: auto !important;
                min-width: 100px;
            }

            &:nth-child(3) {
                width: auto !important;
                min-width: 180px;
            }

            &:nth-child(4) {
                width: auto !important;
                min-width: 50px;
            }

            &:nth-child(5) {
                width: auto !important;
                min-width: 100px;
            }
        }
    }

    .exam-list-table {
        .ant-table table {
            width: 100% !important;
        }

        .ant-table .ant-table-expanded-row-fixed {
            width: auto !important;
        }

        &.competition-table .ant-table table colgroup col {
            &:nth-child(1) {
                width: auto !important;
                min-width: unset;
            }
        }
        .ant-table table colgroup col {
            &:nth-child(1) {
                width: auto !important;
                min-width: 170px;
            }

            &:nth-child(2) {
                width: auto !important;
                min-width: 170px;
            }

            &:nth-child(3) {
                width: auto !important;
                min-width: 170px;
            }

            &:nth-child(4) {
                width: auto !important;
                min-width: 50px;
            }

            &:nth-child(5) {
                width: auto !important;
                min-width: 50px;
            }

            &:nth-child(6) {
                width: auto !important;
                min-width: 50px;
            }

            &:nth-child(7) {
                width: auto !important;
                min-width: 100px;
            }

            &:nth-child(8) {
                width: auto !important;
                min-width: 100px;
            }
        }
    }

    .class-delbtn {
        &.btn.btn-icon-only .btn__content {
            background-color: transparent;
            transition: all 0.5s ease;
        }

        .btn__icon-wrapper svg {
            fill: colors.$danger;
            height: 22px;
            max-width: 22px;
            transition: all 0.5s ease;
        }

        &:hover {
            &.btn.btn-icon-only .btn__content {
                background-color: colors.$danger;
            }

            .btn__icon-wrapper svg {
                fill: #fff;
            }
        }
    }

    .class-detail-action-icon {
        font-size: 22px;
        color: #0077ff;
        fill: #0077ff;

        &.delbtn {
            svg {
                color: colors.$danger;
            }
        }

        &.delbtn.disabled {
            svg {
                color: #cdcfd0;
            }
        }
    }

    .help {
        .act-button.question {
            display: block;
            height: 38px;
            width: 38px;
            width: clamp(30px, calc(45 / 1440 * 100vw), 45px);
            height: clamp(30px, calc(45 / 1440 * 100vw), 45px);
            border-radius: 50%;
            border: 1px solid #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #0077ff;
            cursor: pointer;
            svg {
                fill: #fff;
                font-size: clamp(16px, calc(20 / 1440 * 100vw), 20px);
            }
            &:hover {
                opacity: 0.6;
            }
            &.outline {
                border: 2px solid #445cfe;
                background-color: transparent;
                svg {
                    fill: #0077ff;
                }
                &:hover {
                    opacity: 1;
                    border: none;
                    background-color: #0077ff;
                    svg {
                        fill: #fff;
                    }
                }
            }
        }
    }
    .class-btn {
        padding: clamp(2px, calc(8 / vars.$w * 100vw), 8px) clamp(4px, calc(20 / vars.$w * 100vw), 20px);
        font-size: clamp(12px, calc(16 / vars.$w * 100vw), 18px);
    }
}

.class-detail-modal.create-exam {
    .ant-modal-content {
        height: 100%;
        background: none;

        .ant-modal-close {
            background: none;
            filter: drop-shadow(0px 4px 2px rgba(0, 0, 0, 0.25));
            width: 35px;
            height: 35px;
            line-height: 35px;
            position: absolute;
            top: -8px;
            right: -8px;
            border-radius: 5px;
            overflow: hidden;

            .ant-modal-close-x {
                background-color: #fff;
                position: relative;
                width: 100%;
                height: 100%;
                line-height: inherit;
                display: flex;
                justify-content: center;
                align-items: center;
                fill: #000000;
                border-radius: 5px;
            }

            &:hover {
                .ant-modal-close-x {
                    background-color: #f8f8f8;
                }
            }

            &:active {
                .ant-modal-close-x {
                    transform: scale(0.8);
                }
            }
        }

        .ant-modal-body {
            height: 100%;
            background-color: #fff;
            padding: 0;
            border-radius: 10px;
            overflow: hidden;

            & > div:first-child {
                height: 100%;
                padding: 35px 30px 35px 30px;
                overflow-y: auto;
            }
        }
    }
}

.class-detail-modal.detail-learning-progress,
.class-detail-modal.detail-marking {
    .ant-modal-content {
        height: 100%;

        .ant-modal-body {
            height: calc(100% - 68px); // Exclude header's height!
            overflow-y: auto;
        }
    }
}

.table-column-filter-menu {
    padding: 0;
    margin: 0;
    background-color: #fff;
    border: 1px solid #0077ff;
    list-style: none;
    border-radius: 4px;
    overflow: hidden;

    li {
        font-size: 18px;
        line-height: 35px;
        padding: 3px 10px;
        cursor: pointer;

        &:hover {
            background-color: #f0f7ff;
        }

        &.active {
            background-color: #f0f7ff;
        }
    }
}

.ownership-filter {
    padding: 0;
    margin: 0;
    background-color: #fff;
    border: 1px solid #0077ff;
    list-style: none;
    border-radius: 4px;
    overflow: hidden;

    li {
        font-size: 18px;
        line-height: 35px;
        padding: 3px 10px;
        cursor: pointer;

        &:hover {
            background-color: #f0f7ff;
        }

        &.active {
            background-color: #f0f7ff;
        }
    }
}

// RESPONSIVE:
@media screen and (max-width: 1439.98px) {
    .detail-class {
        .class-btn {
            min-width: auto;
            white-space: nowrap;
        }
        .detail-class-top {
            .detail-class-title {
                font-size: max(18px, calc(24 / vars.$w * 100vw));
            }

            // & > div:last-child {
            //     .class-btn {
            //         padding: clamp(2px, calc(8 / vars.$h * 100vw), 8px) clamp(4px, calc(20 / vars.$w * 100vw), 20px);
            //         font-size: max(12px, calc(16 / vars.$w * 100vw));
            //     }
            // }
        }

        .detail-class-management {
            .ant-tabs {
                .ant-tabs-nav-list {
                    // padding: 5px;

                    .ant-tabs-tab {
                        width: max(200px, calc(300 / vars.$w * 100vw));
                        padding: max(2px, calc(8 / vars.$h * 100vh)) max(4px, calc(16 / vars.$w * 100vw));

                        & > div > span {
                            font-size: max(14px, calc(20 / vars.$w * 100vw));
                            line-height: max(24px, calc(35 / vars.$w * 100vw));
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 991.98px) {
    .detail-class {
        .detail-class-top {
            .class-actions-wrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 8px;

                .class-actions {
                    display: flex;
                    justify-content: center;
                    align-items: flex-end;
                    gap: 8px;

                    &.for-class {
                        flex-direction: column;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767.98px) {
    .detail-class {
        .detail-class-title {
            font-size: 22px;
        }
        .detail-class-top {
            flex-direction: column;
            padding-bottom: 5px;
        }
    }
}
