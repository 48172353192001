.payment-order-panel {
    background-color: #fff;
    padding: 0 10px;
    border-radius: 10px;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);

    .current-price,
    .usual-price {
        & > span:first-child {
            margin-right: 2px;
        }
    }

    .item-right {
        min-width: 90px;
        text-align: right;
    }

    .usual-price {
        position: relative;

        &::after {
            position: absolute;
            content: "";
            top: 50%;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: #000;
        }
    }

    .order-header {
        padding: 16px 0 8px 0;
        border-bottom: 1px solid #dedfe0;

        &::after {
            clear: both;
            content: "";
            display: block;
            visibility: hidden;
            font-size: 0px;
            height: 0px;
        }

        .order-title {
            font-size: 14px;
            font-weight: 700;
            text-transform: uppercase;
        }

        .order-actions {
            float: right;

            .btn {
                font-size: 16px;
                color: #445cfe;

                svg {
                    fill: #445cfe;
                }

                &.disabled,
                &:disabled {
                    color: #cdcfd0;

                    svg {
                        fill: #cdcfd0;
                    }
                }
            }
        }
    }

    .order-body {
        padding: 8px 0;

        .order-item {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            .order-item-price {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-end;
                margin-left: 8px;
            }
        }
    }

    .order-footer {
        padding: 8px 0 16px 0;
        border-top: 1px solid #dedfe0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .order-amount-title {
            font-size: 16px;
            font-weight: 700;
        }

        .order-amount-price {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            margin-left: 8px;
        }

        .order-amount-value {
            color: #f00;
            font-weight: 700;

            & > span:nth-child(1) {
                font-size: 20px;
                margin-right: 2px;
            }

            & > span:nth-child(2) {
                font-size: 16px;
            }
        }
    }

    .order-msg {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        color: #aaa;
    }
}
