@use 'src/assets/styles/utilities/colors' as colors;

.class {
    padding: 20px 30px 30px 30px;

    .page-header-wrapper {
        margin-bottom: 30px;
    }

    .filter-toolbar-wrapper {
        margin-bottom: 30px;

        .filter-toolbar-actions {
            display: none;
        }
    }

    .class-filter {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 24px;
        margin-bottom: 30px;

        .branch-select {
            min-width: 220px;
        }

        .class-filter-title {
            &:first-child {
                font-weight: bold;
                margin-right: 15px;
            }
        }

        .class-filter-select {
            .app-select {
                min-width: 200px;

                .ant-select-selector {
                    background-color: transparent;
                    border-color: colors.$primary;
                    color: colors.$primary;
                }
            }
        }
    }

    .class-list {
        display: block;
    }
}

@media screen and (max-width: 575.98px) {
    .class {
        .class-filter {
            align-items: stretch;
            flex-direction: column;
            gap: 10px;

            > div {
                display: flex;
                flex-direction: column;
            }

            &-title {
                font-size: 14px;
            }
        }
    }
}
