.StudentFeatureDropDown_wrapper {
    box-shadow: 1px 2px 5px #000;
    z-index: 20;
    background: #fff;
    border-radius: 4px;
    .StudentFeatureDropDown_dropDownList {
        .StudentFeatureDropDown_dropDownItem {
            padding: 10px 20px;
            font-size: 16px;
            font-weight: bold;
            cursor: pointer;
            display: flex;
            align-items: center;

            &:hover {
                background: rgb(243, 242, 242);
            }

            .StudentFeatureDropDown_icon {
                height: 18px;
                height: 18px;
                margin-right: 8px;
                filter: invert(88%) sepia(21%) saturate(935%) hue-rotate(123deg) brightness(85%) contrast(97%);
            }
        }
    }
}
