.file-manager-container,
.file-manager-container *,
.file-manager-container *:before,
.file-manager-container *:after {
    box-sizing: border-box;
}

.file-manager-container {
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);
    font-size: 13px;
    position: relative;
    border-radius: 3px;
    .file-manager-header {
        height: 32px;
        line-height: 32px;
        display: flex;
        justify-content: space-between;
        .file-manager-header-title {
            padding-left: 10px;
        }
        .file-manager-header-actions {
            display: flex;
            padding-right: 10px;
            padding-top: 10px;
            .fm-header-action-item {
                cursor: pointer;
                width: 12px;
                height: 12px;
                display: block;
                background-repeat: no-repeat;
                background-size: 100%;
            }
            .fm-header-action-close {
                background-image: url(./assets/close.svg);
            }
        }
    }
    .file-manager-toolbar {
        border-top: 1px solid #d9d9d9;
        padding-top: 8px;
        padding-right: 8px;
        padding-left: 8px;
        padding-bottom: 8px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .file-manager-toolbar-item {
            border: 0;
            cursor: pointer;
            border-radius: 3px;
            outline: none;
            background-color: #eee;
            display: flex;
            justify-content: center;
            align-items: center;
            column-gap: 8px;
            row-gap: 4px;
            padding: 4px 8px;
            margin-left: 3px;
            margin-right: 3px;
            transition: all 300ms;
            &:enabled:hover {
                // background-color: #d0d0d0;
                transform: translateY(-2px);
                box-shadow: 0 2px 5px 0px rgb(0 0 0 / 20%);
            }
            &:enabled:active {
                opacity: 0.7;
            }
            &:not(:enabled) {
                opacity: 0.2;
                cursor: default;
            }
            img {
                margin: auto;
            }
            .btn-icon-wrapper {
                display: flex;
                padding: 0;
                width: 24px;
                height: 24px;
                min-height: 24px;
                img,
                svg {
                    display: block;
                    width: 100%;
                    height: 100%;
                }
            }
            .btn-text {
                font-size: 14px;
                font-weight: 700;
            }
            &.item-lbl {
                width: auto;
                height: auto;
                padding: 0;
                & > label {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    column-gap: 8px;
                    row-gap: 8px;
                    flex-wrap: wrap;
                    cursor: pointer;
                    padding: 4px 8px;
                }
            }
        }
        .file-manager-toolbar-item.fm-go-back {
            .btn-icon-wrapper {
                width: 18px;
                height: 18px;
            }
        }
    }
    .file-manager-body {
        display: flex;
        height: calc(100% - 48px - 32px - 1px);
        border-top: 1px solid #d9d9d9;
    }
    .file-manager-modal {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #00000040;
        opacity: 0;
        z-index: -1;
        transition: opacity 300ms, z-index 0s 300ms;
        .file-manager-modal-fog {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
        .file-manager-modal-content {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            padding: 15px;
            background-color: #fff;
            box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
            border-radius: 3px;
            text-align: center;
        }
        &.file-manager-modal-active {
            opacity: 1;
            z-index: 100;
            transition: opacity 300ms, z-index 0s;
        }
        &.file-manager-modal-notification {
            top: 70px;
            right: 6px;
            transform: none;
            bottom: unset;
            left: unset;
            background-color: transparent;
            .file-manager-modal-content {
                position: initial;
                transform: unset;
            }
            .file-manager-modal-body {
                margin-bottom: 0;
            }
        }
        .file-manager-modal-message {
            margin-bottom: 20px;
        }
        .file-manager-modal-body {
            margin-bottom: 20px;
            input {
                outline: none;
                line-height: 32px;
                padding: 0 8px;
                border: 1px solid #d9d9d9;
                border-radius: 3px;
            }
        }
        .file-manager-modal-actions {
            button {
                margin: 0 5px;
                border: 0;
                padding: 5px 15px;
                border-radius: 3px;
                cursor: pointer;
                background-color: #e0e0e0;
                outline: none;
                transition: all 300ms;
                &:not(:enabled) {
                    opacity: 0.5;
                    cursor: default;
                }
                &:enabled:hover {
                    background-color: #d0d0d0;
                }
                &:active {
                    opacity: 0.7;
                }
            }
        }
    }
    .fm-input-center {
        text-align: center;
    }
}

.file-manager-container.disable-header {
    .file-manager-toolbar {
        border-top: none;
    }
    .file-manager-body {
        height: calc(100% - 48px);
    }
    .file-manager-toolbar {
        border-top: none;
    }
}

@media screen and (max-width: 767.98px) {
    .file-manager-container {
        .file-manager-toolbar {
            .file-manager-toolbar-item {
                .btn-text {
                    display: none;
                }
            }
        }
    }
}
