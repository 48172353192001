/*
 * Global class names:
 * _
 */

.q-highlight-image {
    .q-content-section {
        &:not(:last-child) {
            margin-bottom: 30px;
        }
    }
}

.canvas-wrapper-img {
    position: relative;
    // overflow: auto;
    margin: 0;
}
