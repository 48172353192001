.upload-file-wrapper {
    &.type-avatar {
        .avatar-wrapper {
            position: relative;
            width: 100px;
            height: 100px;
            min-width: 100px;
            background-color: #eee;
            border-radius: 50%;
            cursor: pointer;

            .avatar-btn {
                $svgWH: 43px;
                position: absolute;
                bottom: calc(0px - ($svgWH / 4));
                right: calc(0px - ($svgWH / 4));

                & > svg {
                    display: block;
                    width: $svgWH;
                    height: $svgWH;
                }
            }

            &:hover {
                box-shadow: 0px 2px 2px 0px #bbb;
            }

            &.loading {
                animation: skeletonLoadingChangeBgColor 0.5s ease infinite alternate;
            }
        }

        .ant-avatar {
            background-color: #eee;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #000;
            font-size: 2em;
        }
    }
}
