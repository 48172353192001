.forget-password {
    width: 100%;

    .sign-in-wrapper {
        width: 90%;
        text-align: center;
        margin: auto;
        margin-top: 5%;

        .forget-password-wrapper {
            width: 50%;
            margin: auto;
            text-align: left;

            @media screen and (max-width: 767px) {
                width: 100%;
            }

            .forget-password-img {
                text-align: center;
                margin-bottom: 20px;
            }

            .sign-in-register {
                text-align: center;

                & > a {
                    margin-left: 8px;
                }
            }

            .btn {
                width: 100%;
                padding: 8px 10px;
            }

            //email sent
            .redirect-btn {
                margin-top: 70px;
                margin-bottom: 20px;
            }
        }
        @media screen and (min-width: 1920px) {
            margin-top: 10%;
        }
        @media screen and (max-width: 320px) {
            .forget-password-img img {
                width: 220px;
            }
        }
    }
}

.reset-password {
    .sign-in-wrapper {
        animation: swipeFromLeft 0.3s ease-in-out;
    }
}

@keyframes swipeFromLeft {
    0% {
        opacity: 0;
        transform: translateX(-50%);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}
