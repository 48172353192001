@use 'src/assets/styles/utilities/colors' as c;

.my-storage-wrapper {
    padding: 20px 30px 30px 30px;

    .page-header-wrapper {
        margin-bottom: 30px;
    }

    .my-storage-panel {
        .space-usage-wrapper {
            height: 100%;
            min-height: 300px;
            // background-color: #fff;
            background: linear-gradient(180deg, #fff 0%, #fff 50%, transparent 100%);
            // box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
            border-radius: 10px;
            min-width: 210px;
            overflow: hidden;

            .space-usage-header {
                color: #fff;
                background-color: c.$primary;
                padding: 12.5px 16px;

                .header-title {
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 23px;
                    text-decoration: underline;
                    padding-left: 8px;
                    border-left: 4px solid #fff;
                }
            }

            .space-usage-chart-wrapper {
                width: 100%;
                min-height: calc(100% - 53px);

                .disk-usage-statistics {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

        .file-manager-wrapper {
            height: calc(100vh - 170px);
            min-height: 500px;
            background-color: #fff;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
            border-radius: 10px;

            .file-manager-container {
                height: 100%;
                background: none;
                box-shadow: none;
            }

            .file-manager-container .file-manager-modal {
                border-radius: 10px;
            }

            .file-manager-container.disable-header .file-manager-toolbar {
                background-color: c.$primary;
                border-radius: 10px 10px 0 0;
            }

            .fm-settings > .setting-btn:last-child {
                border-radius: 0 0 10px 0;
            }
        }
    }

    .ant-tabs {
        border-top: 1px solid #f0f0f0;
    }
}

@media screen and (max-width: 991.98px) {
    .my-storage-wrapper {
        .my-storage-panel {
            .space-usage-wrapper {
                background-color: #fff;
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
            }
        }
    }
}
