.editor-fm-container {
    .ant-modal.app-modal.type-basic {
        .ant-modal-body {
            padding: 0;

            .ant-spin-nested-loading,
            .ant-spin-container {
                height: 100%;
            }
        }
    }

    .ant-modal.app-modal {
        max-height: 550px;

        .ant-modal-content {
            min-width: 500px;
        }
    }
}

.editor-fm-audio-visible-type {
    .ant-modal-header {
        padding-right: 54px;
    }

    .ant-modal-body {
        .action-list {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 8px;
            flex-wrap: wrap;

            & > button {
                flex: 1;
                min-width: calc(50% - 8px);
                max-width: calc(50% - 8px);
                border: none;
                outline: none;
                padding: 8px;
                border-radius: 10px;
                cursor: pointer;

                &:hover {
                    outline: 1px solid #ddd;
                }
            }
        }
    }
}
