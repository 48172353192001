.percentColumnPlot_wrapper {
    padding: 10px;
    height: 100%;
    width: 100%;
    .percentColumnPlot_inner {
        position: relative;
        width: 100%;
        height: 100%;

        background: #fff;
        border-radius: 10px;

        .percentColumnPlot_label {
            position: absolute;
            left: -20px;
            top: 50%;
            transform: rotate(-90deg);
            color: #404045;
        }
    }
}
