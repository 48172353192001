.ge-item-menu {
    &.ant-menu-root.ant-menu-vertical {
        border-radius: 10px;
        box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
        overflow: hidden;
    }

    &.ant-menu-vertical > .ant-menu-item.ge-item-menuitem {
        height: 32px;
        line-height: 32px;
        color: #000;
        user-select: none;

        &:hover {
            color: #000;
            background-color: #f2f2f2;
        }
    }
}

.ge-item-menuitem-lbl {
    display: flex;
    justify-content: flex-start;
    column-gap: 8px;
}
