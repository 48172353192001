@use 'src/assets/styles/utilities/variables' as vars;

.organization-user-list {
    &.org-student-list {
        .organization-user-list__table {
            .class-column {
                max-width: 1000px;

                @media screen and (max-width: 2048px) {
                    max-width: 800px;
                }
                @media screen and (max-width: 1920px) {
                    max-width: 600px;
                }
                @media screen and (max-width: 1440px) {
                    max-width: 460px;
                }
                @media screen and (max-width: 992px) {
                    max-width: 260px;
                }
            }

            .class-cell {
                max-height: 100px;
                overflow-y: auto;
                display: flex;
                flex-wrap: wrap;
                // row-gap: 6px;
                column-gap: 10px;
            }

            .student-class-column {
                display: inline-block;
                margin-bottom: 4px;
                width: fit-content;

                border: solid 1px #00b3;
                line-height: 1.6;
                padding: 2px 4px;
                border-radius: 5px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .actions-button {
        display: flex;
        flex-wrap: wrap-reverse;
        align-items: center;
        justify-content: space-between;
        margin-top: 15px;
        gap: 10px;
    }

    .filter-toolbar-wrapper {
        column-gap: unset;
    }
}

// RESPONSIVE:
@media screen and (max-width: 991.98px) {
    .organization-user-list {
        &__table.student {
        }
    }
}

@media screen and (max-width: 1439px) {
    .organization-user-list {
        .btn {
            border-radius: clamp(8px, calc((10 / vars.$w) * 100vw), 10px);
            padding: clamp(5px, calc((7.5 / vars.$w) * 100vw), 7.5px) clamp(6px, calc((10 / vars.$w) * 100vw), 10px);
            .btn__icon-wrapper > img {
                width: clamp(12px, calc((18 / vars.$w) * 100vw), 18px);
                height: clamp(14px, calc((21 / vars.$w) * 100vw), 21px);
            }
            .btn__content div {
                font-size: clamp(14px, calc((18 / vars.$w) * 100vw), 18px);
            }
        }
    }
}
