.question-bank.competitions {
    .competitions-table {
        .ant-table table colgroup col {
            &:nth-child(1) {
                min-width: 200px;
            }
        }
        .ant-table-tbody .ant-table-cell:nth-child(2),
        .ant-table-tbody .ant-table-cell:nth-child(4) {
            text-align: center;
        }
    }
}
