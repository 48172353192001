.test_content {
    .TestHeader {
        margin: 30px;
    }

    .test_question {
        margin: 30px;
    }

    @media screen and (max-width: 500px) {
        .test_question {
            margin: 10px 10px;
        }
    }
}

.NumberQuestion_Responsive {
    // padding-top: 30px;
    box-shadow: 0 0px 20px #5f5f5f;
    display: none;
    background-color: #fff;
    position: relative;
    transition: max-height 0.3s ease-out;
    max-height: 300px;

    &--collapsed {
        max-height: 0px;
        // background-color: transparent;
        box-shadow: none;
    }

    .NumberQuestion_toggleBtn {
        position: absolute;
        // top: -100%;
        left: 50%;
        top: -20px;
        // padding: 8px;
        border-radius: 50%;
        background-color: #fff;
        transition: transform 0.3s ease-out;
        transform: translateX(-50%) rotate(90deg);
        box-shadow: 0 0 3px rgba(0, 0, 0, 1);
        height: 35px;
        width: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:hover {
            color: red;
        }

        &--collapsed {
            transform: translateX(-50%) rotate(-90deg);
        }
    }

    .Submit_Test {
        display: flex;
        justify-content: center;

        button {
            margin: 20px 8px;
            display: block;
        }
    }

    .test_timeoutTest {
        animation: fadeIn 0.5s ease-in;
        padding: 10px 15px;
        color: red;
        text-align: center;
    }
}

@media screen and (max-width: 1024px) {
    .test_content {
        margin-bottom: 250px;
        .TestHeader {
            // margin: 120px 12px 12px 12px;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            z-index: 99;
            margin: 0;
            border-radius: 0;

            .title_wrapper {
                width: 60%;
            }
        }

        .test_question {
            // margin: 12px;
            margin-top: 120px;
            // margin: 120px 12px 12px 12px;
        }
    }

    .NumberQuestion_Responsive {
        display: block;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 2;
        .NumberQuestion {
            .NumberQuestion_list {
                margin-top: 20px;
                &.NumberQuestion_list--slider {
                    padding: 0 300px;
                }
            }
        }
    }
}

@media screen and (max-width: 860px) {
    .NumberQuestion_Responsive {
        .NumberQuestion {
            .NumberQuestion_list {
                &.NumberQuestion_list--slider {
                    padding: 0 200px;
                }
            }
        }
    }
}

@media screen and (max-width: 690px) {
    .NumberQuestion_Responsive {
        .NumberQuestion {
            .NumberQuestion_list {
                &.NumberQuestion_list--slider {
                    padding: 0 100px;
                }
            }
        }
    }
}

@media screen and (max-width: 450px) {
    .NumberQuestion_Responsive {
        .NumberQuestion {
            .NumberQuestion_list {
                &.NumberQuestion_list--slider {
                    padding: 0 20px;
                }
            }
        }
    }
}

@media screen and (max-width: 400px) {
    .NumberQuestion_Responsive {
        .NumberQuestion {
            .NumberQuestion_list {
                &.NumberQuestion_list--slider {
                    padding: 0 40px;
                }
            }
        }
    }
}
