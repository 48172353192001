.container {
    padding: 20px;
}

.wrapper {
}

.listCard {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
}

.imageWrapper {
    position: relative;
}

.checkbox {
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 10;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    padding: 4px;
    border-radius: 50%;
}

.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}
