.test_sidebar {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;

    .SiderHeader {
        padding: 40px 35px;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .SiderHeader_controlPanel {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .SiderHeader_filter {
            display: none;
        }

        .SiderHeader_Account {
            display: flex;
            align-items: center;
            justify-content: space-around;
            background-color: #f0f7ff;
            border-radius: 10px;
            cursor: pointer;
            user-select: none;

            &:hover {
                box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
            }

            .SiderHeader_Account_avatar {
                border-radius: 9.6px;
                width: 40px;
                height: 40px;
                object-fit: cover;
            }

            .SiderHeader_Account_info {
                width: 60px;
                .SiderHeader_Account_name {
                    font-weight: 600;
                    font-size: 15px;
                    color: #333;

                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .SiderHeader_Account_email {
                    display: none;
                }
            }

            .SiderHeader_Account_avatarWrapper {
                padding: 5px 20px;
            }

            .SiderHeader_Account_arrowWrapper {
                padding: 5px 20px;
            }
        }
    }

    .Submit_Test {
        padding: 20px;
        row-gap: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        button {
            width: 100px;
        }
    }

    .test_timeoutTest {
        animation: fadeIn 0.5s ease-in;
        padding: 10px 15px;
        color: red;
        text-align: center;
    }
}

@media screen and (max-width: 1024px) {
    .test_sidebar {
        display: none;
        box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
        overflow: hidden !important;
        position: fixed !important;
        border-radius: 0px;
        height: 10vh !important;
        right: 0px;
        left: 0px;
        top: 0px;
        max-width: none !important;
        min-width: none !important;
        width: auto !important;

        .SiderHeader {
            height: 100%;
            padding: 20px 25px;
        }

        .Submit_Test {
            display: none;
        }

        .ClockCountdown {
            display: none;
        }

        .NumberQuestion {
            display: none;
        }
    }

    .test_wrapper {
        margin-right: 0;
    }
}
