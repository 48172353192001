.question-formitems.set-publishing-status {
    column-gap: 70px;

    .ant-form-item {
        align-items: flex-start;
        margin-bottom: 0;
    }

    .ant-form-item-label {
        margin-right: 50px;
    }

    .ant-radio-wrapper.app-radio {
        margin-right: 50px;
        line-height: 45px;

        &:last-child {
            margin-right: 0;
        }
    }

    .status-field-desc {
        display: block;
        font-size: 16px;
        font-style: italic;
        color: rgba(0, 0, 0, 0.8);
    }
}

// RESPONSIVE:
@media screen and (max-width: 1399.98px) {
    .question-formitems.set-publishing-status {
        .ant-radio-wrapper.app-radio {
            line-height: 36px;
        }
    }
}
