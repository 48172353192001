@use 'src/assets/styles/utilities/colors' as c;

.help-menu-layout {
    .ant-layout-sider {
        position: relative;
        background-color: #fff;
        padding-bottom: 40px;

        .ant-menu {
            height: 100%;
            overflow-x: hidden;
            overflow-y: auto;
        }

        .help-menu-bottom {
            position: absolute;
            bottom: 0px;
            left: 0;
            right: 0;
            font-size: 16px;
            text-align: center;
            height: 40px;
            line-height: 40px;
            color: #303030;
            background-color: rgba($color: #000000, $alpha: 0.02);
            cursor: pointer;

            &:hover {
                background-color: rgba($color: #000000, $alpha: 0.08);
            }

            svg {
                font-size: 16px;
            }

            span[class="minimize"] {
                margin-left: 10px;
            }
        }
    }

    .ant-layout-sider-children {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
    }

    .ant-layout-sider-trigger {
        display: none;
    }

    .help-menu-title {
        font-size: 18px;
        font-weight: 700;
        text-align: center;
        color: #303030;
        padding: 8px;
        width: 100%;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
        z-index: 10;

        span {
            height: 40px;
            line-height: 40px;
            display: block;
            background-color: rgba($color: #000000, $alpha: 0.08);
            border-radius: 5px;
        }
    }

    .sider-menu-title {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .icon_list {
        font-size: 22px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 7px;

        > span {
            background-color: rgba($color: #000000, $alpha: 0.08);
            width: 30px;
            height: 30px;
            border-radius: 5px;
            line-height: 30px;
            text-align: center;
            border: solid 1px #ccc;

            &:hover {
                box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 2px 0px;
            }
        }

        svg {
            z-index: 3;
        }
    }

    .ant-layout-sider-zero-width-trigger {
        top: 1px;
        right: -28px;
        width: 26px;
        height: 28px;
        line-height: 28px;
    }

    .ant-layout-sider-zero-width-trigger {
        background-color: c.$primary;
        opacity: 0.6;
    }

    .ant-menu-title-content {
        font-size: 16px;
    }

    .ant-layout-sider-collapsed {
        .ant-menu-item {
            display: none;
        }

        .help-menu-title {
            display: none;
        }

        .help-menu-title span {
            display: none;
        }
    }

    .ant-layout {
        background: unset;
        background-color: #f5f5f5;
        height: 100%;
        overflow-y: auto;

        &-header {
            font-size: 18px;
            font-weight: 700;
            text-align: center;
            color: #303030;
            padding: 8px;
            width: 100%;
            height: 56px;
            line-height: 40px;
            border-bottom: 1px solid #eeee;
            box-shadow: 2px 0 2px 0 rgba(0, 0, 0, 0.2);
            background-color: #f5f5f5;
            position: sticky;
            top: 0;
            opacity: 1;
            z-index: 1;
        }

        &-sider-children {
            height: 100%;
            overflow-x: hidden;
            overflow-y: auto;
        }

        &-sider-trigger {
            display: none;
        }
    }

    .help-content-wrapper {
        position: relative;
    }

    .navigate-button {
        .navigate-icon {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(0, -50%);

            &.btn {
                width: 36px;
                height: 36px;
                min-width: 36px;
                min-height: unset;
                border-radius: 50%;

                .anticon {
                    display: block;
                }
            }

            &.prev {
                left: 16px;
                right: auto;
            }

            &.next {
                left: auto;
                right: 32px;
            }
        }
    }

    .slick-slide {
        opacity: 0;
    }

    .slick-slide.slick-active.slick-current {
        opacity: 1;
    }
}
