.sign-in-container {
    // Variables:
    $input-text-color: rgba(99, 99, 100, 1);
    $input-icon-color: rgba(99, 99, 100, 0.7);
    // CSS:
    position: relative;
    width: 100%;

    // (Ant Design) Form:
    .ant-form-item {
        .ant-form-item-label > label {
            font-size: 16px;
            line-height: 26px;
            height: auto;
        }

        svg {
            fill: $input-icon-color;
        }
    }

    .ant-form-item-label {
        margin-right: 8px;
        font-weight: 700;

        & > label::after {
            display: none;
        }

        & > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
            display: none;
        }
    }

    // (Ant Design) Input:
    .app-input.ant-input,
    .app-input.ant-input-affix-wrapper,
    .app-input.ant-input-affix-wrapper > input.ant-input {
        &::placeholder {
            color: #636364;
            font-style: normal;
        }
    }

    .app-input.ant-input-affix-wrapper.ant-input-password {
        & > input.ant-input::placeholder {
            color: rgba(99, 99, 100, 0.7);
        }
    }

    // Elements:
    .sign-in-wrapper {
        width: 100%;
        text-align: center;
        // animation: zoomIn 0.5s ease-in-out;

        h1 {
            font-size: 30px;
            font-weight: bold;
            color: #0077ff;
            margin-bottom: 15px;
        }

        h4 {
            font-size: 18px;
            font-weight: normal;
            color: $input-text-color;
            margin-bottom: 20px;
        }

        .sign-in-form {
            text-align: left;

            h4 {
                margin-bottom: 5px;
                font-size: 16px;
                font-weight: 700;
                line-height: 26px;
                color: #181818;
            }

            .sign-in-option {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 20px;
                margin-top: 15px;
            }

            .submit-btn {
                width: 100%;
                height: 50px;
                margin-top: 15px;
            }
        }
    }

    @media screen and (max-width: 1599px) {
        .sign-in-wrapper {
            h1 {
                font-size: 24px;
                margin-bottom: 0;
            }
            h4 {
                font-size: 14px;
            }
        }
    }

    @media screen and (min-width: 1920px) {
        min-height: auto;
        margin: auto;
    }
}

.sign-in-identification-modal {
    .ant-modal-header {
        background-color: #0077ff;

        border-bottom: none;
        border-radius: 10px 10px 0 0;

        .ant-modal-title {
            color: #fff;
            font-size: 18px;
            line-height: 35px;
        }
    }

    .ant-modal-content {
        background-color: #0077ff;
        border-radius: 10px;
    }

    .role-wrapper {
        h4 {
            font-size: 18px;
            line-height: 35px;
            color: #fff;
            margin-top: 10px;
            margin-top: 10px;
            text-align: center;
        }

        button {
            width: 100%;
            font-size: 18px;
            line-height: 25px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border: none;
            outline: none;
            border-radius: 10px;
            background-color: #fff;
            padding: 12px 0;
            cursor: pointer;
            transition: all 0.3s ease;

            &:hover {
                background-color: #ff1d86;
                color: white;
                font-weight: bold;
            }
        }
    }
}

.sign-in-separator {
    position: relative;

    span {
        position: relative;
        background-color: #fff;
        padding: 0 10px;
    }

    &::before {
        border-top: 1px solid #bfbfbf;
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
    }
}

.sign-in-login-with {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 30px;
    overflow: hidden;

    .btn {
        width: 50%;
        height: 50px;
        min-width: unset;
        color: #000;
        border: 1px solid rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));

        .btn__icon-wrapper img {
            height: 30px;
            max-width: 30px;
        }

        &:hover {
            color: #000;
            background-color: #ececec;
        }
    }
}

.sign-in-register {
    a {
        color: #0077ff;
    }
}

@keyframes zoomIn {
    0% {
        transform: scale(0.8);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

// RESPONSIVE DESIGN:
@media screen and (max-width: 1399.98px) {
    .sign-in-container {
        .ant-form-item {
            margin-bottom: 8px;
        }

        .sign-in-wrapper .sign-in-form .submit-btn {
            height: 40px;
        }
    }

    .sign-in-login-with {
        .btn {
            height: 40px;

            .btn__icon-wrapper img {
                height: 25px;
                max-width: 25px;
            }
        }
    }
}

@media screen and (max-width: 991.98px) {
    .sign-in-login-with {
        .btn {
            .btn__icon-wrapper {
                margin-right: 0;
            }

            .btn__icon-wrapper + * {
                display: none;
            }
        }
    }
}
