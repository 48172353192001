@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Tsukimi+Rounded:wght@300;400;500;600;700&display=swap");

html {
    scroll-behavior: smooth;
}

*,
body,
html {
    font-family: "mulish", sans-serif;

    --keyboard-background: rgb(52, 52, 52);
}

body,
html {
    height: auto;
    min-height: 100%;
}

// Form items:
.hr-between-formitems {
    margin-bottom: 24px;
}

// Elements used for table:
.table-content-format {
    .text-limit-lines {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        max-width: unset;
        max-height: 72px;

        * {
            width: auto !important;
            max-height: inherit;
        }
    }

    & * {
        font-weight: 400 !important;
        font-size: 16px !important;
        line-height: 24px !important;
        color: rgba(0, 0, 0, 0.7) !important;
        background: none !important;
        white-space: normal !important;
        margin: 0 !important;
    }

    p {
        margin-bottom: 0;
    }

    table,
    video {
        display: none;
    }
}

.content-highlight,
.contentHighlight {
    .selected {
        user-select: none;
        cursor: pointer;
        background-color: yellow;
        color: red;

        * {
            display: inline;
        }
    }

    .selected[data-selected="true"] {
        outline: 3px solid #4099ff;
    }
}

// Others:
.m-0 {
    margin: 0 !important;
}

//General padding for pages
.page-padding {
    padding: 20px 30px 30px 30px;
}

// RESPONSIVE:
@media screen and (max-width: 1399.98px) {
    // Elements used for table:
    .table-content-format {
        & * {
            font-size: 14px !important;
            line-height: 18px !important;
        }
    }
}

:host {
    display: none;
}

::slotted(p) {
    display: none;
}

.flex {
    display: flex;
    &-center {
        align-items: center;
        justify-content: center;
    }
}
