@use 'src/assets/styles/utilities/colors' as c;
.viewScoreStudent_wrapper {
    background-color: #fff;
    min-height: 100vh;
    font-size: 18px;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }

    &.model {
        min-height: 80vh;
        height: 1vh;

        .viewScore_controlPanel {
            position: absolute;
        }
    }

    .viewScoreStudent_container {
        margin-right: 360px;
        display: flex;
        flex-direction: column;
        height: 100%;

        .viewScoreStudent_writing {
            .viewScoreStudent_writingTitle {
                font-weight: bold;
            }
        }

        .viewScoreStudent_listAnswer {
            background-color: #eeeeee;
            display: flex;
            flex-wrap: nowrap;
            overflow-x: auto;
            padding: 20px 10px;
            border-radius: 12px;
        }

        .viewScoreStudent_textArea {
            border-radius: 12px;
            padding: 20px;
            width: 100%;
            border: none;
            background: #eeeeee;
        }

        .viewScoreStudent_box {
            padding: 20px;

            &:first-child {
                h3 {
                    margin-top: 90px;
                }
            }

            h3 {
                margin-top: 10px;
                font-size: 26px;
                font-weight: bold;
            }
        }

        .studentFrame_header {
            .studentFrame_title {
                font-size: 26px;
                font-weight: bold;
                margin-top: 25px;
            }
        }

        .viewScoreStudent_header {
            display: none;
        }

        .studentFrame_wrapper {
            margin: 0;
            padding: 10px 28px 10px 44px;
        }

        .viewScoreStudent_container_teacherSaveMark {
            display: none;
            margin-top: 30px;
            margin-bottom: 30px;
            text-align: center;
        }

        @media screen and (max-width: 1024px) {
            margin-right: 260px;
        }

        @media screen and (max-width: 768px) {
            margin-right: 0;

            .studentFrame_wrapper {
                margin: 0;
                padding: 20px;
            }

            .studentFrame_header {
                .studentFrame_title {
                    margin-top: 0;
                    font-size: inherit;
                }
            }

            .viewScoreStudent_header {
                display: block;
            }

            .viewScoreStudent_box {
                padding: 20px;

                &:first-child {
                    h3 {
                        display: none;
                    }
                }
            }

            .viewScoreStudent_container_teacherSaveMark {
                display: block;
            }
        }
    }

    .viewScore_controlPanel {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        width: 360px;
        background: c.$primary;
        padding: 10px;
        color: #ffffff;
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
        z-index: 2;
        overflow-y: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }

        &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-image: url(../../../assets/images/decoration-clock.svg);
            background-repeat: no-repeat;
            background-position: bottom center;
            z-index: 1;
            mix-blend-mode: soft-light;
            opacity: 0.25;
        }

        .viewScore_controlPanel_inner {
            position: relative;
            z-index: 2;

            .viewScore_examResult {
                margin-top: 90px;
            }

            .viewScore_questionNumberList {
                margin-top: 50px;
            }

            .viewScore_teacherSaveMark {
                margin-top: 30px;
                margin-bottom: 30px;
                text-align: center;
            }
        }

        @media screen and (max-width: 1024px) {
            width: 260px;

            .viewScore_examResult {
                display: flex;
                justify-content: center;

                .exam-result {
                    transform: scale(0.6);
                }
            }
        }

        @media screen and (max-width: 768px) {
            display: none;
        }
    }
}
