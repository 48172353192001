.pdf-thumbnails.test {
    position: relative;
    overflow: unset;
    margin-right: 5px;
    width: 0;
    transition: all 0.5s ease;

    &.show {
        min-width: 180px;
        width: auto;
    }
    .thumbnails-list {
        max-height: calc(100vh - 150px);
        overflow-y: auto;
    }

    .toggle-btn {
        position: absolute;
        top: 50%;
        right: -18px;
        width: 22px;
        height: 36px;
        background-color: #ccc;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
        cursor: pointer;
    }
}
