.app-dropdown.menu-as-child {
    position: relative;

    .app-dropdown-content {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: #fff;
        box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
        transition: all 0.5s ease;
        // display: none;
        opacity: 0;
        visibility: hidden;
        max-height: 0;
        height: auto;

        & > div {
            max-height: 350px;
            overflow-x: hidden;
            overflow-y: auto;
        }
    }

    &.app-dropdown-open {
        .app-dropdown-content {
            // display: block;
            opacity: 1;
            visibility: visible;
            max-height: 350px;
        }
    }
}
