.detail_speech_to_text {
    .speech_to_text_select {
        .ant-select-selector {
            .ant-select-selection-item {
                line-height: 26px;
            }
        }
    }
    .ant-form-item-label {
        min-width: 100px;
    }
}
