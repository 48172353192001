.mathAnswer {
    display: flex;
    align-items: center;
}

.math-box {
    &--visible {
        display: block;
    }

    min-width: 400px;
    display: none;
    padding: 20px;
    border-radius: 6px;
    background-color: #fff;
    position: fixed;
    box-shadow: 0px 0px 4px #000;
    z-index: 999;
}
