.answer-for-multiple-choice {
    margin: 8px 0;

    .answer-wrapper {
        .answer-status {
            margin-right: 10px;
        }
    }

    &.chosen-correctly {
        .answer-wrapper {
            background-color: rgba(0, 255, 41, 0.1);
        }
    }

    &.chosen-incorrectly {
        .answer-wrapper {
            background-color: #ffe6e6;
        }
    }
}
