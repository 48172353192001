.reportTable {
    .reportTable_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .reportTable_title {
            font-size: 18px;
            font-weight: bold;
        }

        .filter-reportTable_selector {
            width: 160px;
            &.ant-select {
                .ant-select-selector {
                    background-color: #eee !important;
                    border-radius: 10px !important;
                    height: 45px;
                    align-items: center;

                    .ant-select-selection-search {
                        input {
                            height: 100%;
                        }
                    }
                }
            }
        }
    }

    .ant-table-body {
        max-height: none !important;
    }

    .reportTable_table {
        margin-top: 40px;

        .ant-table-cell {
            font-size: 16px !important;
        }
        .ant-table-row {
            background-color: #fff !important;

            &:nth-child(even) {
                background-color: #f8f8f8 !important;
            }
        }

        .reportTable_scoreBar {
            position: absolute;
            top: 7px;
            right: 0;
            bottom: 7px;
            left: 0;

            display: flex;
            justify-content: center;
            align-items: center;

            width: 100%;

            background-color: #2dac81;
            color: #fff;
        }
    }

    .ant-table-content {
        overflow: auto;
    }
}
