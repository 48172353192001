@use 'src/assets/styles/utilities/colors' as colors;

.pdf-thumbnails-item {
    padding-bottom: 10px;

    &.active {
        .pdf-thumbnails-item_number {
            background-color: colors.$primary;
        }

        .pdf-thumbnails-item_img {
            border: colors.$primary solid 1px;
        }
    }

    &_img {
        position: relative;
        min-width: 120px;
        // min-height: 180px;
        border-radius: 5px;
        border: rgba($color: #434b5d, $alpha: 0.4) solid 1px;
        cursor: pointer;

        img {
            border-radius: 5px;
        }
    }
    &_number {
        position: absolute;
        left: 0px;
        right: 0px;
        bottom: 0;
        height: 26px;
        line-height: 26px;
        opacity: 0.8;
        text-align: center;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        font-weight: 700;
        color: #fff;
        background-color: #434b5d;
    }
}
