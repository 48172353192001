@use 'src/assets/styles/utilities/variables' as vars;

.subscription-plan-card {
    .card-subsc-plan {
        position: relative;
        overflow: hidden;

        .card-wrapper {
            position: relative;
            width: 323px;
            background-color: #ffffff;
            border-radius: 10px;

            .card-header {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                margin-bottom: 50px;

                .header-icon {
                    height: calc(25px + 17px);
                }

                .header-title {
                    position: relative;
                    color: #090a0a;
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 32px;
                    padding: 0 20px;
                    text-transform: uppercase;

                    &::after {
                        position: absolute;
                        content: "";
                        top: 100%;
                        left: 50%;
                        transform: translate(-50%, 0);
                        width: 140px;
                        height: 1px;
                        background-color: #445cfe;
                    }
                }
            }

            .card-price {
                padding: 0 20px;
                text-align: center;
                margin-bottom: 20px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .price-current {
                    position: relative;
                    display: inline-block;
                    color: #445cfe;
                    font-weight: 700;
                    font-size: 32px;
                    line-height: 36px;
                }

                .price-usual {
                    position: relative;
                    display: inline-block;
                    color: #636364;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 24px;
                }

                .price-usual::after {
                    position: absolute;
                    content: "";
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: calc(100% + 8px);
                    height: 1px;
                    background-color: #636364;
                }

                .price-current .price-value {
                    margin-right: 8px;
                }

                .price-current .price-unit {
                    font-size: 24px;
                }

                .price-usual .price-value {
                    margin-right: 4px;
                }

                .price-discount-label {
                    position: absolute;
                    top: -27px;
                    right: -14px;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    display: inline-block;
                    width: 36px;
                    height: 36px;
                    font-weight: 700;
                    font-size: 10px;
                    line-height: 36px;
                    color: #ffffff;
                }
            }

            .card-body {
                padding: 0 20px;
            }

            .card-actions {
                margin-top: 50px;
                padding: 0 20px 0 20px;

                .btn {
                    width: 100%;
                }
            }
        }

        .card-label {
            position: absolute;
            top: 22px;
            right: calc(-160px / 4);
            transform: rotate(45deg);
            background-color: #4fe3c1;
            width: 160px;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #ffffff;
            text-align: center;
            padding: 5px;
        }
    }

    ul.plan-benefit-list {
        position: relative;
        list-style-type: none;
        margin: 0;
        padding: 0;

        & > li {
            display: flex;
            align-items: center;
            gap: 5px;

            & > span:first-child {
                display: inline-block;

                & > svg {
                    display: block;
                }
            }

            &:not(:last-child) {
                margin-bottom: 15px;
            }
        }
    }
}

.subscription-plan-card.skeleton {
    .card-subsc-plan {
        .card-wrapper {
            .card-header {
                .header-title {
                    width: 100%;
                    &::before {
                        position: absolute;
                        content: "";
                        top: 0;
                        left: 0;
                        width: calc(100% - 40px);
                        height: 100%;
                        margin: 0 20px;
                        border-radius: 5px;
                        background-color: hsl(200, 20%, 70%);
                        animation: skeletonLoadingChangeBgColor 0.5s ease infinite alternate;
                    }
                    &::after {
                        content: unset;
                    }
                }
            }
            .card-price {
                .price-current {
                    margin-bottom: 2px;
                }
                .price-current,
                .price-usual {
                    width: 100%;
                    background-color: #eee;

                    &::before {
                        position: absolute;
                        content: "";
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        border-radius: 5px;
                        background-color: hsl(200, 20%, 70%);
                        animation: skeletonLoadingChangeBgColor 0.5s ease infinite alternate;
                    }
                }
                .price-usual::after {
                    content: unset;
                }
                .price-discount-label {
                    display: none;
                }
            }
        }
    }

    ul.plan-benefit-list {
        &::before {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 5px;
            background-color: hsl(200, 20%, 70%);
            animation: skeletonLoadingChangeBgColor 0.5s ease infinite alternate;
        }
    }
}

.swiper.app-swiper .swiper-slide {
    .card-subsc-plan {
        .card-wrapper {
            border: 5px solid transparent;
        }
    }

    &.swiper-slide-active {
        .card-subsc-plan {
            .card-wrapper {
                border: 5px solid #445cfe;
            }
        }
    }
}

// RESPONSIVE:
@media screen and (min-height: 0px) {
    .subscription-plan-card {
        .card-subsc-plan {
            .card-wrapper {
                // width: clamp(250px, (323 / vars.$w) * 100vw, 323px);

                .card-header {
                    margin-bottom: clamp(30px, (50 / vars.$w) * 100vw, 50px);

                    .header-title {
                        font-size: clamp(16px, (24 / vars.$w) * 100vw, 24px);
                        line-height: clamp(24px, (32 / vars.$w) * 100vw, 32px);
                    }
                }

                .card-price {
                    margin-bottom: clamp(8px, (20 / vars.$w) * 100vw, 20px);

                    .price-current {
                        font-size: clamp(24px, (32 / vars.$w) * 100vw, 32px);
                        line-height: clamp(32px, (36 / vars.$w) * 100vw, 36px);
                    }

                    .price-current .price-unit {
                        font-size: clamp(16px, (24 / vars.$w) * 100vw, 24px);
                    }
                }

                .card-actions {
                    margin-top: clamp(30px, (50 / vars.$w) * 100vw, 50px);
                }
            }
        }

        ul.plan-benefit-list {
            & > li {
                &:not(:last-child) {
                    margin-bottom: clamp(8px, (15 / vars.$w) * 100vw, 15px);
                }
            }
        }
    }
}
