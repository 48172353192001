.view-image-labels-container {
    position: relative;
    display: inline-flex;
    align-items: center;
    border: 1px solid #d9d9d9;
    margin: auto;

    .image-wrapper {
        position: relative;
        display: block;
        margin: auto;

        img {
            display: block;
            margin: auto;
        }
    }
}
