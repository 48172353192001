.chart-wrapper {
    margin-top: -100px;
    margin-top: -35%;
}

@media screen and (max-width: 1024px) {
    .chart-wrapper {
        margin-top: 0%;
    }
}

@media screen and (max-width: 500px) {
    .chart-wrapper {
        margin-top: -30%;
    }
}
