.qr-code {
    &-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    canvas {
        border: solid 16px #455a55;
        border-radius: 10px;
    }
    .download-qr-btn {
        margin-top: 10px;
    }
}
