@use 'src/assets/styles/utilities/variables' as vars;
@use 'src/assets/styles/utilities/colors' as colors;

.class-item-wrapper {
    position: relative;
    height: 100%;

    .class-item-dropdown-btn {
        position: absolute;
        top: 10px;
        right: 10px;
        height: auto;
        padding: 5px 10px;
        border-radius: 20px;

        > span {
            display: block;

            svg {
                display: block;
                font-size: 20px;
            }
        }
    }

    & > .ant-spin-nested-loading,
    & > .ant-spin-nested-loading > .ant-spin-container,
    & > .ant-spin-nested-loading > .ant-spin-container > .class-item {
        height: 100%;
    }
}

.class-item {
    // cursor: pointer;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    min-height: 150px;
    height: auto;
    // Responsive:
    padding-left: max(15px, calc(30 / vars.$w * 100vw));
    padding-right: max(15px, calc(30 / vars.$w * 100vw));
    padding-top: max(10px, calc(10 / vars.$h * 100vh));
    padding-bottom: max(10px, calc(10 / vars.$h * 100vh));

    &:hover {
        box-shadow: 1px 1px 2px 1px rgb(0, 0, 0, 0.1);
    }

    p {
        margin-bottom: 5px;
        font-size: 14px;
        line-height: 35px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        color: #000;
    }

    .class-item-header {
        color: colors.$primary;
        font-size: 18px;
        line-height: 35px;
        font-weight: 700;
        margin-right: 32px;
    }

    .class-item-icon {
        margin-right: 5px;
    }
}

.class-item-add {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    min-height: 150px;
    height: 100%;
}

.class-item-loadmore {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    min-height: 275px;
    height: 100%;
    cursor: pointer;

    .class-btn {
        background: none;
        border: none;
        color: #888;
        font-size: 18px;
        line-height: 35px;
        font-weight: 500;
    }
}

.class-item-menu {
    .class-item-menu-btn-wrapper {
        > div:first-child {
            min-width: 25px;
        }
    }
}
