@use 'src/assets/styles/utilities/variables' as vars;
@use 'src/assets/styles/utilities/colors' as colors;

// ClassNames: app-table.

// Table:
.ant-table-wrapper.app-table {
    .ant-table,
    .ant-table.ant-table-fixed-header.ant-table-scroll-horizontal {
        background-color: transparent !important;

        &.ant-table-empty {
            background-color: transparent;
        }
    }

    .ant-table .ant-table-expanded-row-fixed {
        width: auto !important;
        margin: 0;
        padding: 0;
    }

    .ant-table table colgroup col {
        width: auto !important;
    }

    .ant-table-container {
        background-color: #fff;
        border-radius: 10px;
        border: 1px solid #d9d9d9;
        overflow: hidden;

        .ant-table-thead {
            .ant-table-cell {
                // background-color: #eeeeee;
                background-color: colors.$sunlight;
                border-right: 1px solid #fff;
                // border-right: none;
                text-align: center;
                font-weight: bolder;
                font-size: 18px;

                &:last-child {
                    border-right: none;
                }

                &::before {
                    display: none;
                }
            }
        }

        .ant-table-tbody {
            .ant-table-row {
                .ant-table-cell {
                    // border-right: 1px solid #d9d9d9;
                    border-right: none;
                    font-size: 16px;
                    line-height: 24px;
                    color: rgba(0, 0, 0, 0.7);

                    &:last-child {
                        border-right: none;
                    }
                }

                &:last-child {
                    .ant-table-cell {
                        border-bottom: none;
                    }
                }

                &:nth-child(even) {
                    background-color: rgba($color: colors.$dream, $alpha: 0.1);
                }
            }
        }
    }

    .ant-table-pagination.ant-pagination {
        .ant-pagination-item {
            &:not(:last-child),
            &:not(:first-child) {
                background-color: transparent;
                border: none;
            }

            &.ant-pagination-item-active {
                border-radius: 50%;
                background-color: colors.$dream;

                a {
                    color: #ffffff;
                }
            }
        }

        &:first-child,
        &:last-child {
            button {
                border: none;
                background-color: transparent;
            }
        }
    }

    // Custom elements:
    .table-action {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;

        .table-action__btn {
            font-size: 18px;
            line-height: 35px;
            // border-color: colors.$primary;
            // color: colors.$primary;
            border-radius: 5px;
            height: auto;
            // background-color: transparent;
            min-width: auto;
        }

        .table-action__icon {
            display: inline-block;
            cursor: pointer;

            & > svg,
            & > span,
            & > span > svg {
                display: block;
            }

            &:not(.disabled) {
                @media (hover: hover) {
                    &:hover {
                        opacity: 0.6;
                    }
                }
            }

            &.disabled {
                cursor: default;

                & > svg,
                & > span > svg {
                    fill: #cdcfd0;
                }

                @media (hover: hover) {
                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }
    }
}

// Elements inside Table:
.cell-content {
    &.cont-txt {
        display: block;

        .txt-val {
            display: block;
        }

        .txt-desc {
            display: block;
            font-style: italic;
            color: rgba(0, 0, 0, 0.5);
        }
    }
}

// RESPONSIVE:
@media screen and (max-width: 1439.98px) {
    .ant-table-wrapper.app-table {
        .ant-table-tbody > tr > td,
        .ant-table-thead > tr > th,
        .ant-table tfoot > tr > td,
        .ant-table tfoot > tr > th {
            padding: clamp(8px, calc(16 / vars.$w * 100vw), 16px);
        }

        .ant-table-container {
            .ant-table-thead {
                .ant-table-cell {
                    font-size: clamp(14px, calc(18 / vars.$w * 100vw), 18px);
                }
            }

            .ant-table-tbody {
                .ant-table-row {
                    .ant-table-cell {
                        font-size: clamp(14px, calc(16 / vars.$w * 100vw), 16px);
                        line-height: 18px;
                    }
                }
            }
        }

        .table-action {
            gap: clamp(8px, calc(16 / vars.$w * 100vw), 16px);

            .table-action__btn {
                font-size: clamp(14px, calc(18 / vars.$w * 100vw), 18px);
                line-height: clamp(22px, calc(35 / vars.$w * 100vw), 35px);
                padding: 0px 4px;
            }
        }
    }
}
