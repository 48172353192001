.userBoard_wrapper {
    padding: 10px;
    min-width: 340px;
    .userBoard_inner {
        background-color: #0077ff;
        border-radius: 10px;
        position: relative;
        height: 100%;
        padding: 0 20px;

        .userBoard_avatarWrapper {
            position: absolute;
            top: -75px;
            background: #f0f6fb;
            padding: 10px;
            border-radius: 50%;
            left: 50%;
            transform: translateX(-50%);
            img {
                border-radius: 50%;
                width: 130px;
                height: 130px;
            }
        }

        .userBoard_info {
            padding-top: 135px;
            padding-bottom: 20px;
            text-align: center;
            color: #fff;

            .userBoard_info_item {
                margin-bottom: 35px;
                h5 {
                    color: #fff;
                    font-weight: bold;
                    font-size: 14px;
                    text-transform: uppercase;
                    margin-bottom: 0;
                }
            }
        }
    }

    .userBoard__exportBtn {
        background-color: #445cfe;
        color: #fff;
        // box-shadow: 0px -3px 0px #445c9e inset;
        border-bottom: 3px solid #445c9e;
        outline: none;
        border: none;
        border-radius: 10px;
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        padding: 7.5px 10px;
        min-width: 175px;
        cursor: pointer;
        white-space: nowrap;
        margin-bottom: 35px;

        &:active {
            transform: scale(0.97);
        }
    }

    @media screen and (max-width: 500px) {
        min-width: auto;
    }
}
