.ClockCountdown {
    display: flex;
    flex-direction: column;

    .Clock {
        display: flex;
        justify-content: center;
        margin: 30px 0;
        color: #fff;
    }

    .ClockCountdown_title {
        font-size: 18px;
        text-align: center;
        font-weight: bold;

        margin-top: 15px;
    }
}

@media screen and (max-width: 1024px) {
    .ClockCountdown {
        display: block;
    }
}
