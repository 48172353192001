.studentFrame_wrapper {
    padding: 10px 30px;
    // margin-top: 92px;

    .studentFrame_navItem {
        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }

    .studentFrame_inner {
        background: #fff;
        border-radius: 20px;
        padding: 10px 30px;
    }
    .studentFrame_header {
        padding: 10px 0;
        display: flex;
        justify-content: space-between;
    }

    .studentFrame_title {
        font-size: 16px;
        font-weight: bold;
    }

    .studentFrame_select {
        display: none;

        .ant-select-selector {
            min-width: 100px;
        }
    }

    .studentFrame_container {
        min-height: calc(100vh - 400px);
        height: auto;
    }
}

@media screen and (max-width: 1024px) {
    .studentFrame_wrapper {
        // margin-top: 92px;
        // padding: 10px 10px 0 10px;
        // margin: -20px 0;
        // min-width: 150px;
        // .studentFrame_header {
        //     .studentFrame_title {
        //         white-space: nowrap;
        //     }
        // }
    }
}

@media screen and (max-width: 768px) {
    .studentFrame_wrapper {
        padding: 0;
        margin: -20px 0 !important;
        min-width: 150px;
        position: relative;

        .studentFrame_title {
            white-space: nowrap;
        }

        .studentFrame_select {
            display: block;
        }

        .studentFrame_header {
            margin-top: 20px;
        }
    }
}
