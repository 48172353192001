@use 'src/assets/styles/utilities/colors' as colors;

.modal-mark-assignment-table {
    .ant-modal-content {
        border-radius: 10px;
        overflow: hidden;
    }

    .ant-table {
        .ant-table-tbody {
            .ant-table-cell {
                text-align: center;
            }
        }
    }

    .table-action {
        .table-action__icon {
            span {
                margin-left: 10px;

                svg {
                    font-size: 25px;
                    color: colors.$primary;

                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
        }
    }

    .ant-table .ant-table-container,
    .ant-table.ant-table-fixed-header.ant-table-scroll-horizontal .ant-table-container {
        border: none;
        border-radius: unset;
        padding: 8px;
    }

    .ant-table-wrapper {
        .ant-table .ant-table-expanded-row-fixed {
            margin: 0;
            padding: 0;
        }
    }
}

.modal-mark-assignment {
    .ant-modal-content {
        border-radius: 20px;
        overflow: hidden;
    }
}
