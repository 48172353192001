.verify {
    width: 100%;
    .sign-in-wrapper {
        width: 100%;
        padding: 30px 0;
        text-align: center;

        .verify-wrapper {
            width: 50%;
            margin: auto;
            text-align: left;
            padding: 0 20px;
            @media screen and (max-width: 767px) {
                width: 100%;
            }

            .verify-img {
                text-align: center;
                margin-bottom: 20px;
            }

            .sign-in-register {
                text-align: center;
            }

            .btn {
                width: 100%;
                padding: 8px 10px;
            }
        }
    }
}
