.dashboard {
    &-container {
        margin-top: 30px;
    }

    &-inner {
    }

    &-header {
        margin-bottom: 30px;
    }

    &-cards {
        .list-card {
            display: grid;
            gap: 20px;
            grid-template-columns: repeat(3, 1fr);
        }
    }

    @media screen and (max-width: 1600px) {
        &-cards {
            .list-card {
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }

    @media screen and (max-width: 900px) {
        &-cards {
            .list-card {
                grid-template-columns: repeat(1, 1fr);
            }
        }
    }
}
