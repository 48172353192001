.app-modal.modal-merge-class {
    .list-class-items {
        max-height: 300px;
        overflow-y: auto;
    }

    .info-confirmation-wrapper {
        .info-confirmation-box {
            padding: 5px 10px;
            border-radius: 10px;
            background-color: #eff6ca;
            cursor: pointer;
            &:hover {
                opacity: 0.8;
            }
            .label {
                font-weight: 600;
            }
        }
        &.hightlight {
            .info-confirmation-box {
                background-color: #eff6ca;
                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }

    .list-class-items.intend-class {
        .info-confirmation-wrapper {
            .info-confirmation-box {
                background-color: #ffccc7;
            }
        }
    }
}
