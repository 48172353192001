.pdf-drawer {
    &-container {
        position: relative;
        padding: 0 20px;
        .page-adjust-tool {
            position: absolute;
            left: 8px;
            top: 80%;
            display: flex;
            flex-direction: column;
            gap: 2px;
        }
        .zwibbler-draw-container {
            overflow: unset;
        }

        .pdf-container {
            // width: 600px;
            overflow-x: auto;
        }
        .pdf-wrapper {
            height: calc(100vh - 200px);
            overflow-y: auto;
        }
    }
}
