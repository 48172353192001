.exam-result {
    text-align: center;
    position: relative;
    .progress-bar {
        max-height: 184px;
        overflow: hidden;

        .ant-progress-text {
            display: block;
            position: absolute;
            bottom: 50%;
            left: 50%;
            top: auto;
            right: auto;
            transform: translate(-50%, 0);
            margin-bottom: -12px;

            .result-wrapper {
                display: block;
                color: #fff;
                font-weight: 400;
                font-size: 16px;
                text-align: center;
            }

            .result-wrapper > span {
                display: block;
            }

            .result-wrapper span.score-wrapper {
                font-weight: 700;
                font-size: 35px;
                text-transform: lowercase;
                text-shadow: 0 0 5px #000;
                max-width: 280px;
                margin: 0 auto;
                margin-bottom: 12px;
                text-align: center;
            }

            .result-wrapper span.score-value {
                font-weight: 700;
                font-size: 75px;
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
            stroke-width: 4;
        }
    }

    &.mobile {
        display: flex;
        justify-content: center;
        position: relative;
        height: 70px;
        .progress-bar {
            position: absolute;
            top: -20px;
            .ant-progress-text {
                .result-wrapper {
                    .score-wrapper {
                        display: flex;
                        flex-direction: column;

                        .score-value {
                            font-size: 55px;
                        }

                        .score-text {
                            font-size: 15px;
                        }
                    }

                    .result-totalScore {
                        font-size: 10px;
                    }
                }
            }
        }
    }
    &.small {
        padding: 15px;
        border-radius: 20px;
        .score-wrapper {
            font-size: 20px !important;
            .score-value {
                font-size: 20px !important;
            }

            .score-text {
                font-size: 12px !important;
            }
        }
        .result-totalScore {
            font-size: 12px !important;
        }
        .ant-progress-circle {
            // position: absolute;
        }

        &.pdf {
            margin-top: 50px;
        }
    }
}
