.round.cardItem_status {
    padding: 1px 12px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    border-radius: 10px;
    white-space: nowrap;

    &-upcoming {
        color: rgba(68, 92, 254, 1);
        background-color: rgba(68, 92, 254, 0.1);
    }
    &-happenning {
        color: rgba(18, 157, 41, 1);
        background: #e6ffea;
    }
    &-finished {
        color: rgba(255, 0, 0, 1);
        background: rgba(255, 0, 0, 0.1);
    }
}
