.modal-detail-role {
    .form-item-group-permission {
        & > .ant-row > .ant-col:nth-child(2) {
            min-width: 350px;
        }
    }
}

@media screen and (max-width: 767.98px) {
    .modal-detail-role {
        .form-item-group-permission {
            & > .ant-row > .ant-col:nth-child(2) {
                min-width: 100%;
            }
        }
    }
}
