.disk-usage-statistics {
    .disk-usage-chart-wrapper {
        position: relative;

        .chart-legend-notes {
            position: absolute;
            top: auto;
            right: auto;
            bottom: 0;
            left: 0;
            padding: 2px 0;
            margin-bottom: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 12px;
            z-index: 1;
        }
    }

    .btn-note {
        width: 16px;
        height: 16px;

        &.btn {
            min-height: unset;
            box-shadow: 0 0 0 5px #ddd;
        }

        &.btn.btn-icon-only .btn__content {
            width: 100%;
            height: 100%;
        }
    }
}
