@use 'src/assets/styles/utilities/colors' as colors;

.testLayout_wrapper.pdf {
    .viewScoreStudent_header {
        background-color: colors.$primary;
        color: #fff;
        height: 120px;
        border-radius: 20px;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 20px;

        .title {
            flex: 1;
            font-size: 25px;
            font-weight: 700;
            color: #fff;

            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1; /* number of lines to show */
            line-clamp: 1;
            -webkit-box-orient: vertical;
            margin-right: 20px;
        }

        .actions {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }
}
