.modal-invlink-asgmt {
    .invlink-title-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 10px;
    }

    .invlink-btn {
        min-width: unset;
    }
}
