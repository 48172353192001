@use 'src/assets/styles/utilities/variables' as vars;
@use 'src/assets/styles/utilities/colors' as colors;

.btn {
    outline: none;
    border: none;
    border-radius: 10px;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    padding: 7.5px 10px;
    min-width: 175px;
    min-height: 40px;
    cursor: pointer;
    white-space: nowrap;

    &:active {
        transform: scale(0.97);
    }

    @media (hover: hover) {
        &:hover {
            filter: opacity(0.8);
        }
    }

    &__content {
        display: flex;
        align-items: center;
        justify-content: center;
        color: inherit;
    }

    &__icon-wrapper {
        display: block;
        margin-right: 10px;

        img {
            display: block;
            width: auto;
            height: 18px;
            max-width: 18px;
        }

        svg {
            display: block;
            width: auto;
            height: 18px;
            max-width: 18px;
        }

        & > .anticon,
        & > .anticon > svg {
            display: block;
        }
    }

    // Types:
    &.btn-simple {
        padding: 0;
        min-width: unset;
        background: none;
    }

    &.btn-normal {
        background-color: colors.$primary;
        color: #fff;
    }

    &.btn-grey {
        background-color: #eeeeee;
        box-shadow: 0px -1px 0px #ddd inset;

        .btn__content {
            & > div:nth-child(2) {
                color: #000;
                opacity: 0.6;
            }
        }

        .btn__icon-wrapper {
            img {
                filter: grayscale(100%);
            }

            svg {
                fill: rgba($color: #000000, $alpha: 0.6);
            }
        }
    }

    &.btn-primary {
        display: inline-block;
        background-color: colors.$primary;
        color: #fff;
        box-shadow: 0px -3px 0px colors.$primary_darker inset;

        .btn__icon-wrapper {
            svg {
                fill: #fff;
            }
        }
    }

    &.btn-ghost {
        padding: 6.5px 9px;
        background: none;
        color: colors.$primary;
        border: 1px solid colors.$primary;
        transition: background-color 0.2s ease, color 0.2s ease, border 0.2s ease;

        &:hover {
            filter: opacity(1);
            background-color: colors.$primary;
            color: #fff;
        }
    }

    &.btn-ghost2 {
        padding: 6.5px 9px;
        background: none;
        color: #fff;
        border: 1px solid #fff;
        transition: background-color 0.2s ease, color 0.2s ease, border 0.2s ease;

        &:hover {
            filter: opacity(1);
            background-color: colors.$primary;
            color: #fff;
        }
    }

    // Others:
    &.btn-icon-only {
        display: inline-flex;
        justify-content: center;
        align-items: center;

        .btn__content {
            width: 30px;
            height: 30px;
            background-color: #ddd;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }

        .btn__icon-wrapper {
            margin-right: 0;
        }
    }

    // DISABLED.
    &.disabled,
    &:disabled {
        background: none;
        background-color: #cdcfd0;
        color: #979c9e;
        box-shadow: none;
        pointer-events: none;
        cursor: auto;
    }

    &.btn-simple {
        &.disabled,
        &:disabled {
            background: none;
            color: #cdcfd0;

            .btn__icon-wrapper {
                svg {
                    fill: #cdcfd0;
                }
            }
        }
    }

    &.btn-ghost {
        &.disabled,
        &:disabled {
            background: none;
            color: #cdcfd0;
            border: 1px solid #cdcfd0;

            .btn__icon-wrapper {
                svg {
                    fill: #cdcfd0;
                }
            }
        }
    }

    &.btn-second {
        background-color: colors.$second;
        color: colors.$white;
        .btn__icon-wrapper {
            svg {
                fill: colors.$white;
            }
        }
        &.has-shadow {
            box-shadow: 0px -3px 0px colors.$second inset;
        }
    }
}

// RESPONSIVE DESIGN:
.has-rd {
    @media screen and (max-width: 1399.98px) {
        &.btn {
            font-size: 16px;
            line-height: 18px;
            min-height: 36px;
        }
    }
}
