.container {
    margin-bottom: 10px;
}

.wrapper {
    padding: 4px;
    display: flex;
}

.actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1px;
}

.actionIcon {
    padding: 4px 4px 0 4px;
    cursor: pointer;
    font-size: 16px;
    line-height: 1;

    &:hover {
        color: #1ab394;
    }
}

.inputTitle {
    flex: 1;
    padding: 2px;

    input {
        width: 100%;
        height: 40px;
        border: none;
        outline: none;
        padding: 2px;
        background-color: transparent;
        &:focus {
            border-bottom: solid 2px #ccc;
        }
    }
}

.textTitle {
    margin: 0px;
    font-size: 20px;
    font-weight: bold;
    padding: 5px;
    width: 300px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: rgb(67, 75, 93);
}
