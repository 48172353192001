@use 'src/assets/styles/utilities/variables' as vars;
@use 'src/assets/styles/utilities/colors' as c;

.ant-modal.modal-select-question {
    $modal-width: 95%;

    // Important properties:
    // width: calc(100vw - 70px) !important;
    width: 100% !important;
    // max-width: 940px;

    /* Modal antd style */
    &--invisible {
        transform: translateX(-100%);
    }

    & > div.ant-modal-content {
        width: $modal-width;
        max-width: 1206px;
    }

    > div.ant-modal-content {
        position: relative;
        background-color: #ffffff;
        box-sizing: border-box;
        border-radius: 15px;
        margin: 0 auto;
    }

    .ant-modal-header {
        border-radius: 15px 15px 0 0;
        border: none;
        padding: 60px 60px 40px 60px;

        .ant-modal-title {
            font-weight: 700;
            font-size: 26px;
            line-height: 35px;
            text-align: center;
            color: #000000;
        }
    }

    .ant-modal-body {
        font-size: 16px;
    }

    .ant-modal-body {
        padding: 0;
    }

    .ant-modal-footer {
        border: none;
        padding: 24px;
    }

    .ant-modal-close {
        background-color: #ffffff;
        width: 35px;
        height: 35px;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        border-radius: 50%;
        position: absolute;
        top: calc(-35px + 20px);
        right: calc(-35px + 20px);
        transition: all 0.3s ease;

        &:hover {
            transform: rotate(180deg);
        }

        .ant-modal-close-x {
            width: 100%;
            height: 100%;
            display: inline-block;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .ant-modal-close-icon {
            > svg {
                fill: #000000;
            }
        }
    }

    /* End modal antd style */

    /* Modal skeleton */
    .modal-select-question__skeleton {
        padding: 30px 23px;
        display: flex;
    }

    .modal-select-question__preview-skeleton {
        display: flex;
        flex-direction: column;
        height: 100%;

        padding: 10px;
        &::-webkit-scrollbar-track {
            border-radius: 10px;
        }

        &::-webkit-scrollbar {
            width: 8px;
        }

        &::-webkit-scrollbar-thumb {
            background: #cfcece;
            border-radius: 10px;
        }
    }

    .modal-select-question__group {
        display: flex;
        flex-direction: column;
        padding: 20px 30px;

        max-height: calc(80vh - 30px);
        overflow-y: auto;
        padding-right: 10px;
        // width: 60%;
        flex: 60%;

        &::-webkit-scrollbar-track {
            border-radius: 10px;
        }

        &::-webkit-scrollbar {
            width: 8px;
        }

        &::-webkit-scrollbar-thumb {
            background: #cfcece;
            border-radius: 10px;
        }
    }

    .modal-select-question__preview {
        // width: 40%;
        // position: relative;
        flex: 40%;
    }

    // Group
    .modal-select-question__group-header {
        font-size: 26px;
        font-weight: 700;
        // margin-bottom:
    }

    .modal-select-question__group-tips {
        display: none;
        padding: 10px;
        margin-left: auto;

        &:hover {
            color: #445cfe;
        }

        &:active {
            transform: scale(0.9);
        }
    }

    .modal-select-question__group-list {
    }

    .modal-select-question__group-item {
    }

    .modal-select-question__type-icon {
        position: relative;
        height: 35px;
        width: 35px;
        border-radius: 5px;

        display: flex;
        justify-content: center;
        align-items: center;

        background-color: #445cfe;
        color: #fff;
    }

    .modal-select-question__type-label {
        display: flex;
        align-items: center;
        column-gap: 8px;

        font-size: 14px;
        color: #000;

        svg {
            color: #ff2c2c;
        }
    }

    .modal-select-question__group-item__title {
        font-size: 16px;
        font-weight: bold;
        margin-top: 34px;
        margin-bottom: 18px;
    }

    .modal-select-question__type-list {
        display: flex;
        flex-wrap: wrap;
    }

    .modal-select-question__type-item {
        padding: 5px;

        width: 50%;
        cursor: pointer;
    }

    .modal-select-question__type-item-skeleton {
        display: flex;
        column-gap: 10px;
        align-items: center;
        padding: 5px;
        border-radius: 5px;
        &:hover {
            background-color: #eeeeee;
        }

        &.disable {
            cursor: not-allowed;

            .modal-select-question__type-label svg {
                color: #333;
            }
        }
    }

    .modal-select-question__group-privilege {
        position: absolute;
        top: 0;
        right: 0;

        transform: translate(50%, -50%);
    }

    /* Preview */
    .modal-select-question__preview-thumb {
        position: relative;
        width: 100%;
        padding-top: 50%;
        border-radius: 5px;
        overflow: hidden;

        img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
    }

    .modal-select-question__preview-title {
        font-size: 16px;
        font-weight: bold;
        margin-top: 17px;
    }

    .modal-select-question__preview-description {
        margin-top: 11px;
        font-size: 14px;
    }

    .modal-select-question__preview-benefit {
        margin-top: auto;
        display: flex;
        align-items: center;
        column-gap: 10px;
    }

    .modal-select-question__preview-benefit__label {
        user-select: none;
        font-size: 12px;
        background-color: rgba($color: #445cfe, $alpha: 0.3);
        color: #445cfe;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        border-radius: 5px;
        height: 23px;
        white-space: nowrap;
    }

    .modal-select-question__preview-collapse-btn {
        position: absolute;
        bottom: 0;
        left: 50%;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 35px;

        transform: translateX(-50%) rotate(-90deg);
        transition: all 0.3s ease-out;

        border: 1px solid #ddd;
        border-radius: 50%;
        background-color: #fff;

        cursor: pointer;

        &:hover {
            background-color: #ddd;
        }
    }

    @media screen and (max-width: 992px) {
        .modal-select-question__type-item {
            width: 100%;
        }
    }

    @media screen and (max-width: 768px) {
        .modal-select-question__skeleton {
            flex-direction: column;
            flex-flow: column-reverse;
        }

        .modal-select-question__group {
            padding: 0;
            transition: all 0.3s ease-out;
            &--collapsed {
                max-height: calc(40vh - 15px);
            }
        }

        .modal-select-question__preview {
            position: relative;
            padding: 0;
        }

        .modal-select-question__preview-skeleton {
            padding: 0;
            max-height: 0;
            overflow-y: auto;
            background-color: #fff;
            border-radius: 5px;

            margin-bottom: 20px;
            transition: all 0.3s ease-out;

            &--collapsed {
                box-shadow: 0 1px 5px 1px rgba($color: #000000, $alpha: 0.3);
                max-height: calc(40vh - 15px);
            }
        }

        .modal-select-question__type-item {
            padding: 10px;
            width: 50%;
        }

        .modal-select-question__preview-benefit,
        .modal-select-question__preview-description,
        .modal-select-question__preview-title {
            padding: 0 10px;
        }
    }

    @media screen and (max-width: 678px) {
        .modal-select-question__type-item {
            // width: 100%;
            padding: 0px;
        }

        .modal-select-question__skeleton {
            padding: 0;
        }

        .modal-select-question__group {
            padding: 10px;
        }

        .modal-select-question__preview-benefit {
            margin-bottom: 10px;
        }
    }
}

// RESPONSIVE:
@media screen and (max-width: 1439.98px) {
    .ant-modal.modal-select-question {
        // $modal-width: 700;

        // & > div.ant-modal-content {
        //     width: clamp(375px, calc($modal-width / vars.$w * 100vw), $modal-width * 1px);
        // }

        // .ant-modal-header {
        //     padding: clamp(32px, calc(60 / vars.$w * 100vw), 60px);
        //     padding-bottom: clamp(24px, calc(40 / vars.$w * 100vw), 40px);

        //     .ant-modal-title {
        //         font-size: clamp(22px, calc(26 / vars.$w * 100vw), 26px);
        //         line-height: clamp(24px, calc(35 / vars.$w * 100vw), 35px);
        //     }
        // }

        // .ant-modal-body {
        //     padding-left: clamp(32px, calc(60 / vars.$w * 100vw), 60px);
        //     padding-right: clamp(32px, calc(60 / vars.$w * 100vw), 60px);
        //     padding-bottom: clamp(24px, calc(50 / vars.$w * 100vw), 50px);
        //     padding-top: clamp(24px, calc(50 / vars.$w * 100vw), 50px);
        // }
    }
}

@media screen and (max-width: 767px) {
    .ant-modal.modal-select-question {
        .modal-select-question__group-tips {
            display: inline;
        }

        & > div.ant-modal-content {
            width: auto;
        }

        .ant-modal-body {
            padding: 10px;
        }

        .ant-modal-close {
            top: 0;
            right: 0;
        }
    }
}

@media screen and (max-width: 575px) {
    .ant-modal.modal-select-question {
        width: calc(100vw - 40px) !important;
    }
}

@media screen and (max-width: 640px) {
    .ant-modal.modal-select-question {
        .modal-select-question__type-item {
            width: 100%;
        }
    }
}
