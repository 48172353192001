.multi-choice-answer {
    display: inline-block;
    border-radius: 10px;
    border: 1px solid #0077ff;
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    transition: all 0.3s ease-in-out;
    animation: fadeIn 0.3s ease-in-out;

    .multiple-choice-answer__editor {
        .mce-content-body {
            background-color: inherit;
            border: none;
        }
    }

    .multi-choice-answer__content {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        padding: 5px 10px;
    }

    .multi-choice-answer__letter {
        user-select: none;
        cursor: pointer;
        border-radius: 50%;
        background: radial-gradient(100% 100% at 142.5% 13.75%, #ffffff 0%, #0077ff 100%);
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        font-weight: 700;
        font-size: 16px;
        color: #fff;
        flex: none;

        @media (hover: hover) {
            &:hover {
                opacity: 0.7;
            }
        }
    }

    .multi-choice-answer__text {
        width: 100%;
        overflow: hidden;

        input {
            outline: none;
            border: none;
            background-color: transparent;
            color: #000;

            &:focus {
                box-shadow: none;
            }
        }

        .input-editor {
            width: 100%;

            .mce-content-body.editorim-content--input.mce-edit-focus {
                box-shadow: none;
            }

            img {
                max-width: 100px;
                max-height: 100px;
                width: auto;
                height: auto;
            }
        }
    }

    .multi-choice-answer__delete {
        cursor: pointer;
        display: flex;
        align-items: center;
        fill: #000;

        @media (hover: hover) {
            &:hover {
                opacity: 0.3;
            }
        }
    }

    &.multi-choice-answer-result {
        background-color: #0077ff;
        .input-editor__editor {
            color: #fff;
        }

        .mce-content-body img[data-mce-selected] {
            outline-color: red;
        }

        .mce-content-body:not([dir="rtl"])[data-mce-placeholder]:not(.mce-visualblocks)::before {
            color: #fff;
        }

        .multi-choice-answer__delete {
            color: #fff;
            z-index: 100;
        }

        .multi-choice-answer__letter {
            background: #fff;
            color: #07f;
        }
    }
}

.multi-choice-answers {
    .multi-choice-answers-flex {
        display: flex;
        align-items: center;
    }

    .multi-choice-answers__add {
        display: flex;
        align-items: center;
        margin: 5px 10px;

        button {
            background-color: #000;
            color: #fff;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            outline: none;
            border: none;
            cursor: pointer;

            @media (hover: hover) {
                &:hover {
                    opacity: 0.7;
                }
            }
        }
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
