@use 'src/assets/styles/utilities/variables' as vars;
@use 'src/assets/styles/utilities/colors' as c;

.ant-modal.app-modal.type-basic.modal-detail-exam.competition .detail-exam-form .ant-form-item-explain-error {
    color: #ff4d4f;
}

.ant-modal.app-modal.type-basic.modal-detail-exam.competition {
    .modal-detail-exam__left {
        flex: 50%;
        &-header {
            text-align: center;
        }
    }

    .modal-detail-exam__right {
        flex: 50%;
        .image-wrapper {
            display: block;
            width: 100%;
            height: 100%;
            position: relative;
            background-color: c.$primary;
        }
        .image-top {
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
            justify-content: flex-end;
            min-height: 70%;
            img {
                max-width: 98%;
            }
        }
        .image-bottom {
            position: absolute;
            bottom: 0;
            right: 0;
            display: flex;
            align-items: flex-end;
            height: 60%;

            img {
                max-width: 100%;
            }

            &.center {
                right: unset;
                justify-content: center;
                img {
                    max-width: 90%;
                }
            }
        }
    }

    .detail-exam-form {
        background-color: #fff;

        .note {
            color: c.$dark_gray;
        }
        .ant-row.ant-form-item {
            input::placeholder {
                color: c.$dark_gray !important;
            }
            input {
                color: c.$dark_gray;
            }
        }
        .ant-select-selection-item {
            color: c.$dark_gray;
        }
        .ant-select-selection-placeholder {
            color: c.$dark_gray !important;
        }

        .ant-picker {
            border-color: c.$dark_gray !important;
        }

        .ant-picker-suffix,
        .ant-select-arrow {
            //icon suffix right
            color: c.$dark_gray;
        }
        .ant-form-item-label > label {
            color: c.$dark_gray;

            &::before {
                color: c.$dark_gray !important;
            }
        }

        .ant-select-selector,
        .ant-input-status-error {
            border-color: c.$dark_gray !important;
        }

        .ant-input-number.app-input,
        .ant-input.app-input {
            border-color: c.$dark_gray !important;
        }

        .ant-input-number-handler-wrap {
            .ant-input-number:hover .ant-input-number-handler-wrap {
                svg {
                    fill: c.$dark_gray;
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        .modal-detail-exam__right {
            display: none;
        }
        .modal-detail-exam__left {
            flex: 100%;
        }
    }
}
