.assignment-report-wrapper {
    padding: 20px 0 0 0;

    .page-header-wrapper {
        margin: 0 30px 30px 30px;
    }

    .report-tabs-wrapper {
        .ant-tabs {
            .ant-tabs-nav {
                padding: 0 30px;
                margin-bottom: 18px;

                &::before {
                    display: none;
                }
            }

            .ant-tabs-tabpane {
                padding: 0 30px 30px 30px;
            }
        }

        .ant-tabs-nav-list {
            border: 1px solid #0077ff;
            padding: 5px;
            border-radius: 10px;

            .ant-tabs-tab {
                width: 150px;
                display: flex;
                justify-content: center;
                background-color: transparent;
                border: none;
                border-radius: 10px;
                padding: 0 25px;

                span {
                    font-size: 18px;
                    line-height: 35px;
                }

                &.ant-tabs-tab-active {
                    background-color: #0077ff;
                    border-radius: 5px;

                    > div {
                        color: #fff;
                    }

                    span {
                        color: #fff;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}
