@use 'src/assets/styles/utilities/variables' as vars;
@use 'src/assets/styles/utilities/colors' as colors;

.class-list.rounds {
    .rounds-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 20px;

        &-title h2 {
            font-size: 26px;
            font-weight: 700;
            margin: 0;
        }
    }
    .rounds-scroll-btn {
        display: inline-flex;
        gap: 5px;
    }

    .class-item {
        &-header {
            font-weight: 700;
            font-size: 18px;
            line-height: 35px;
            color: #636364;
        }
        &-header-top {
            display: flex;
            align-items: center;
            color: #445cfe;
            > .round-index {
                font-size: 24px;
                font-weight: 700;
                margin-right: 5px;
                white-space: nowrap;
            }
        }
    }

    .competition-assignment-result {
        font-size: 16px;
        font-weight: 700;
        &.passed {
            color: #52c41a;
        }
        &.failed {
            color: #ff4d4f;
        }
    }
}
