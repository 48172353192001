.exam-pdf-create {
    height: calc(100vh - 200px);
    &_container {
        gap: 10px;
    }

    .upload-area {
        display: flex;
        height: fit-content;
    }
}
