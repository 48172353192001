@use 'src/assets/styles/utilities/colors' as c;

.modal-helper {
    &.ant-modal.app-modal.type-primary {
        max-width: 1200px;

        .ant-modal-header {
            background-color: c.$primary;

            .ant-modal-title {
                color: #fff;
            }
        }

        .ant-modal-body {
            padding: 0;
        }
    }

    .ant-spin-nested-loading {
        > div > .ant-spin {
            max-height: unset;
        }
    }

    .ant-spin-nested-loading,
    .ant-spin-nested-loading .ant-spin-container,
    .ant-spin-nested-loading .ant-spin-container .modal-wraper,
    .ant-spin-nested-loading .ant-spin-container .modal-wraper .data-wrapper,
    .ant-spin-nested-loading .ant-spin-container .modal-wraper .no-data-wrapper {
        height: 100%;
    }

    .no-data-wrapper {
        position: relative;

        .no-data {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            font-size: 1.2rem;
        }
    }

    .help-menu-layout {
        height: 100%;

        .ant-layout-sider {
            height: 100%;
        }

        .ant-layout {
            height: 100%;
        }
    }
}

// .modal-helper-wrap {
//     &.ant-modal-wrap {
//     }
// }
