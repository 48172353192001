.button-import-export {
    .button-import {
        text-align: center;
        position: relative;
        &_sub-btn {
            cursor: pointer;
            position: absolute;
            left: 0;
            right: 0;
            font-size: 12px;
            line-height: 1.5;
            &:hover {
                color: #63a5e3;
            }
        }
    }
}
