@use 'src/assets/styles/utilities/colors' as c;

.answer-for-multiple-choice {
    &:hover {
        opacity: 0.8;
    }

    .answer-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 12px;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        border: 1px solid #eee;
        border-radius: 10px;
        max-width: 100%;
        transition: all 0.3s ease;
        cursor: pointer;

        .answer-content {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            word-break: break-word;

            .answer-letter {
                user-select: none;
                cursor: pointer;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                background: radial-gradient(100% 100% at 142.5% 13.75%, #ffffff 0%, #0077ff 100%);
                text-align: center;
                line-height: 30px;
                font-weight: 700;
                font-size: 16px;
                color: #fff;
                flex: none;
            }

            .answer-text {
                margin-left: 10px;

                img {
                    max-width: 100px;
                    max-height: 100px;
                    width: auto;
                    height: auto;
                }
            }
        }

        &:hover {
            background-color: #eee;
        }
    }

    &.chosen {
        .answer-wrapper {
            background-color: c.$second;

            .answer-content {
                .answer-letter {
                    background: none;
                    background-color: #fff;
                    color: c.$second;
                }

                .answer-text {
                    color: #fff;
                }
            }
        }
    }

    &.disabled {
        .answer-wrapper {
            cursor: not-allowed;
        }
    }
}

.multichoice-ans-imgviwer {
    display: inline-block;
    max-width: 100px;
    height: auto;
    max-height: 100px;
}
