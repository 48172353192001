.role {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
    &.third-party-role {
        background-color: #07f;
    }
    .role-clock {
        display: block;
        position: absolute;
        bottom: 0;
        right: 0;
        mix-blend-mode: soft-light;
        opacity: 0.5;
        z-index: 1;
        &.mobile {
            display: none;
        }
    }

    .role-girl-and-book {
        bottom: 0;
        position: absolute;
        right: 20%;
        z-index: 3;
        img {
            height: 400px;
            width: 350px;
        }
        &.mobile {
            display: none;
        }
    }

    .role-img-wrap {
        height: 100vh;
        width: 50%;
        min-height: 400px;
        > .color {
            background-color: #07f;
            height: 100vh;
            width: 100%;
            margin-left: 30%;
        }
    }

    .role-wrapper {
        max-width: 50%;
        margin-left: 10%;
        text-align: center;
        animation: zoomIn 0.5s ease-in-out;

        h1 {
            font-size: 45px;
            line-height: 55px;
            font-weight: bold;
            color: #fff;
        }

        p,
        h4 {
            font-size: 18px;
            line-height: 35px;
            font-weight: normal;
            color: #fff;
        }

        h4 {
            font-weight: bold;
        }

        .ant-row {
            button {
                width: 100%;
                font-size: 18px;
                line-height: 25px;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                border: none;
                outline: none;
                border-radius: 10px;
                background-color: #fff;
                padding: 12px 0;
                cursor: pointer;
                transition: all 0.3s ease;

                &:hover {
                    background-color: #ff1d86;
                    color: white;
                    font-weight: bold;
                }
            }
        }
    }

    @media screen and (max-width: 1199px) {
        width: 100%;
        background-color: #0077ff;
        flex-direction: column;
        position: relative;
        padding-top: 30px;

        .role-wrapper {
            position: relative;
            z-index: 1;
            margin-left: 0;
            max-width: 90%;
            height: 650px;
        }

        .role-clock.mobile {
            display: block;
            position: absolute;
            bottom: 0;
            right: 20%;
            mix-blend-mode: soft-light;
            opacity: 0.5;
            z-index: -2;
            img {
                width: 100%;
            }
        }

        .role-girl-and-book,
        .role-clock {
            display: none;
        }

        .role-girl-and-book.mobile {
            margin-top: 30px;
            display: block;
            z-index: -1;
            position: absolute;
            bottom: 0;
            right: 40%;
            img {
                height: 286px;
                width: 260px;
            }
        }
        .role-img-wrap {
            display: none;
        }
    }

    @media screen and (max-width: 575px) {
        .role-wrapper {
            margin-top: 30px;
            width: 90%;

            h1 {
                font-size: 35px;
                line-height: 40px;
            }
        }
    }

    @media screen and (max-width: 479px) {
        .role-clock.mobile {
            right: 14%;
        }
        .role-girl-and-book.mobile {
            img {
                width: 200px;
                height: 220px;
            }
        }
    }

    @media screen and (min-width: 2000px) {
        .role-wrapper {
            margin-left: 18%;
        }
    }
}

@keyframes zoomIn {
    0% {
        transform: scale(0.8);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.role.third-party-role {
    background-color: #fff;

    .role-wrapper {
        h1 {
            color: #0077ff;
        }

        p {
            color: #a9a9a9;
        }

        button {
            box-shadow: -2px 2px 10px 1px #0077ff80;

            &:hover {
                box-shadow: -2px 2px 5px 1px #ff1d8780;
            }
        }
    }

    @media screen and (max-width: 1199px) {
        background-color: #0077ff;

        .role-wrapper {
            h1,
            p {
                color: #fff;
            }
        }
    }
}
