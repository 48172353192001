.assignment-report-wrapper .report-question {
    .report-question-chart {
        position: relative;
        background-color: #fff;
        border-radius: 20px;
        padding: 20px;

        & > .option-menu-wrapper {
            text-align: end;
            margin-bottom: 20px;
        }

        & > .ant-spin-nested-loading {
            min-height: 400px;
        }

        .chart-null {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .detail-student-answer {
        margin-top: 40px;
    }

    .filter-menu-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;

        .filter {
            > span {
                font-weight: 400;
                font-size: 18px;
                line-height: 35px;
                margin-right: 10px;
            }

            .app-select {
                .ant-select-selector {
                    min-width: 200px;
                    border-color: #0077ff;
                    color: #0077ff;

                    .ant-select-selection-item {
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 35px;
                    }
                }

                .ant-select-arrow {
                    color: #0077ff;
                    font-size: 14px;
                }
            }
        }
    }

    .answer-record-list-wrapper {
        > div:not(:last-child) {
            margin-bottom: 30px;
        }
    }
}

@media screen and (max-width: 1199.98px) {
    .assignment-report-wrapper .report-question {
        .filter-menu-wrapper {
            align-items: flex-end;

            .filter {
                > span {
                    display: block;
                    margin-right: 0;
                }
            }

            .question-number-list {
                height: 42.5px;
            }
        }
    }
}

@media screen and (max-width: 767.98px) {
    .assignment-report-wrapper .report-question {
        .filter-menu-wrapper {
            flex-direction: column;
            align-items: flex-start;
            row-gap: 15px;

            .question-number-list {
                height: auto;
                text-align: center;

                > li {
                    margin-bottom: 8px;
                }
            }
        }
    }
}
