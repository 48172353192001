@use 'src/assets/styles/utilities/colors' as colors;

.confirm-popup-modal {
    .ant-modal-content {
        border-radius: 10px;

        .ant-modal-body {
            text-align: center;

            .modal-icon-wrapper {
                position: relative;
                top: -69px;
                display: flex;
                justify-content: center;
                align-content: center;
            }

            .modal-icon {
                width: 90px;
                height: 90px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                border: 2px solid #8c8c8c;
                border-radius: 50%;
                background-color: #fff;

                & > svg,
                & > .anticon,
                & > .anticon > svg {
                    display: block;
                    width: 45px;
                    height: 100%;
                    fill: colors.$danger;
                }

                &.submit-test {
                    svg {
                        color: colors.$danger;
                        width: 35px;
                        height: 35px;
                    }

                    svg > * {
                        fill: colors.$danger;
                    }
                }
            }

            p {
                font-size: 18px;
                line-height: 25px;
                margin-bottom: 0;
                margin-top: 5px;
            }

            .modal-title {
                margin-top: -40px;
                font-size: 26px;
                line-height: 35px;
                font-weight: 700;
            }
        }

        .ant-modal-footer {
            text-align: center;
            border-top: none;
            padding-bottom: 30px;

            .ant-btn {
                border-radius: 10px;
                font-size: 16px;
                line-height: 22px;
                font-weight: bold;
                border: none;
                height: 45px;
                min-width: 120px;

                &:first-child {
                    background-color: #ebebeb;
                }

                & + .ant-btn:not(.ant-dropdown-trigger) {
                    background-color: colors.$danger;
                    margin-left: 20px;
                }
            }
        }
    }

    .modal-actions {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;

        .btn.btn-grey {
            background-color: #ebebeb;
        }

        .btn.btn-primary {
            background-color: colors.$danger;
            box-shadow: 0px -3px 0px colors.$danger inset;
        }

        .btn.btn-grey,
        .btn.btn-primary {
            &.disabled,
            &:disabled {
                background-color: #cdcfd0;
                color: #979c9e;
                box-shadow: none;
            }
        }
    }
}
