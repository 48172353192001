@use 'src/assets/styles/utilities/colors' as colors;

.organization-user-list {
    &__toolbar {
        margin-bottom: 40px;
    }

    &__row {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 16px;

        &.storage-available-info {
            align-items: center;
            background-color: #fff;
            padding: 8px 16px;
            border-radius: 10px;
        }
    }

    .organization-user-list-actions {
        text-align: center;

        svg {
            font-size: 25px;
            color: colors.$primary;

            &:hover {
                opacity: 0.7;
            }
        }

        .action-btn {
            cursor: pointer;
            padding: 0 5px;
        }

        .action-btn.organization-user-delbtn {
            span svg {
                color: colors.$danger;
            }
        }
    }

    .storage-available-content {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 16px;
    }

    .storage-available-value {
        display: block;
        width: 100%;
        text-align: right;
        color: colors.$primary;

        & > span:nth-child(2) {
            margin: 0 4px;
        }
    }
}

.organization-user-list.question-bank {
    padding: 0;
}

// RESPONSIVE:
@media screen and (max-width: 1199.98px) {
    .organization-user-list {
        .toolbar-filter-wrapper {
            .filter-tools {
                align-items: flex-start;
            }

            .filter-actions {
                width: 100%;
                text-align: right;
            }
        }
    }
}

@media screen and (max-width: 767.98px) {
    .question-bank {
        .toolbar-filter-wrapper {
            .filter-tools {
                align-items: stretch;
                flex-direction: column;
            }
        }
    }
}
