.qc_highlight {
    .viewScoreStudent_highlightTitle {
        font-weight: bold;
    }

    .contentResultHighlight {
        text-align: justify;
        border: 1px solid #ddd;
        border-radius: 6px;
        padding: 6px;
        overflow: auto;
        display: block;

        .selected {
            background-color: yellow;
            color: red;
        }
    }
}
