.add-exam-bank {
    padding: 0 20px;

    .table-content-format {
        .text-limit-lines {
            max-width: unset;
        }
    }

    .table {
        .ant-table-body {
            .ant-table-tbody {
                .ant-table-row {
                    .ant-table-cell {
                        &:nth-child(5) {
                            text-align: left;
                        }

                        &:not(:nth-child(5)) {
                            text-align: center;
                        }
                    }
                }
            }
        }
    }

    .add-exam-table {
        .ant-table table {
            width: 100% !important;
        }

        .ant-table .ant-table-expanded-row-fixed {
            width: auto !important;
        }

        .ant-table table colgroup col {
            &:nth-child(1) {
                width: 50px !important;
            }
            &:nth-child(2) {
                width: 200px !important;
                min-width: 150px;
            }
            &:nth-child(3) {
                width: 200px !important;
                min-width: 150px;
            }
            &:nth-child(4) {
                width: 150px !important;
                min-width: 100px;
            }
            &:nth-child(5) {
                width: auto !important;
                min-width: 350px;
            }
            &:nth-child(6) {
                width: 150px !important;
                min-width: 100px;
            }
        }

        // These code below is important! It's used to prevent long content to be shown fully!
        // The max-width value is the same as min-width value of question content column.
        .table-content-format .text-limit-lines {
            max-width: 350px;
        }
    }

    .btn-group {
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        column-gap: 30px;
        row-gap: 16px;
    }
}

@media screen and (max-width: 1399.98px) {
    .add-exam-bank {
        .add-exam-table {
            .ant-table table colgroup col {
                &:nth-child(2) {
                    min-width: 100px;
                }
                &:nth-child(3) {
                    min-width: 100px;
                }
                &:nth-child(4) {
                    min-width: 100px;
                }
                &:nth-child(5) {
                    min-width: 200px;
                }
                &:nth-child(6) {
                    min-width: 100px;
                }
            }

            // These code below is important! It's used to prevent long content to be shown fully!
            // The max-width value is the same as min-width value of question content column.
            .table-content-format .text-limit-lines {
                max-width: 200px;
            }
        }
    }
}
