/*
 * Global class names:
 * .view-image-with-labels
 * .view-lblanswer-drg
 * .view-lblanswers
 */

.q-imgwithlbls {
    .q-content-section {
        &:not(:last-child) {
            margin-bottom: 30px;
        }
    }
}

.view-image-with-labels {
    position: relative;
    display: flex;
    // justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    background-color: #f5f5f5;
    border-width: 2px;
    border-style: dashed;
    border-color: #d9d9d9;
    border-radius: 10px;
    padding: 8px;
    margin: 0px auto;
    user-select: none;
    overflow: auto;
}

// Answer item:
.view-lblanswer-drg {
    &.imglbl-drg-wrapper {
        .imglbl-drg {
            opacity: 0;

            .lblanswer-item {
                width: 100%;
                max-width: unset;
                margin: 0;
                border: none;
            }

            &.react-draggable-dragging {
                opacity: 0.8;
                transition: opacity 0.2s ease;
            }
        }
    }

    .lblanswer-item {
        background-color: #fff;
        border-radius: 10px;
    }
}

// Answer list:
.view-lblanswers {
    &.dropdown {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        column-gap: 40px;
        row-gap: 10px;
        background: #f0f7ff;
        padding: 12px 30px;
        border-radius: 10px;
    }

    &.drag-drop {
        position: relative;
        width: 100%;
        height: auto;
        min-height: 58px;
        background-color: #f5f5f5;
        border-width: 2px;
        border-style: dashed;
        border-color: #d9d9d9;
        border-radius: 10px;
        padding: 8px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        transition: background-color 0.5s ease, border-color 0.5s ease;

        .lblanswer-item-wrapper {
            display: inline-block;
        }

        .lblanswer-item {
            display: inline-block;
            padding: 4px 8px;
            margin: 2px;
            border-width: 2px;
            border-style: dashed;
            border-color: #d9d9d9;
            max-width: 200px;
        }

        .view-lblanswer-drg {
            &.imglbl-drg-wrapper {
                .imglbl-drg {
                    border: 2px solid transparent;
                }
            }
        }

        &.is-fixed {
            --top: 0; // Set by JS.
            --left: 0; // Set by JS.
            --width: 0; // Set by JS.
            position: fixed;
            top: var(--top);
            left: var(--left);
            width: var(--width);
            z-index: 1000;
            border-style: solid;
            box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
                0 9px 28px 8px rgba(0, 0, 0, 0.05);
        }
    }

    &.drag-drop.under-dragelem {
        background-color: #e5e5e5;
        border-color: #bbb;
    }
}
