.modal-class-list {
    .ant-modal-content {
        border-radius: 15px;
        overflow: hidden;
    }

    .filter-toolbar-wrapper {
        .btn {
            min-width: 120px;
        }
    }
}
