@use 'src/assets/styles/utilities/colors' as colorList;

#notif-panel {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    // Ant Design - List:
    .ant-list {
        min-height: calc(100vh - 40px - 56px - 52px - 54px - 1px);
    }

    .ant-list-items {
        .ant-list-item {
            padding: 8px 16px 8px 16px;
            gap: 8px 16px;
            border-radius: 10px;
            margin: 0 8px;
            border-bottom: none;
        }
    }

    // Ant Design - Skeleton:
    .ant-skeleton {
        margin: 8px 0;
        padding: 8px 16px 8px 24px;
    }

    .ant-skeleton-header .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
        width: 32px;
        height: 32px;
    }

    .ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title {
        margin: 0;
    }

    .ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
        margin: 8px 0 0 0;
    }

    // Custom:
    .notif-menu {
        padding: 8px 24px 8px 16px;
        text-align: right;

        .notif-menu-action {
            background: none;
            color: colorList.$primary;
            font-size: 16px;

            .btn__icon-wrapper > svg {
                fill: colorList.$primary;
            }
        }

        .notif-menu-action:disabled {
            color: #979c9e;

            .btn__icon-wrapper > svg {
                fill: #979c9e;
            }
        }
    }

    .notif-msg {
        padding: 0 24px 8px 16px;
        font-style: italic;
        color: colorList.$primary;
        font-weight: 500;
    }

    .notif-item {
        .ant-list-item-meta-title {
            font-weight: 400;
        }

        &:hover {
            background-color: #f0f2f5;
        }

        &:not(:last-child) {
            margin-bottom: 8px;
        }

        &.unread {
            background-color: rgba($color: colorList.$primary, $alpha: 0.1);

            .ant-list-item-meta-title {
                font-weight: 500;
            }

            &:hover {
                background-color: rgba($color: colorList.$primary, $alpha: 0.2);
            }
        }

        .notif-item-action {
            display: inline-block;
            color: colorList.$primary;
            cursor: pointer;

            &.hidden {
                opacity: 0;
                visibility: hidden;
            }
        }
    }

    .list-end-message {
        font-style: italic;
    }
}
