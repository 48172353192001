@use 'src/assets/styles/utilities/colors' as c;

.detail-class-header.ranking-header {
    .go-back-title {
        font-size: 26px;
        font-weight: 700;
    }
    .btn-go-back {
        display: flex;
        align-items: center;
        .go-back-title {
            color: c.$white;
            margin-left: 20px;
        }
    }
    .go-back-icon {
        cursor: pointer;
    }

    .anticon.anticon-clock-circle {
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
    }

    .detail-class-header-wrapper {
        animation: grow 0.3s linear;
    }

    @media screen and (min-width: 1920px) {
        .detail-class-header-wrapper ul li p .detail-class-icon.icon-small img {
            width: 100%;
        }
    }

    @keyframes grow {
        from {
            height: 0;
            opacity: 0;
        }
        to {
            height: 100%;
            opacity: 1;
        }
    }
}
