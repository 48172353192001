@use 'src/assets/styles/utilities/colors' as c;

.container {
    width: 400px;
    height: 400px;
    box-shadow: 0 4px 4px 4px rgba($color: #000000, $alpha: 0.1);
    border-radius: 10px;
    transition: all linear 0.3s;

    &:hover {
        transform: scale(1.01);
    }
}

.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
}

.body {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 4px 40px;
    text-align: center;
}

.title {
    font-size: 20px;
    font-weight: 700;
    padding: 16px 0;
    color: #444;
}

.description {
    font-size: 14px;
    font-weight: 600;
    line-height: 26px;
    margin-bottom: 20px;
    color: #444;
}

.cardIcon {
    background-color: c.$second;
    padding: 10px;
    border-radius: 30px;
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 20px;
    color: #fff;
}
