.bank-info-list {
    .bank-selection-list {
        width: 100%;

        .ant-row {
            .ant-col {
                padding: 8px;
                border: 1px solid #ddd;
            }
        }
    }

    .bank-selection {
        &.skeleton {
            width: 100%;
            height: 30px;
            animation: skeletonLoadingChangeBgColor 0.5s ease infinite alternate;
        }
    }

    .bank-selection.ant-radio-button-wrapper {
        width: 100%;
        height: 100%;
        border: 1px solid #dcdcdf;
        border-radius: 5px;
        box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.08);

        & > img {
            max-width: 100%;
        }

        &:first-child:last-child {
            border-radius: 5px;
        }

        & > span:nth-child(2) {
            display: block;
            width: 100%;
            padding: 11px 0;

            & > img {
                display: block;
                max-width: 100%;
                margin: 0 auto;
            }
        }
    }
}
