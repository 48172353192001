.zwibbler-toolbars {
    margin-bottom: 20px;
    z-index: 10;
    background-color: #fff;

    .zwibbler-toolbar-buttons {
        display: flex;
        justify-content: space-between;

        background: white;
        border-radius: 3px;
        box-shadow: 3px 3px 3px rgb(0 0 0 / 20%);
        padding: 3px;
        padding-bottom: 8px;

        button {
            background-color: #ddd;
            border: none;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;

            min-width: 40px;
            height: 40px;
            cursor: pointer;
            &:hover {
                background-color: #bbb;
                transform: translate(0px, -1px) scale(1.05);
            }
            svg {
                width: 24px;
                height: 24px;
            }
        }

        button.active {
            background-color: #bbb;
        }
    }

    .toolbar-text-group {
        display: flex;
        h4 {
            margin-bottom: 2px;
        }
    }

    .sub-tool {
        min-height: 60px;
    }

    //color picker
    .form-control-color {
        width: 40px;
        height: 40px;
        border-radius: 5px;
        border: none;
        padding: 4px;
        cursor: pointer;
    }

    .select-size {
        select {
            width: 32px;
            height: 30px;
            border-color: #ccc;
            border-radius: 5px;
            font-size: 16px;
            cursor: pointer;
        }
    }

    .pick-options {
        button {
            border: none;
            width: 34px;
            height: 34px;
            border-radius: 4px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
                background-color: #bbb;
                transform: translate(0px, -1px) scale(1.05);
            }
        }
        svg {
            width: 14px;
        }
    }

    .sub-tool {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .sub-tool_helper {
        width: 50%;
        padding: 10px;
    }

    .sub-tool_tool {
        padding: 10px;
        position: relative;
        width: 50%;
        &::after {
            content: "";
            height: 22px;
            line-height: 22px;
            width: 1px;
            position: absolute;
            right: 0px;
            top: 54%;
            border-left: 1.5px solid rgb(255, 255, 255);
            border-right: 1.5px solid rgb(204, 204, 204);
        }
    }
}
