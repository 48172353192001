@use 'src/assets/styles/utilities/colors' as colors;

.setting_wrapper {
    min-height: 100vh;

    .setting_header-wrapper {
        padding: 20px 30px 0 30px;
    }
    .setting_tabs {
        margin-top: 30px;

        .ant-tabs-nav {
            margin-bottom: 0;
            &::before {
                display: none;
            }
        }
        .ant-tabs-ink-bar {
            // min-height: 30px !important;
            z-index: 0;
            // background-color: colors.$second;
            bottom: 5px !important;
            height: auto !important;
            top: 5px;
            border-radius: 10px;
        }
        .ant-tabs-nav-list {
            border: 1px solid colors.$second;
            padding: 5px;
            border-radius: 10px;

            .ant-tabs-tab {
                min-width: 100px;
                display: flex;
                justify-content: center;
                background-color: transparent;
                border: none;
                border-radius: 10px;

                & > div > span {
                    font-size: 18px;
                    line-height: 28px;
                    display: block;
                }

                &.ant-tabs-tab-active {
                    span {
                        color: #fff;
                        font-weight: 700;
                        z-index: 10;
                        position: relative;
                        padding: 0 4px;
                    }
                }

                &:hover {
                    color: colors.$second;
                }
            }
        }

        .ant-table {
            .ant-table-thead {
                .owner-ship-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    span {
                        margin-right: 5px;
                    }
                }
            }

            .ant-table-tbody {
                .ant-table-cell {
                    text-align: center;

                    &:first-child {
                        span {
                            position: relative;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            .icon {
                                position: absolute;
                                top: 50%;
                                left: -5px;
                                transform: translateY(-50%);
                                cursor: pointer;

                                &:hover {
                                    opacity: 0.6;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
